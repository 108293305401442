import {Input, LoadingDialog} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Field, Form, Formik} from 'formik';
import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import {createCustomerAction, getCustomerByPhoneNumberAction} from '../../redux/orderActions';
import { setStorage } from 'app/common/_helpers';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function ModalCustomerOrder({show, onHide, onPayCart, className=''}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();

  const PhoneSchema = Yup.object().shape({
    phone: Yup.string()
      .required('Số điện thoại là thông tin bắt buộc')
      .matches(phoneRegExp, 'Số điện thoại không đúng định dạng'),
  });

  const CustomerSchema = Yup.object().shape({
    name: Yup.string().required('Tên là thông tin bắt buộc'),
    phone_delivery: Yup.string()
      .required('Số điện thoại là thông tin bắt buộc')
      .matches(phoneRegExp, 'Số điện thoại không đúng định dạng'),
  });

  const [statusForm, setStatusForm] = useState('phone');

  const [phone, setPhone] = useState('');

  const initialCustomer = {
    name: '',
    address: '',
    phone: '',
    phone_delivery: phone,
    password: 123
  };

  const handleCheckPhoneNumber = async (values) => {
    try {
      const phone = values.phone;
      setPhone(phone);
      const res = await dispatch(getCustomerByPhoneNumberAction(phone));
      if (!res?.data?.data) {
        setStatusForm('checkCustomer');
      } else {
        onPayCart(res.data.data);
        const timeExpire = 24 * 60 * 60 * 10;
        setStorage('phoneCustomer', phone, timeExpire);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateCustomer = async (values) => {
    try {
      const params = {
        name: values.name,
        address: values.address,
        phone: phone,
        phone_delivery: phone,
        password: values.password
      };
      const res = await dispatch(createCustomerAction(params));
      if (res?.data?.data) {
        onPayCart(res.data.data);
        const timeExpire = 24 * 60 * 60 * 10;
        setStorage('phoneCustomer', phone, timeExpire);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={show} className={className} dialogClassName={className}>
        <Modal.Header>
          <Modal.Title>{'Vui lòng nhập số điện thoại để tiếp tục'}</Modal.Title>
        </Modal.Header>
        {statusForm === 'phone' && (
          <Formik
            enableReinitialize={true}
            initialValues={{phone: ''}}
            validationSchema={PhoneSchema}
            onSubmit={(values) => {
              handleCheckPhoneNumber(values);
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({handleSubmit}) => {
              return (
                <>
                  <LoadingDialog isLoading={false} text={'loading'} />
                  <Modal.Body className='overlaycursor-default'>
                    <Form className='form form-label-right'>
                      <div className='form-group row'>
                        <div className='col-lg-12'>
                          <Field
                            name='phone'
                            component={Input}
                            type='text'
                            placeholder={'Số điện thoại'}
                            withFeedbackLabel={true}
                            customFeedbackLabel={true}
                            focus
                          />
                        </div>
                      </div>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type='submit'
                      className='btn btn-primary btn-elevate'
                      onClick={handleSubmit}
                    >
                      Tiếp theo
                    </button>
                  </Modal.Footer>
                </>
              );
            }}
          </Formik>
        )}
        {statusForm === 'checkCustomer' && (
          <>
            <Modal.Body className='overlaycursor-default'>
              <div className='col-lg-12'>
                Số điện thoại của bạn chưa được đăng ký vui lòng đăng ký để tiếp tục mua hàng
              </div>
            </Modal.Body>
            <Formik
              enableReinitialize={true}
              initialValues={initialCustomer}
              validationSchema={CustomerSchema}
              onSubmit={(values) => {
                handleCreateCustomer(values);
              }}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({handleSubmit}) => {
                return (
                  <>
                    <LoadingDialog isLoading={false} text={'loading'} />
                    <Modal.Body className='overlaycursor-default'>
                      <Form className='form form-label-right'>
                        <div className='form-group row'>
                          <div className='col-lg-12'>
                            <Field
                              name='name'
                              component={Input}
                              placeholder={'Tên'}
                              label={'Tên'}
                              focus
                            />
                          </div>
                        </div>
                        <div className='form-group row mt-2'>
                          <div className='col-lg-12'>
                            <Field
                              name='phone_delivery'
                              component={Input}
                              type='text'
                              placeholder={'Nhập số điện thoại'}
                              label={'Số điện thoại nhận hàng'}
                              withFeedbackLabel={true}
                              customFeedbackLabel={true}
                            />
                          </div>
                        </div>
                        <div className='form-group row mt-2'>
                          <div className='col-lg-12'>
                            <Field
                              name='address'
                              component={Input}
                              placeholder={'Địa chỉ'}
                              label={'Địa chỉ'}
                            />
                          </div>
                        </div>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type='button'
                        onClick={() => setStatusForm('phone')}
                        className='btn btn-light btn-elevate'
                      >
                        Huỷ
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary btn-elevate'
                        onClick={handleSubmit}
                      >
                        Lưu thông tin và Đặt hàng
                      </button>
                    </Modal.Footer>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </Modal>
    </div>
  );
}

export default ModalCustomerOrder;
