import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import ProductCategoryForm from './ProductCategoryForm';
import {useDispatch} from 'react-redux';
import {editProductCategorysAction} from '../../redux/productCategoryActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';

function EditProductCategory({show, onHide, onSaveSuccess, productCategory}) {
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();
  const initProductCategory = {
    name: '',
    description: '',
    maxOrder: '',
    unitName: '',
    image: '',
    price: '',
    packages: [],
  };

  const [productCategoryEdit, setProductCategoryEdit] = useState(initProductCategory);

  useEffect(() => {
    const productCategoryEdit = {
      name: productCategory.name,
      description: productCategory.description,
      maxOrder: productCategory.max_order,
      unitName: productCategory.unit_name,
      image: productCategory.image,
      image_url: productCategory.image_url,
      price: productCategory.price,
      packages: productCategory.packages,
    };
    setProductCategoryEdit(productCategoryEdit);
  }, [productCategory]);

  const saveProductCategory = async (productCategoryValues) => {
    try {
      let imageUrl = productCategory?.image_url || '';
      const params = {
        ...productCategoryValues,
        image_url: imageUrl,
        max_order: productCategoryValues.maxOrder,
        unit_name: productCategoryValues.unitName
      };
      dispatch(editProductCategorysAction(params, productCategory._id)).then((res) => {
        if (res?.data?.success) {
          snackNotification.showSuccess(t('productCategory_edit_success'));
          onSaveSuccess();
        } else {
          snackNotification.showError(t('productCategory_edit_error'));
          onHide();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('productCategory_edit_title')}</Modal.Title>
        </Modal.Header>
        <ProductCategoryForm
          initProductCategory={productCategoryEdit}
          onHide={onHide}
          saveProductCategory={saveProductCategory}
          isEdit={true}
        />
      </Modal>
    </div>
  );
}

export default EditProductCategory;
