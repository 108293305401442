import './orderPageOrderSection.scss';
import './orderPage.scss';

import OrderItem from './OrderItem';
import { FormattedNumber } from 'react-intl';
import React from 'react';
function OrderPageOrderSection({
  listProduct,
  cartProductsById,
  handelDeleteToCart,
  handelAddToCart,
  cartSummary,
  setShowCartOrder,
}) {
  return (
    <div className='container' style={{marginTop: '10vh'}}>
      <p className='title'>Đặt mua sản phẩm của của dự án</p>
      <div className='product-container'>
        {listProduct?.map((product) => {
          const cartProduct = cartProductsById[product._id];
          return (
            <React.Fragment key={product._id}>
              <OrderItem
                product={product}
                cartProduct={cartProduct}
                handelDeleteToCart={handelDeleteToCart}
                handelAddToCart={handelAddToCart}
              />
            </React.Fragment>
          );
        })}
      </div>
      <div className='d-flex justify-content-center flex-column align-items-center mt-5'>
        <p className='title'>
          Tổng cộng: <FormattedNumber value={cartSummary.totalAmount} />đ
        </p>
        <button
          className='order-page-button-primary mt-5'
          onClick={() => {
            cartSummary?.totalProduct > 0 && setShowCartOrder(true);
          }}
          disabled={cartSummary.totalProduct === 0}
        >
          Đặt hàng
        </button>
      </div>
    </div>
  );
}

export default OrderPageOrderSection;
