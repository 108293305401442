/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {AsideTabs} from './AsideTabs'
import {AsideFooter} from './AsideFooter'
import {TabsBase} from './Tabs/_TabsBase'
import { menus } from '../../../configs/MenuConfig'

const AsideDefault = () => {
  const navigate = useNavigate()
  const {config} = useLayout()
  const {classes} = useLayout()
  const [link, setLink] = useState<string>('')
  const [menu, setMenu] = useState<any>(null)
  const location = useLocation();

  const activateLink = (link: string) => {
    setLink(link);
    navigate(link, {replace: true})
  }

  const activateMenu = (menu: any) => {
    setMenu(menu);
    activateLink(menu.link)
  }

  useEffect(() => {
    if (menu?.secondary) {
      document.body.setAttribute('data-kt-aside-minimize', '')
    } else {
      document.body.setAttribute('data-kt-aside-minimize', 'on')
    }
  }, [menu])
  
  useEffect(() => {
    menus.forEach( menu => {
      if (location.pathname.indexOf(menu.link) >= 0 ) {
        setLink(menu.link);
        setMenu(menu);
        return;
      }
    })
  }, [location])

  return (
    <div
      id='kt_aside'
      className={clsx('aside aside-extended shadow-none', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      
      {/* end::Primary */}
      {/* {(config.aside.secondaryDisplay && menu?.secondary) && ( */}
        <>
          {/* begin::Secondary */}
          <div className='aside-secondary d-flex flex-row-fluid'>
            {/* begin::Workspace */}
            <div className='aside-workspace my-0 p-0 w-100' id='kt_aside_wordspace'>
              <TabsBase link={link} />
            </div>
            {/* end::Workspace */}
          </div>
          {/* end::Secondary */}
          {/* begin::Aside Toggle */}
          {/*
          <button
            id='kt_aside_toggle'
            className={clsx(
              'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 top-0 shadow-sm d-none d-lg-flex d-none',
              classes.asideToggle.join(' ')
            )}
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            style={{marginTop: '4.05rem'}}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr063.svg'
              className='svg-icon-2 rotate-180'
            />
          </button>
          */
          }
          {/* end::Aside Toggle */}
        </>
      {/* )} */}
    </div>
  )
}

export {AsideDefault}
