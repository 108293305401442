import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../core/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../core/layouts/layout2/core'
import { AuthInit } from './modules/auth'
import { useDispatch } from 'react-redux'
import { fetchAllUsersAction } from '../core/store/core/coreActions'
import { SnackNotificationProvider } from './layout/_core/SnackNotificationProvider'
import ErrorBoundary from './layout/_core/ErrorBoundary'


const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    // dispatch<any>(fetchAllUsersAction());
  }, [dispatch]);

  return (

    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <SnackNotificationProvider>
            <ErrorBoundary>
              <AuthInit>
                <Outlet />
              </AuthInit>
            </ErrorBoundary>
          </SnackNotificationProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>

  )
}

export { App }
