import {CardHeaderToolbar, LoadingDialog, Pagination} from 'app/common/_partials/controls';
import {useIntl} from 'react-intl';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  defaultTableOptions,
  getHandlerTableChange,
} from 'app/common/_helpers';
import {useCallback, useEffect, useState} from 'react';
import {isEqual, isFunction} from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from 'app/common/_helpers';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {addProductCategorysAction, deleteProductCategoryAction, fetchProductCategorysAction} from '../redux/productCategoryActions';
import AddProductCategory from './Modal/AddProductCategory';
import EditProductCategory from './Modal/EditProductCategory';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import './productCategoryList.scss';
import {addProductCategory} from '../redux/productCategoryApi';
import ProductCategoryListFilter from './ProductCategoryListFilter';

export default function ProductCategoryListPage() {
  const intl = useIntl();
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {productCategorys, countProductCategorys} = useSelector((state) => state.productCategory, shallowEqual);
  const [productCategory, setProductCategory] = useState(null);
  const [productCategoryDelete, setProductCategoryDelete] = useState(null);
  const [showAddProductCategory, setShowAddProductCategory] = useState(false);
  const [newProductCategoryValue, setNewProductCategoryValue] = useState('');

  const tableOptions = {
    ...defaultTableOptions,
    searchText: null,
  };

  const [queryParams, setQueryParamsBase] = useState(tableOptions);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const onAddItemInputBlur = (e) => {
    if (e.target.value !== '') {
      handleAddItemEvent(e);
    }
  };

  const onAddItemInputPasted = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const input = e.target;

    let lines = pastedText.split('\r\n');
    lines = lines.filter((x) => {
      return x !== '';
    });
    if (lines.length > 1) {
      handleAddItems(lines, () => {
        setTimeout(() => (input.value = ''), 0);
      });
    }
  };

  const onAddItemInputKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      handleAddItemEvent(e);
    }
  };

  const handleAddItems = (items) => {
    const requests = items.map((item) => {
      const params = {
        name: item,
        unit_name: 'kg'
      };
      return addProductCategory(params);
    });
    Promise.all(requests).then((res) => {
      setNewProductCategoryValue('');
      onSaveSuccess();
    });
  };

  const handleAddItemEvent = (e) => {
    const value = e.target.value;
    const params = {
      name: value,
    };
    dispatch(addProductCategorysAction(params)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('productCategory_add_success'));
        onSaveSuccess();
        setNewProductCategoryValue('');
      } else {
        snackNotification.showError(t('productCategory_add_error'));
      }
    });
  };

  const productCategoryColumns = [
    {
      dataField: 'name',
      text: t('productCategory_name'),

      footer: '',
      footerClasses: 'border-top',
      footerFormatter: () => {
        return (
          <div>
            <input
              type='text'
              value={newProductCategoryValue}
              onChange={(e) => setNewProductCategoryValue(e.target.value)}
              className='w-100 input-add-new-productCategory rounded-0  form-control  form-control-xs item-text-input'
              placeholder='+ Thêm danh mục sản phẩm'
              onBlur={(e) => onAddItemInputBlur(e)}
              onKeyDown={(e) => onAddItemInputKeyDown(e)}
              onPasteCapture={(e) => onAddItemInputPasted(e)}
            />
          </div>
        );
      },
    },
    
    {
      dataField: 'action',
      text: '',
      footer: '',
      footerClasses: 'border-top',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEdit: setProductCategory,
        openDelete: setProductCategoryDelete,
      },
      classes: 'text-right pe-0',
      headerClasses: 'text-right pe-3',
      style: {
        width: '50px',
      },
    },
  ];

  const onSaveSuccess = () => {
    loadProductCategorys();
    setShowAddProductCategory(false);
    setProductCategory(null);
    setProductCategoryDelete(null);
  };

  const handleDeleteProductCategory = () => {
    dispatch(deleteProductCategoryAction(productCategoryDelete._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('productCategory_delete_success'));
        loadProductCategorys();
        setProductCategoryDelete(null);
      } else {
        snackNotification.showError(t('productCategory_delete_error'));
        setProductCategoryDelete(null);
      }
    });
  };

  useEffect(() => {
    loadProductCategorys();
  }, [dispatch, queryParams]);

  const loadProductCategorys = () => {
    const params = {
      ...queryParams,
      paging: {
        pageNumber: queryParams.pageNumber,
        pageSize: queryParams.pageSize,
      },
    };
    dispatch(fetchProductCategorysAction(params));
  };

  return (
    <>
      <LoadingDialog isLoading={false} text='Loading ...' />
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('productCategory_title')}</h3>
              <CardHeaderToolbar>
                <div className='d-flex'>
                  <ProductCategoryListFilter setQueryParams={setQueryParams} queryParams={queryParams} />
                  <button
                    type='button'
                    className='btn btn-sm btn-primary ms-2 w-100'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowAddProductCategory(true);
                    }}
                  >
                    {intl.formatMessage({id: 'common_add_new'})}
                  </button>
                </div>
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <PaginationProvider
                pagination={paginationFactory({
                  ...tableOptions,
                  totalSize: countProductCategorys,
                  page: queryParams.pageNumber,
                  withFirstAndLast: false,
                  hideSizePerPage: true,
                })}
              >
                {({paginationProps, paginationTableProps}) => {
                  return (
                    <Pagination isLoading={false} paginationProps={paginationProps}>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                        bootstrap4
                        remote
                        hover
                        keyField='_id'
                        onTableChange={getHandlerTableChange(setQueryParams)}
                        data={productCategorys === null ? [] : productCategorys}
                        columns={productCategoryColumns}
                        {...paginationTableProps}
                      >
                        <PleaseWaitMessage entities={productCategorys === null ? [] : productCategorys} />
                        <NoRecordsFoundMessage entities={productCategorys === null ? [] : productCategorys} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </div>
      <AddProductCategory
        show={showAddProductCategory}
        onHide={() => setShowAddProductCategory(false)}
        onSaveSuccess={onSaveSuccess}
      />
      {productCategory && (
        <EditProductCategory
          show={productCategory ? true : false}
          productCategory={productCategory}
          onHide={() => setProductCategory(null)}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {productCategoryDelete && (
        <ModalDelete
          onClick={handleDeleteProductCategory}
          show={productCategoryDelete ? true : false}
          title={'Xoá danh mục sản phẩm'}
          content={'Bạn muốn xoá danh mục sản phẩm'}
          deleteName={productCategoryDelete.name}
          close={() => setProductCategoryDelete(null)}
        />
      )}
    </>
  );
}

const ActionsColumnFormatter = (cellContent, row, rowIndex, {openEdit, openDelete}) => (
  <div className='d-flex'>
    <a
      className='btn btn-icon  btn-hover-primary btn-sm mx-2'
      key='edit'
      onClick={(event) => {
        event.stopPropagation();
        openEdit(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
      </span>
    </a>
    <a
      className='btn btn-icon  btn-hover-primary btn-sm mx-2'
      key='delete'
      onClick={(event) => {
        event.stopPropagation();
        openDelete(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
      </span>
    </a>
  </div>
);
