import { createSlice } from "@reduxjs/toolkit";

const initialShortLinkState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  shortLinks: [],
  countShortLinks: 0,
  products: []
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const shortLinkSlice = createSlice({
  name: 'shortLink',
  initialState: initialShortLinkState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    shortLinksFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.shortLinks = data.data ? data.data : [];
      state.countShortLinks = data.count ? data.count : 0
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
    listProductFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.products = data.data ? data.data : [];
      state.countProducts = data.count ? data.count : 0;
    },
  },
});
