import {Route, Routes, Outlet, Navigate} from 'react-router-dom';
import SellerPage from './page/SellerPage';


const SellerRoutes = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          element={
            <>
              <SellerPage />
            </>
          }
        />
      </Route>
      <Route index element={<SellerPage />} />
    </Routes>
  );
};

export default SellerRoutes;
