import {CardHeaderToolbar} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useNavigate} from 'react-router-dom';
import {
  editShortLinksAction,
  fetchProductsAction,
  getShortLinkDetailAction,
} from '../../redux/shortLinkActions';
import ShortLinkEditForm from './ShortLinkEditForm';

function ShortLinkEditPage(props) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackNotification = useSnackNotification();
  const {shortLinkId} = useParams();

  const initShortLink = {
    name: '',
  };
  const [shortLinkDetail, setShortLinkDetail] = useState(initShortLink);


  useEffect(() => {
    if (shortLinkId) {
      dispatch(getShortLinkDetailAction(shortLinkId)).then((res) => {

        setShortLinkDetail(res.data.data);
      });
    }
  }, [shortLinkId, dispatch]);


  const saveShortLink = (values) => {
    const params = {
      ...values,
    };
    dispatch(editShortLinksAction(params, shortLinkId)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess('Cập nhật link thành công');
      } else {
        snackNotification.showError('Có lỗi sảy ra trong quá lưu link');
      }
    });
  };

  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('shortLink_title')}</h3>
              <CardHeaderToolbar>
                <button
                  type='button'
                  className='btn btn-sm btn-secondary ms-2'
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate('/short-link');
                  }}
                >
                  <i className='las la-arrow-left'></i>
                  Quay lại
                </button>
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <ShortLinkEditForm
                initShortLink={shortLinkDetail}
                isEdit={true}
                onHide={() => {
                  navigate('/short-link');
                }}
                saveShortLink={saveShortLink}
                shortLinkId={shortLinkId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShortLinkEditPage;
