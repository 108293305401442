import React from 'react';
import {Modal} from 'react-bootstrap';
import CustomerForm from './SellerForm';
import {useDispatch} from 'react-redux';
import {addSellerAction} from '../../redux/sellerActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { useTranslate } from 'core/i18n/i18nProvider';

function AddSeller({show, onHide, onSaveSuccess}) {
  const dispatch = useDispatch();
  const {t} = useTranslate()
  const snackNotification = useSnackNotification();

  const initSeller = {
    name: '',
    code: '',
    address: '',
    phone: '',
    settings: {
      name: '',
      bank: '',
      bankAccount: '',
    }
  };

  const saveSeller = (seller) => {
    const params = {
      ...seller,
    };
    dispatch(addSellerAction(params)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('seller_add_success'));
        onSaveSuccess();
      } else {
        snackNotification.showError(t('seller_add_error'));
        onHide();
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('seller_add_title')}</Modal.Title>
        </Modal.Header>
        <CustomerForm initSeller={initSeller} onHide={onHide} saveSeller={saveSeller} />
      </Modal>
    </div>
  );
}

export default AddSeller;
