import React from "react";
import {Error500} from '../../modules/errors/components/Error500';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true});
  }

  render() {
    if (this.state.hasError) {
      return <Error500 />;
    }
    return this.props.children;
  }
}


export default ErrorBoundary;