import {Route, Routes, Outlet, Navigate} from 'react-router-dom';
import PriceListPage from './page/PriceListPage';
import PriceListEditPage from './page/Modal/PriceListEditPage';
import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';

const PriceListRoutes = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/:priceListId'
          element={
            <>
              <PageAside>
                <Menu />
              </PageAside>
              <PriceListEditPage />
            </>
          }
        />
        <Route
          element={
            <>
              <PriceListPage />
            </>
          }
        />
      </Route>
      <Route index element={<PriceListPage />} />
    </Routes>
  );
};

export default PriceListRoutes;
