import { actions } from "../store/auth";


export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (request) => {
      const {
        auth: { auth },
        core: { common }
      } = store.getState();


      if (auth && auth.token) {
        request.headers.Authorization = `Bearer ${auth.token}`;
      } 
      return request;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (res) => {
      const originalConfig = res.config;
      if (originalConfig.url !== "/user/login" && res.data.err_code === 402) {
        store.dispatch(actions.logout());
      }

      return res;
    },
    (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/user/login" && err.response) {
        if (err.response.status === 401) {
          store.dispatch(actions.logout());
        }
      }

      Promise.reject(err);
    }
  );
}
