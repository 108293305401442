import * as requestFromServer from './shortLinkApi'
import {shortLinkSlice, callTypes} from './shortLinkSlice'

const {actions} = shortLinkSlice

export const fetchShortLinksAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getShortLinks(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.shortLinksFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get shortLinks";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const addShortLinksAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .addShortLink(params)
    .catch((error) => {
      error.clientMessage = "Can't add shortLink";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
}


export const editShortLinksAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .editShortLink(params, id)
    .catch((error) => {
      error.clientMessage = "Can't edit shortLink";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}



export const getShortLinkDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .shortLinkDetail(id)
    .catch((error) => {
      error.clientMessage = "Can't get shortLink";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const deleteShortLinkAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .deleteShortLink(id)
    .catch((error) => {
      error.clientMessage = "Can't delete shortLink";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}


export const duplicateShortLinkAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .duplicateShortLink(id)
    .catch((error) => {
      error.clientMessage = "Can't suplicate shortLink";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}




export const getShortLinkFromCodeAction = (code) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .shortLinkFromCode(code)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get shortLink";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}