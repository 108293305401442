import {CardHeaderToolbar} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useNavigate} from 'react-router-dom';
import {
  editPriceListsAction,
  fetchProductsAction,
  getPriceListDetailAction,
} from '../../redux/priceListActions';
import PriceListEditForm from './PriceListEditForm';

function PriceListEditPage(props) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackNotification = useSnackNotification();
  const {priceListId} = useParams();
  const {products} = useSelector((state) => state.priceList, shallowEqual);

  const initPriceList = {
    name: '',
    description: '',
    prices: [],
  };
  const [priceListDetail, setPriceListDetail] = useState(initPriceList);
  const [editPriceListDetail, setEditPriceListDetail] = useState(initPriceList);

  useEffect(() => {
    dispatch(fetchProductsAction());
  }, [dispatch]);

  useEffect(() => {
    if (priceListId) {
      dispatch(getPriceListDetailAction(priceListId)).then((res) => {
        const priceList = res.data.data;
        const productEdit = {
          name: priceList.name,
          description: priceList.description,
          prices: priceList.prices || [],
        };

        setPriceListDetail(productEdit);
      });
    }
  }, [priceListId, dispatch]);

  useEffect(() => {
    if (products.length > 0 && priceListDetail.prices.length > 0) {
      const _prices = products.map((product) => {
        let price = priceListDetail.prices.find((price) => price.productId === product._id || price.product_id === product._id);
        if (price) {
          return {
            productId: product._id,
            productName: product.name,
            price: price.price,
            discount_price_percent: price.discount_price_percent || null,
            discount_price: price.discount_price || null,
          };
        } else {
          return {
            productId: product._id,
            productName: product.name,
            price: null,
            discount_price_percent: null,
            discount_price: null,
          };
        }
      });
      setEditPriceListDetail({
        ...priceListDetail,
        prices: _prices,
      });
    }
  }, [products, priceListDetail]);

  const savePriceList = (productValue) => {
    const params = {
      ...productValue,
    };
    dispatch(editPriceListsAction(params, priceListId)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess('Cập nhật đợt hàng thành công');
      } else {
        snackNotification.showError('Có lỗi sảy ra trong quá lưu đợt hàng');
      }
    });
  };

  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('priceList_title')}</h3>
              <CardHeaderToolbar>
                <button
                  type='button'
                  className='btn btn-sm btn-secondary ms-2'
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate('/price-list');
                  }}
                >
                  <i className='las la-arrow-left'></i>
                  Quay lại
                </button>
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <PriceListEditForm
                initPriceList={priceListDetail}
                isEdit={true}
                onHide={() => {
                  navigate('/price-list');
                }}
                savePriceList={savePriceList}
                priceListId={priceListId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceListEditPage;
