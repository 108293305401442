import {Input, LoadingDialog, SelectField} from 'app/common/_partials/controls';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import { listBankCode } from '../../constants/ListBankCode';
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function SellerForm({saveSeller, initSeller, onHide, isEdit}) {
  const {t} = useTranslate();
  const sellerAddSchema = Yup.object().shape({
    name: Yup.string().required('Tên là bắt buộc'),
    address: Yup.string().required('Địa chỉ là bắt buộc'),
    phone: Yup.string()
      .required('Số điện thoại là thông tin bắt buộc')
      .matches(phoneRegExp, 'Số điện thoại không đúng định dạng'),
  });

  const {actionsLoading} = useSelector((state) => state.seller, shallowEqual);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initSeller}
        validationSchema={sellerAddSchema}
        onSubmit={(values) => {
          saveSeller(values);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({handleSubmit, values, setFieldValue}) => {
          return (
            <>
              <LoadingDialog isLoading={actionsLoading} text={t('common_loading')} />
              <Modal.Body className='overlaycursor-default'>
                  <Form className='form form-label-right'>
                      <div className='form-group row'>
                          <div className='col-lg-12'>
                              <Field
                                  name='name'
                                  component={Input}
                                  placeholder={t('customer_name')}
                                  label={t('customer_name')}
                                  focus
                              />
                          </div>
                      </div>
                      <div className='form-group row mt-4'>
                          <div className='col-lg-12'>
                              <Field
                                  name='code'
                                  component={Input}
                                  placeholder={t('customer_code')}
                                  label={t('customer_code')}
                              />
                          </div>
                      </div>
                      <div className='form-group row mt-4'>
                          <div className='col-lg-12'>
                              <Field
                                  name='address'
                                  component={Input}
                                  placeholder={t('customer_address')}
                                  label={t('customer_address')}
                              />
                          </div>
                      </div>
                      <div className='form-group row mt-4'>
                          <div className='col-lg-12'>
                              <Field
                                  name='phone'
                                  component={Input}
                                  placeholder={t('customer_phone')}
                                  label={t('customer_phone')}
                              />
                          </div>
                      </div>
                      <div className='border border-secondary rounded p-2 mt-7'>
                          <div
                              className='offcanvas-title h5 bg-white'
                              style={{marginTop: '-20px', width: 'fit-content'}}
                          >
                              {t('seller_bank')}
                          </div>
                          <div className='form-group row mt-4'>
                              <div className='col-lg-12'>
                                  <Field
                                      name='settings.name'
                                      component={Input}
                                      placeholder={t('seller_bank_user_name')}
                                      label={t('seller_bank_user_name')}
                                  />
                              </div>
                          </div>
                          <div className='form-group row mt-4'>
                              <div className='col-lg-12'>
                                  <SelectField isMulti={false} label='Ngân hàng' name='settings.bank'>
                                      <option value=''>--Chọn ngân hàng--</option>
                                      {listBankCode.map((bank) => {
                                          return <option value={bank.value}>{bank.label}</option>;
                                      })}
                                  </SelectField>
                              </div>
                          </div>
                          <div className='form-group row mt-4'>
                              <div className='col-lg-12'>
                                  <Field
                                      name='settings.bankAccount'
                                      component={Input}
                                      placeholder={t('seller_bank_account')}
                                      label={t('seller_bank_account')}
                                  />
                              </div>
                          </div>
                      </div>
                  </Form>
              </Modal.Body>
                <Modal.Footer>
                    <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                        {t('common_cancel')}
                    </button>
                    <button
                        type='submit'
                        className='btn btn-primary btn-elevate'
                        onClick={handleSubmit}
                    >
                  {isEdit ? t('common_save') : t('common_add_new')}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default SellerForm;
