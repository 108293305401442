import {CardHeaderToolbar, Pagination} from 'app/common/_partials/controls';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  defaultTableOptions,
  getHandlerTableChange,
} from 'app/common/_helpers';
import {useCallback, useEffect, useState} from 'react';
import {isEqual, isFunction} from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {getCustomerReportByPaginationAction, getCustomerReportNotByPaginationAction} from '../redux/reportActions';
import CustomerReportViewFilter from './CustomerReportViewFilter';
import {FormattedNumber} from 'react-intl';

export default function CustomerReportView() {
  const dispatch = useDispatch();
  const [customerReportByPagination, setCustomerReportByPagination] = useState([]);
  const [totalCustomerReportByPagination, setTotalCustomerReportByPagination] = useState(0);
  const [totalQuantity, settotalQuantity] = useState([]);
  const [totalRevenue, settotalRevenue] = useState([]);

  const tableOptions = {
    ...defaultTableOptions,
    searchText: null,
  };
  const [queryParams, setQueryParamsBase] = useState(tableOptions);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);


  const customerColumns = [
    {
      dataField: 'name',
      text: 'Tên khách hàng',
      footer: '',
      footerClasses: 'border-top',
    },
    {
      dataField: 'phone',
      text: 'Số điện thoại  ',
      footer: '',
      footerClasses: 'border-top',
    },
    {
      dataField: 'quantity',
      text: 'Tổng khối lượng(kg)',
      footer: '',
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell?.toFixed(2)} />
          </span>
        );
      },
      footerClasses: 'border-top',
    },
    {
      dataField: 'discount',
      text: 'Giảm giá (VND)',
      footer: '',
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell} />
          </span>
        );
      },
      footerClasses: 'border-top',
    },
    {
      dataField: 'totalAmount',
      text: 'Tổng tiền (VND)',
      footer: '',
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell?.toFixed(2)} />
          </span>
        );
      },
      footerClasses: 'border-top',
    },
  ];

  useEffect(() => {
    loadCustomerReportByPagination();
    loadCustomerReportNotByPaginationAction()
  }, [dispatch, queryParams]);

  const loadCustomerReportByPagination = () => {
    const params = {
      page: queryParams.pageNumber,
      pageSize: queryParams.pageSize,
      ...queryParams,
    }
    dispatch(getCustomerReportByPaginationAction(params)).then(res => {
      let customerReports = res.data;
      if(customerReports.length > 0) {
        customerReports.map((customerReport) => {
          setCustomerReportByPagination(customerReport.data);
          setTotalCustomerReportByPagination(customerReport.count);
        })
      }else{
        setTotalCustomerReportByPagination(0);
        setCustomerReportByPagination(null);
      }
    });
  };

  const loadCustomerReportNotByPaginationAction = () => {
    const params = {
      page: queryParams.pageNumber,
      pageSize: queryParams.pageSize,
      ...queryParams,
    }
    dispatch(getCustomerReportNotByPaginationAction(params)).then(res => {
      let customerReports = res.data;
      let totalQuantityReport = 0;
      let totalRevenueReport = 0;
      if(customerReports.length > 0) {
        customerReports.map((customerReport) => {
          totalQuantityReport += customerReport.quantity;
          totalRevenueReport += customerReport.totalAmount;
          settotalQuantity(totalQuantityReport);
          settotalRevenue(totalRevenueReport);
        })
      }else{
        settotalRevenue(0);
        settotalQuantity(0);
      }
    });
  }


  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='container-full'>
              <h3 className='card-title font-weight-bolder text-dark'></h3>
              <CardHeaderToolbar>
                <div className='d-flex'>
                  <CustomerReportViewFilter setQueryParams={setQueryParams} queryParams={queryParams} />
                </div>
              </CardHeaderToolbar>
            </div>
            <div className='container-full'>
              <h3 className='card-title font-weight-bolder text-dark'></h3>
              <div className='row d-flex customer report'>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                  <div>Tổng số khách hàng:</div>
                  <div className='ms-4'>{totalCustomerReportByPagination.toLocaleString()}</div>
                </div>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                  <div>Tổng số lượng (kg):</div>
                  <div className='ms-4'>{totalQuantity.toLocaleString()}</div>
                </div>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                  <div>Tổng doanh thu (VND):</div>
                  <div className='ms-4'>{totalRevenue.toLocaleString()}</div>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <PaginationProvider
                pagination={paginationFactory({
                  ...tableOptions,
                  totalSize: totalCustomerReportByPagination,
                  page: queryParams.pageNumber,
                  withFirstAndLast: false,
                  hideSizePerPage: true,
                })}
              >
                {({paginationProps, paginationTableProps}) => {
                  return (
                    <Pagination isLoading={false} paginationProps={paginationProps}>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                        bootstrap4
                        remote
                        hover
                        keyField='_id'
                        onTableChange={getHandlerTableChange(setQueryParams)}
                        data={customerReportByPagination === null ? [] : customerReportByPagination}
                        columns={customerColumns}
                        {...paginationTableProps}
                      >
                        <PleaseWaitMessage entities={customerReportByPagination === null ? [] : customerReportByPagination} />
                        <NoRecordsFoundMessage entities={customerReportByPagination === null ? [] : customerReportByPagination} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}