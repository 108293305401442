import React from 'react';
import {Modal} from 'react-bootstrap';
import ProductCategoryForm from './ProductCategoryForm';
import {useDispatch} from 'react-redux';
import {addProductCategorysAction, uploadFileAction} from '../../redux/productCategoryActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';

function AddProductCategory({show, onHide, onSaveSuccess}) {
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();

  const initProductCategory = {
    name: '',
    description: '',
    maxOrder: '',
    unitName: 'kg',
    image: '',
    price: '',
    packages: [],
  };

  const saveProductCategory = async (productCategory) => {
    try {
      let image = '';
      if (productCategory.image) {
        const res = await dispatch(uploadFileAction({file: productCategory.image}));
        if (res?.data?.result?.url) {
          image = res?.data?.result?.url;
        }
      }
      const params = {
        ...productCategory,
        image: image,
        max_order: productCategory.maxOrder,
        unit_name: productCategory.unitName,
      };
      dispatch(addProductCategorysAction(params)).then((res) => {
        if (res?.data?.success) {
          snackNotification.showSuccess(t('productCategory_add_success'));
          onSaveSuccess();
        } else {
          snackNotification.showError(t('productCategory_add_error'));
          onHide();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('productCategory_add_title')}</Modal.Title>
        </Modal.Header>
        <ProductCategoryForm initProductCategory={initProductCategory} onHide={onHide} saveProductCategory={saveProductCategory} />
      </Modal>
    </div>
  );
}

export default AddProductCategory;
