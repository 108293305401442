import { createSlice } from "@reduxjs/toolkit";

const initialTeamState = {
  listLoading: false,
  actionsLoading: false,
  teams: [],
  teamGridView: [],
  countTeams: 0,
  teamTeachers: [],
  countTeamTeachers: 0,
  teamForEdit: {},
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const teamSlice = createSlice({
  name: "team",
  initialState: initialTeamState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    teamsFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.teams = data.data ? data.data : [];
    },

    teamsTreeFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.teamGridView = data.result ? data.result : [];
    },

    teamForEditFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.teamForEdit = data.result ? data.result : {};
    },

    teamAdd: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    teamUpdate: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    teachersFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.teamTeachers = data.result ? data.result : [];
      if (data.objects) {
        state.countTeamTeachers = data.objects.total;
      } else if (data.total) {
        state.countTeamTeachers = data.total;
      } else {
        state.countTeamTeachers = 0;
      }
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
  },
});
