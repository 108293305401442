import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';
import CustomerCategoryListPage from './CustomerCategoryListPage';
const CustomerCategoryPage = () => {
  return (
    <div>
      <PageAside>
        <Menu />
      </PageAside>
      <CustomerCategoryListPage/>
    </div>
  );
};

export default CustomerCategoryPage;
