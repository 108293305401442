import * as requestFromServer from './productCategoryApi'
import {productCategorySlice, callTypes} from './productCategorySlice'

const {actions} = productCategorySlice

export const fetchProductCategorysAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getProductCategorys(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.productCategorysFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get productCategorys";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const addProductCategorysAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .addProductCategory(params)
    .catch((error) => {
      error.clientMessage = "Can't add productCategory";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
}


export const editProductCategorysAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .editProductCategory(params, id)
    .catch((error) => {
      error.clientMessage = "Can't edit productCategory";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}



export const getProductCategoryDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .productCategoryDetail(id)
    .catch((error) => {
      error.clientMessage = "Can't get productCategory";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const deleteProductCategoryAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .deleteProductCategory(id)
    .catch((error) => {
      error.clientMessage = "Can't delete productCategory";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}


export const uploadFileAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.uploadFile(params).catch((error) => {
    error.clientMessage = "Can't upload file";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};
