import {postApiV2} from 'core/store/api'

 export const endPoints = {
  productReportByPagination: '/api/report/product',
  productReportNotByPagination: '/api/report/product/statistical',
  customerReportNotByPagination: '/api/report/customer/statistical',
  customerReportByPagination: '/api/report/customer',
  customerExcelReport: '/api/report/customer/excel',
  listCustomer: '/api/customer/search',
  listProduct: '/api/product/search',
  listOrderBatch: '/api/orderBatch/search',
  productExcelReport: '/api/report/product/excel'
};

export function getProducts(params) {
  return postApiV2(endPoints.listProduct, params)
}

export function getProductExcelReport(params) {
  return postApiV2(endPoints.productExcelReport, params)
}

export function getProductReportByPagination(params) {
  return postApiV2(endPoints.productReportByPagination,params)
}

export function getProductReportNotByPagination(params) {
  return postApiV2(endPoints.productReportNotByPagination,params)
}

export function getCustomerReportByPagination(params) {
  return postApiV2(endPoints.customerReportByPagination, params)
}

export function getCustomerReportNotByPagination(params) {
  return postApiV2(endPoints.customerReportNotByPagination,params)
}

export function getCustomerExcelReport(params) {
  return postApiV2(endPoints.customerExcelReport, params)
}

export function getCustomers(params) {
  return postApiV2(endPoints.listCustomer, params)
}

export function getListOrderBatch(params) {
  return postApiV2(endPoints.listOrderBatch, params);
}
