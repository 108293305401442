/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { useSession } from 'core/store/core/hooks'
import OrderPagePublic from 'app/modules/order/page/OrderPagePublic'
import ShortLinkPublicPage from 'app/modules/short-links/page/ShortLinkPublicPage'
import PublicOrderPage from 'app/modules/landing-page-order/public-page/PublicOrderPage';


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { user } = useSession();
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/p/page-order/:code' element={<PublicOrderPage />} />
          <Route path='/p/orders/:orderBatchId/:price' element={<OrderPagePublic />} />
          <Route path='/p/orders/:orderBatchId' element={<OrderPagePublic />} />
          <Route path='/p/short/:shortLink' element={<ShortLinkPublicPage />} />

          {user ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/products' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export { AppRoutes }
