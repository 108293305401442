import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api'

const endPoints = {
  orderLandingPageDetail: (id) => `/api/orderLandingPage/${id}`,
  orderLandingPageByCode: `/api/orderLandingPage/by-code`,
  orderBatchDetail: (id) => `/api/orderBatch/${id}`,
  listProductById: '/api/product/ids',
  getProducts: '/api/product',
  updateMultipleProduct: () => `/api/product/update-batch`,
  orders: `/api/order/search`,
  create: `/api/order`,
  deleteOrder: (id) => `/api/order/${id}`,
  update: (id) => `/api/order/${id}`,
  getOrder: (id) => `/api/order/${id}`,
  customerByPhone: (phoneNumber) => `/api/customer/phone/${phoneNumber}`,
  createCustomer: `/api/customer`,
  updateCustomer: (id) => `/api/customer/${id}`,
  priceDetail: (id) => `/api/price-list/${id}`,
  getOrderBatchSummaryProductOrder: (id) => `/api/order/order-batch/${id}/product-summary`,
  getOrderByPhone: '/api/order/order-by-phone',
  getOrderLandingPageByOrderBatch: '/api/orderLandingPage/by-order-batch',
  genPaymentQrCode: '/api/orderBatch/paymentQrCode',
  getPaymentQrCodeContent:'/api/orderBatch/getPaymentQrCodeContent',
  getSeller: (id) => `/api/seller/${id}`,
};


export function getOrderLandingPageDetail(id) {
  return getApiV2(endPoints.orderLandingPageDetail(id));
}

export function getOrderLandingPageByCode(params) {
  return getApiV2(endPoints.orderLandingPageByCode, params);
}

export function getOrderLandingPageByOrderBatch(params) {
  return getApiV2(endPoints.getOrderLandingPageByOrderBatch, params);
}

export function genPaymentQrCode(params) {
  return getApiV2(endPoints.genPaymentQrCode, params);
}

export function getPaymentQrCodeContent(params) {
  return getApiV2(endPoints.getPaymentQrCodeContent, params);
}

export function orderBatchDetail(id) {
  return getApiV2(endPoints.orderBatchDetail(id));
}

export function getProductByIds(params) {
  return getApiV2(endPoints.listProductById, params);
}

export function updateMultipleProduct(params) {
  return postApiV2(endPoints.updateMultipleProduct(), params);
}

export function searchOrders(params) {
  return postApiV2(endPoints.orders, params)
}

export function createOrder(params) {
  return postApiV2(endPoints.create, params);
}

export function getCustomerByPhoneNumber(phoneNumber) {
  return getApiV2(endPoints.customerByPhone(phoneNumber));
}

export function createCustomer(params) {
  return postApiV2(endPoints.createCustomer, params);
}

export function updateCustomer(params, id) {
  return putApiV2(endPoints.updateCustomer(id), params);
}

export function getPriceDetail(id) {
  return getApiV2(endPoints.priceDetail(id));
}

export function getProducts(params) {
  return getApiV2(endPoints.getProducts, params);
}

export function getOrderBatchSummaryProductOrder(id) {
  return getApiV2(endPoints.getOrderBatchSummaryProductOrder(id));
}


export function getOrder( id) {
  return getApiV2(endPoints.getOrder(id));
}


export function updateOrder(params, id) {
  return putApiV2(endPoints.update(id), params);
}

export function deleteOrder(id) {
  return deleteApiV2(endPoints.deleteOrder(id));
}

export function getOrderByPhone(params) {
  return getApiV2(endPoints.getOrderByPhone, params);
}

export function getSeller(id) {
  return getApiV2(endPoints.getSeller(id));
}