import { useTranslate } from 'core/i18n/i18nProvider';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedNumber } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getOrderAction } from '../redux/orderActions';
import './orderDetail.scss';

function HistoryOrderDetail({orderId, setShowDetail, orderBatch}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const [orderDetail, setOrderDetail] = useState({});

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderAction(orderId)).then((res) => {
        const detailOrder = cloneDeep(res?.data?.details)?.map((order, idx) => ({
          index: idx,
          ...order,
        })).filter(order => order.package_quantity > 0);
        setOrderDetail({...res.data, details: detailOrder});
      });
    }
  }, [orderId]);

  const packageColumn = [
    {
      dataField: 'product.name',
      text: 'Sản phẩm',
    },
    {
      dataField: 'package_name',
      text: 'Gói',
    },
    {
      dataField: 'package_quantity',
      text: 'Số gói',
      classes: 'text-end',
      headerClasses: 'text-end',
      style: {
        width: '100px',
      },
    },
    {
      dataField: 'price',
      text: 'Giá/ĐVT',
      classes: 'text-end',
      headerClasses: 'text-end',
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell} />
          </span>
        );
      },
    },
    {
      dataField: 'total_quantity',
      text: 'Tổng Số',
      editorStyle: {
        height: '28px',
        width: '90px',
      },
      editorClasses: 'text-end',
      classes: 'text-end',
      headerClasses: 'text-end',
      style: {
        width: '100px',
      },
    },
    {
      dataField: 'amount',
      text: 'Thành tiền',
      classes: 'text-end',
      headerClasses: 'text-end',
      style: {
        width: '100px',
      },
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell} />
          </span>
        );
      },
    },
  ];

  const statusOrder = (status) => {
    if (status === 'placed') {
      return <span className='badge bg-info'>{t('order_status_placed')}</span>;
    }
    if (status === 'delivered') {
      return <span className='badge bg-primary'>{t('order_status_delivered')}</span>;
    }
    if (status === 'success') {
      return <span className='badge bg-success'>{t('order_status_success')}</span>;
    }
  };

  const statusPlaymentOrder = (statusPayment) => {
    if (statusPayment === 'paid') {
      return <span className='badge bg-success'>Đã thanh toán</span>;
    }
    if (statusPayment === 'unpaid') {
      return <span className='badge bg-info'>Chưa thanh toán</span>;
    }
  };

  return (
    <div className='p-5'>
      <div onClick={() => setShowDetail(null)} className='text-end'>
        <i className='fs-1 fa-solid fa-xmark text-dark'></i>
      </div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Đợt hàng</div>
        <div className='col-lg-7'>{orderDetail?.order_batch?.name}</div>
      </div>

      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Ghi chú</div>
        <div className='col-lg-7'>
          <textarea
            className='form-control form-control-sm'
            defaultValue={orderDetail?.note}
            readOnly
          />
        </div>
      </div>
      <div className='separator'></div>
      <div className='mb-2 mt-2'>
        <div className=''>
          <BootstrapTable
            wrapperClasses='table-responsive'
            classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
            bootstrap4
            hover
            keyField='package_id'
            data={!orderDetail?.details ? [] : cloneDeep(orderDetail.details)}
            columns={packageColumn}
          />
        </div>
      </div>
      <div className='separator'></div>
      <div className='row mb-5 mt-2'>
        <div className='col-lg-5'>Tổng sản phẩm</div>
        <div className='col-lg-7 text-end'>
          <FormattedNumber value={orderDetail?.total_amount} />
        </div>
      </div>
      <div className='row mb-2 mt-2 d-none'>
        <div className='col-lg-5'>Giảm giá</div>
        <div className='col-lg-7'>
          <input
            type='text'
            className='form-control form-control-sm text-end'
            defaultValue={orderDetail?.discount}
            readOnly
          />
        </div>
      </div>
      <div className='row mb-5 mt-2'>
        <div className='col-lg-5 fw-bold  fs-2'>Thành tiền</div>
        <div className='col-lg-7 fw-bold text-danger text-end fs-2'>
          <FormattedNumber value={orderDetail?.total_amount} />
        </div>
      </div>
    </div>
  );
}

export default HistoryOrderDetail;
