import {NoRecordsFoundMessage, PleaseWaitMessage} from 'app/common/_helpers';
import {Input, LoadingDialog} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Field, Form, Formik} from 'formik';
import {cloneDeep} from 'lodash';
import {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {fetchProductsAction} from '../../redux/priceListActions';
import {Tab, Nav, Modal} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {FormattedNumber} from 'react-intl';

function PriceListEditForm({savePriceList, initPriceList, onHide, isEdit}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const {priceListId} = useParams();
  const priceListSchema = Yup.object().shape({
    name: Yup.string().required('Tên là bắt buộc'),
  });

  const {actionsLoading, products} = useSelector((state) => state.priceList, shallowEqual);
  const [productSelect, setProductSelect] = useState([]);
  const [selectedTab, setSelectedTab] = useState('product');

  const handleChangeMass = (value, row, column) => {
    if(value.includes(',')){
      value = value.replaceAll(',','.'); 
     };
    const cloneProducts = cloneDeep(productSelect);
    const newProducts = cloneProducts.map((product) => {
      if (row._id === product._id) {
        let modifiedProduct = {
          _id: product._id,
          name: product.name,
          product_id: product._id,
          price: product.price,
          discount_price_percent: product.discount_price_percent,
          discount_price: product.discount_price
        }
        modifiedProduct[column] = value;
        return modifiedProduct;
      }
      return product;
    });
    setProductSelect(newProducts);
  };

  const productColumns = [
    {
      dataField: 'name',
      text: t('product_name'),
      editable: false,
      classes: 'w-300px',
    },
    {
      dataField: 'price',
      text: 'Giá (đ/kg)',
      editable: true,
      editorStyle: {
        height: '30px',
        width: '100px',
      },
      formatter: (cell) => {
        return (
          <div className='border h-30px w-100'>
            {cell && <FormattedNumber value={cell} />}
          </div>
        );
      },
    },
    {
      dataField: 'discount_price_percent',
      text: 'Giảm Giá %',
      editable: true,
      editorStyle: {
        height: '30px',
        width: '100px',
      },
      formatter: (cell) => {
        return (
          <div className='border h-30px w-100'>
            {cell && <FormattedNumber value={cell} />}
          </div>
        );
      },
    },
    {
      dataField: 'discount_price',
      text: 'Giảm Giá đ',
      editable: true,
      editorStyle: {
        height: '30px',
        width: '100px',
      },
      formatter: (cell) => {
        return (
          <div className='border h-30px w-100'>
            {cell && <FormattedNumber value={Math.round(cell * 100) / 100} />}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProductsAction());
    }
  }, [products, dispatch]);

  useEffect(() => {
    let _productSelect = cloneDeep(products).map((product) => {
      let _price = null;
      let _discount_price_percent = null;
      let _discount_price = null;
      if (initPriceList.prices) {
        let productPrice = initPriceList.prices.find((price) => price.product_id === product._id);
        _price = productPrice?.price || null;
        _discount_price_percent = productPrice?.discount_price_percent || null;
        _discount_price = productPrice?.discount_price || null;
      }
      return {...product, price: _price, discount_price_percent: _discount_price_percent, discount_price: _discount_price};
    });
    _productSelect.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    setProductSelect(_productSelect);
  
    //setProductSelect(initPriceList.prices?.length ? initPriceList.prices : _productSelect);
  }, [products, initPriceList.prices]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initPriceList}
        validationSchema={priceListSchema}
        onSubmit={(values) => {
          const products = productSelect.map((product) => ({
            product_id: product._id,
            _id: product._id,
            name: product.name,
            price: product.price,
            discount_price_percent: product.discount_price_percent,
            discount_price: product.discount_price
          }));
          savePriceList({...values, prices: products});
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({handleSubmit, values}) => {
          return (
            <>
              <LoadingDialog isLoading={actionsLoading} text={t('common_loading')} />
              <Modal.Body className='overlaycursor-default'>
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <Field
                        name='name'
                        component={Input}
                        placeholder={'Tên'}
                        label={'Tên'}
                        focus
                      />
                    </div>
                  </div>
                  <div className='form-group row mt-4'>
                    <Tab.Container defaultActiveKey={selectedTab}>
                      {/*begin::Header*/}
                      <div className='d-flex align-items-center justify-content-between mb-5'>
                        <Nav
                          onSelect={setSelectedTab}
                          as='ul'
                          role='tablist'
                          className='nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10'
                        >
                          <Nav.Item as='li'>
                            <Nav.Link
                              eventKey='product'
                              className={`nav-link ${selectedTab === 'product' ? 'active' : ''}`}
                            >
                              Sản phẩm
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      {/*end::Header*/}

                      {/*begin::Content*/}
                      <div className='offcanvas-content px-10'>
                        <div className='tab-content'>
                          <div
                            id='kt_quick_panel_products'
                            role='tabpanel'
                            className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                              selectedTab === 'product' ? 'active show' : ''
                            }`}
                          >
                            <div className='mb-15'>
                              <BootstrapTable
                                wrapperClasses='table-responsive'
                                classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                                bootstrap4
                                bordered={true}
                                keyField='_id'
                                data={productSelect}
                                columns={productColumns}
                                cellEdit={cellEditFactory({
                                  mode: 'click',
                                  blurToSave: true,
                                  afterSaveCell: (oldValue, newValue, row, column) => {
                                    handleChangeMass(newValue, row, column.dataField);
                                  },
                                })}
                              >
                                <PleaseWaitMessage entities={products === null ? [] : products} />
                                <NoRecordsFoundMessage
                                  entities={products === null ? [] : products}
                                />
                              </BootstrapTable>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end::Content*/}
                    </Tab.Container>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' onClick={onHide} className='btn btn-light btn-elevate me-2'>
                  {t('common_cancel')}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary btn-elevate'
                  onClick={handleSubmit}
                >
                  {isEdit ? t('common_save') : t('common_add_new')}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default PriceListEditForm;
