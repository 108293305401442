import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import CustomerCategoryForm from './CustomerCategoryForm';
import {useDispatch} from 'react-redux';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import { editCustomerCategoryAction } from '../../redux/CustomerCategoryActions';

function EditCustomerCategory({show, onHide, onSaveSuccess, customerCategory}) {
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();
  const initCustomerCategory = {
    name: '',
    code: '',
    description: ''
  };

  const [customerCategoryEdit, setCustomerCategoryEdit] = useState(initCustomerCategory);

  useEffect(() => {
    const customerCategoryEdit = {
      name: customerCategory.name,
      code: customerCategory.code,
      description: customerCategory.description,
    };
    setCustomerCategoryEdit(customerCategoryEdit);
  }, [customerCategory]);

  const saveCustomerCategory = async (customerCategoryValues) => {
    try {
      let imageUrl = customerCategory?.image_url || '';
      const params = {
        ...customerCategoryValues,
        image_url: imageUrl,
        max_order: customerCategoryValues.maxOrder,
        unit_name: customerCategoryValues.unitName
      };
      dispatch(editCustomerCategoryAction(params, customerCategory._id)).then((res) => {
        if (res?.data?.success) {
          snackNotification.showSuccess(t('customerCategory_edit_success'));
          onSaveSuccess();
        } else {
          snackNotification.showError(t('customerCategory_edit_error'));
          onHide();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('customerCategory_edit_title')}</Modal.Title>
        </Modal.Header>
        <CustomerCategoryForm
          initCustomerCategory={customerCategoryEdit}
          onHide={onHide}
          saveCustomerCategory={saveCustomerCategory}
          isEdit={true}
        />
      </Modal>
    </div>
  );
}

export default EditCustomerCategory;
