/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import {Registration} from './components/Registration';
import {ForgotPassword} from './components/ForgotPassword';
import {toAbsoluteUrl} from '../../../core/helpers';
import {Login} from './components/Login';
import { useTranslate } from 'core/i18n/i18nProvider';
import { useSession } from 'core/store/hooks';

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body');
    return () => {
      document.body.classList.remove('bg-body');
    };
  }, []);

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
};

const AuthLayoutWidthGoggle = () => {
  useEffect(() => {
    document.body.classList.add('bg-body');
    return () => {
      document.body.classList.remove('bg-body');
    };
  }, []);

  return (
    <div
      className='d-flex flex-row flex-row-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      // }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 w-lg-50'>
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body p-10 p-lg-15 mx-auto'>
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Đăng nhập Optimi</h1>
          </div>
          {/* begin::Heading */}
          <div className='row g-3 mb-9'>
            <div className='col-md-12'>
              <a
                href='#'
                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                  className='h-15px me-3'
                />
                Sign in with Google
              </a>
            </div>
          </div>

          <div className='separator separator-content my-14 w-100'>
            <span className='w-150px text-gray-500 fw-semibold fs-7'>Hoặc với email</span>
          </div>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/misc/accounts/khoatin.png')})`,
        }}
      >
        <div className='d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100'>
          <div className='mb-0 mb-lg-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-144.png')}
              className='h-60px h-lg-75px'
            />
          </div>

          <img
            className='d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src='/media/misc/accounts/khoatin.png'
            alt=''
          />

          <h1 className='d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7'>
            Nhanh chóng, Hiệu quả và Năng xuất
          </h1>
        </div>
      </div>
      {/* end::Content */}
    </div>
  );
};

const AuthLayout2 = () => {
  const {t} = useTranslate()
  const {getDomain} = useSession();
  useEffect(() => {
    document.body.classList.add('bg-body');
    return () => {
      document.body.classList.remove('bg-body');
    };
  }, []);

  //Will get from api later
  const backgroundUrl = (getDomain() === 'khoatin' || getDomain() === 'khoatin1')? '/media/misc/accounts/khoatin.jpg': '/media/misc/auth-bg.png'

  return (
    <div className='d-flex flex-row flex-row-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 w-lg-50'>
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body p-10 p-lg-15 mx-auto'>
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>{t('login_title')}</h1>
          </div>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{
          backgroundImage: `url(${toAbsoluteUrl(backgroundUrl)})`,
        }}
      >
        {/* <div className='d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100'>
          <div className='mb-0 mb-lg-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-144.png')}
              className='h-60px h-lg-75px'
            />
          </div>

          <img
            className='d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src='/metronic8/demo1/assets/media/misc/auth-screens.png'
            alt=''
          />

          <h1 className='d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7'>
            {t('login_description')}
          </h1>
        </div> */}
      </div>
      {/* end::Content */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout2 />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export {AuthPage};
