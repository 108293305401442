import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api'

const endPoints = {
  listPriceList: '/api/price-list/search',
  addPriceList: '/api/price-list',
  editPriceList: (id) => `/api/price-list/${id}`,
  priceListDetail: (id) => `/api/price-list/${id}`,
  deletePriceList: (id) => `/api/price-list/${id}`,
  duplicatePriceList: (id) => `/api/price-list/${id}/duplicate`,
  listProduct: '/api/product',
  listProductById: '/api/product/ids',
};

export function getPriceLists(params) {
  return postApiV2(endPoints.listPriceList, params)
}

export function addPriceList(params) {
  return postApiV2(endPoints.addPriceList, params);
}

export function editPriceList(params, id) {
  return putApiV2(endPoints.editPriceList(id), params);
}

export function priceListDetail(id) {
  return getApiV2(endPoints.priceListDetail(id));
}

export function deletePriceList(id) {
  return deleteApiV2(endPoints.deletePriceList(id));
}


export function duplicatePriceList(id) {
  return postApiV2(endPoints.duplicatePriceList(id));
}


export function getProducts(params) {
  return getApiV2(endPoints.listProduct, params);
}

export function getProductByIds(params) {
  return getApiV2(endPoints.listProductById, params);
}