import {Route, Routes, Outlet, Navigate} from 'react-router-dom';
import ShortLinkPage from './page/ShortLinkPage';
import ShortLinkEditPage from './page/Modal/ShortLinkEditPage';
import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';
import ShortLinkPublicPage from './page/ShortLinkPublicPage';

const ShortLinkRoutes = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/:shortLinkId'
          element={
            <>
              <PageAside>
                <Menu />
              </PageAside>
              <ShortLinkEditPage />
            </>
          }
        />
        <Route
          path='/pub/:shortLink'
          element={
            <>
              <ShortLinkPublicPage />
            </>
          }
        />
        <Route
          element={
            <>
              <ShortLinkPage />
            </>
          }
        />
      </Route>
      <Route index element={<ShortLinkPage />} />
    </Routes>
  );
};

export default ShortLinkRoutes;
