import {Input, LoadingDialog} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Field, Form, Formik} from 'formik';
import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import {
  createCustomerAction,
  getCustomerByPhoneNumberAction,
  updateCustomerAction,
} from '../../redux/orderActions';
import {setStorage} from 'app/common/_helpers';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function ModalConfirmCustomerOrder({
  show,
  userCustomer,
  onHide,
  onPayCart,
  className = '',
  textNote,
  setTextNote,
}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();

  const CustomerSchema = Yup.object().shape({
    phone_delivery: Yup.string()
      .required('Số điện thoại là thông tin bắt buộc')
      .matches(phoneRegExp, 'Số điện thoại không đúng định dạng'),
  });

  const [editForm, setEditForm] = useState(false);

  const initialCustomer = {
    name: userCustomer.name,
    address: userCustomer.address,
    deliver: {
      name: userCustomer?.deliver?.name || userCustomer?.name,
      address: userCustomer?.deliver?.address || userCustomer?.address,
    },
    phone: userCustomer.phone,
    phone_delivery: userCustomer.phone_delivery,
  };

  const handleUpdateCustomer = async (values) => {
    if (editForm) {
      try {
        const params = {
          name: values.name,
          deliver: values?.deliver,
          address: values.address,
          phone: userCustomer.phone,
          phone_delivery: values.phone_delivery,
        };
        const res = await dispatch(updateCustomerAction(params, userCustomer._id));
        if (res?.data?.data) {
          onPayCart(params);
          const timeExpire = 24 * 60 * 60 * 10;
          setStorage('phoneCustomer', userCustomer.phone, timeExpire);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      onPayCart(initialCustomer);
    }
  };

  return (
    <div>
      <Modal show={show} className={className}>
        <Modal.Header>
          <Modal.Title>
            <div className='d-flex align-items-center justify-content-between'>
              <div>{'Kiểm tra thông tin nhận hàng'}</div>
              <div className='text-primary ms-5 cursor-pointer' onClick={() => setEditForm(true)}>
                Chỉnh sửa
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize={true}
          initialValues={initialCustomer}
          validationSchema={CustomerSchema}
          onSubmit={(values) => {
            handleUpdateCustomer(values);
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({handleSubmit}) => {
            return (
              <>
                <LoadingDialog isLoading={false} text={'loading'} />
                <Modal.Body className='overlaycursor-default'>
                  <Form className='form form-label-right'>
                    <div className='form-group row'>
                      <div className='col-lg-12'>
                        <Field
                          disabled={!editForm}
                          name='deliver.name'
                          component={Input}
                          placeholder={'Tên người nhận hàng'}
                          label={'Tên người nhận hàng'}
                          focus
                        />
                      </div>
                    </div>
                    <div className='form-group row mt-2'>
                      <div className='col-lg-12'>
                        <Field
                          disabled={!editForm}
                          name='phone_delivery'
                          component={Input}
                          type='text'
                          placeholder={'Nhập số điện thoại'}
                          label={'Số điện thoại nhận hàng'}
                          withFeedbackLabel={true}
                          customFeedbackLabel={true}
                        />
                      </div>
                    </div>
                    <div className='form-group row mt-2'>
                      <div className='col-lg-12'>
                        <Field
                          disabled={!editForm}
                          name='deliver.address'
                          component={Input}
                          placeholder={'Địa chỉ nhận hàng'}
                          label={'Địa chỉ nhận hàng'}
                        />
                      </div>
                    </div>
                    <div className='mt-4'>
                      <div className='fw-bold'>Ghi chú</div>

                      <div className='mt-3'>
                        <textarea
                          className='w-100 p-2'
                          name=''
                          id=''
                          cols='30'
                          rows='5'
                          disabled={!editForm}
                          value={textNote}
                          onChange={(e) => setTextNote(e.target.value)}
                        ></textarea>
                      </div>
                      {editForm && (
                        <div className='d-flex align-items-center mt-2 mb-2'>
                          <div
                            className='border rounded-pill py-1 px-3 me-2'
                            onClick={() => setTextNote('Giao ngoài giờ hành chính')}
                          >
                            Giao ngoài giờ hành chính
                          </div>
                          <div
                            className='border rounded-pill py-1 px-3 me-2'
                            onClick={() => setTextNote('Giao trong giờ hành chính')}
                          >
                            Giao trong giờ hành chính
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                    Huỷ
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary btn-elevate'
                    onClick={handleSubmit}
                  >
                    Đặt hàng
                  </button>
                </Modal.Footer>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

export default ModalConfirmCustomerOrder;
