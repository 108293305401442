import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import CustomerForm from './CustomerForm';
import {useDispatch} from 'react-redux';
import {editCustomersAction} from '../../redux/customerActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { useTranslate } from 'core/i18n/i18nProvider';

function EditCustomer({show, onHide, onSaveSuccess, customer}) {
  const dispatch = useDispatch();
  const {t} = useTranslate()
  const snackNotification = useSnackNotification();
  const initCustomer = {
    name: '',
    address: '',
    phone: '',
    phone_delivery: '',
    customer_categories: [],
    password: ''
  };

  const [customerEdit, setCustomerEdit] = useState(initCustomer);

  useEffect(() => {
    const customerEdit = {
      name: customer.name,
      address: customer.address,
      phone: customer.phone,
      phone_delivery: customer.phone_delivery,
      customer_categories: customer.customer_categories
    };
    setCustomerEdit(customerEdit);
  }, [customer]);

  const saveCustomer = (customerValue) => {
    const params = {
      ...customerValue,
    };
    dispatch(editCustomersAction(params, customer._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('customer_edit_success'));
        onSaveSuccess();
      } else {
        snackNotification.showError(t('customer_edit_error'));
        onHide();
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('customer_edit_title')}</Modal.Title>
        </Modal.Header>
        <CustomerForm
          initCustomer={customerEdit}
          onHide={onHide}
          saveCustomer={saveCustomer}
          isEdit={true}
        />
      </Modal>
    </div>
  );
}

export default EditCustomer;
