import {CardHeaderToolbar, LoadingDialog, Pagination} from 'app/common/_partials/controls';
import {useIntl} from 'react-intl';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  defaultTableOptions,
  getHandlerTableChange,
} from 'app/common/_helpers';
import {useCallback, useEffect, useState} from 'react';
import {isEqual, isFunction} from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from 'app/common/_helpers';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {deleteCustomerAction, fetchCustomersAction} from '../redux/customerActions';
import AddCustomer from './Modal/AddCustomer';
import EditCustomer from './Modal/EditCustomer';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import CustomerListFilter from './CustomerListFilter';

export default function CustomerListPage() {
  const intl = useIntl();
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {customers, countCustomers} = useSelector((state) => state.customer, shallowEqual);
  const [customer, setCustomer] = useState(null);
  const [customerDelete, setCustomerDelete] = useState(null);

  const [showAddCustomer, setShowAddCustomer] = useState(false);

  const tableOptions = {
    ...defaultTableOptions,
    searchText: null,
  };

  const [queryParams, setQueryParamsBase] = useState(tableOptions);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      return nextQueryParams;
    });
  }, []);

  const customerColumns = [
    {
      dataField: 'name',
      text: t('customer_name'),
    },
    {
      dataField: 'address',
      text: t('customer_address'),
    },
    {
      dataField: 'phone',
      text: t('customer_phone'),
    },
    {
      dataField: 'phone_delivery',
      text: t('customer_phone_deliver'),
    },
    {
      dataField: 'customer_categories',
      text: t('customer_category'),
      formatter: CustomerCategoryFormatter,
    },
    {
      dataField: 'action',
      text: '',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEdit: setCustomer,
        openDelete: setCustomerDelete,
      },
      classes: 'text-right pe-0',
      headerClasses: 'text-right pe-3',
      style: {
        width: '50px',
      },
    },
  ];

  const onSaveSuccess = () => {
    loadCustomers();
    setShowAddCustomer(false);
    setCustomer(null);
    setCustomerDelete(null);
  };

  const handleDeleteCustomer = () => {
    dispatch(deleteCustomerAction(customerDelete._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('customer_delete_success'));
        loadCustomers();
        setCustomerDelete(null);
      } else {
        snackNotification.showError(t('customer_delete_error'));
        setCustomerDelete(null);
      }
    });
  };

  useEffect(() => {
    loadCustomers();
  }, [dispatch, queryParams]);

  const loadCustomers = () => {
    const params = {
      ...queryParams,
      paging: {
        pageNumber: queryParams.pageNumber,
        pageSize: queryParams.pageSize,
      },
    };
    dispatch(fetchCustomersAction(params));
  }

  return (
    <>
      <LoadingDialog isLoading={false} text='Loading ...' />
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('customer_title_name')}</h3>
              <CardHeaderToolbar>
                <div className='d-flex'>
                  <CustomerListFilter setQueryParams={setQueryParams} queryParams={queryParams} />
                  <button
                    type='button'
                    className='btn btn-sm btn-primary ms-2 w-100'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowAddCustomer(true);
                    }}
                  >
                    {intl.formatMessage({id: 'common_add_new'})}
                  </button>
                </div>
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <PaginationProvider
                pagination={paginationFactory({
                  ...tableOptions,
                  totalSize: countCustomers,
                  page: queryParams.pageNumber,
                  withFirstAndLast: false,
                  hideSizePerPage: true,
                })}
              >
                {({paginationProps, paginationTableProps}) => {
                  return (
                    <Pagination isLoading={false} paginationProps={paginationProps}>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                        bootstrap4
                        remote
                        hover
                        keyField='_id'
                        onTableChange={getHandlerTableChange(setQueryParams)}
                        data={customers === null ? [] : customers}
                        columns={customerColumns}
                        {...paginationTableProps}
                      >
                        <PleaseWaitMessage entities={customers === null ? [] : customers} />
                        <NoRecordsFoundMessage entities={customers === null ? [] : customers} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </div>
      <AddCustomer
        show={showAddCustomer}
        onHide={() => setShowAddCustomer(false)}
        onSaveSuccess={onSaveSuccess}
      />
      {customer && (
        <EditCustomer
          show={customer ? true : false}
          customer={customer}
          onHide={() => setCustomer(null)}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {customerDelete && (
        <ModalDelete
          onClick={handleDeleteCustomer}
          show={customerDelete ? true : false}
          title={'Xoá khách hàng'}
          content={'Bạn muốn xoá khách hàng'}
          deleteName={customerDelete.name}
          close={() => setCustomerDelete(null)}
        />
      )}
    </>
  );
}

const CustomerCategoryFormatter = (cellContent) => {

  const getCustomerCategoryValue = () => {
    if(cellContent && Array.isArray(cellContent)) return cellContent.map(item => item.label).toString()
    return ''
  }

  return <span>{getCustomerCategoryValue()}</span>
}

const ActionsColumnFormatter = (cellContent, row, rowIndex, {openEdit, openDelete}) => (
  <div className='d-flex'>
    <a
      className='btn btn-hover-primary btn-xs mx-2'
      key='edit'
      onClick={(event) => {
        event.stopPropagation();
        openEdit(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
      </span>
    </a>
    <a
      className='btn btn-hover-primary btn-xs mx-2'
      key='delete'
      onClick={(event) => {
        event.stopPropagation();
        openDelete(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
      </span>
    </a>
  </div>
);
