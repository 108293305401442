import {Route, Routes, Outlet, Navigate} from 'react-router-dom';
import ProductCategoryPage from './page/ProductCategoryPage';


const ProductCategoryRoutes = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          element={
            <>
              <ProductCategoryPage />
            </>
          }
        />
      </Route>
      <Route index element={<ProductCategoryPage />} />
    </Routes>
  );
};

export default ProductCategoryRoutes;
