/* eslint-disable react-hooks/exhaustive-deps */
import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: any
  setPageTitle: (_title: any) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  pageTools?: any
  setPageTools: (_tools: any) => void
  pageAside?: any
  setPageAside: (_tools: any) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: any) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageTools: (_tools: any) => {},
  setPageAside: (_aside: any) => {}
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<any>('')
  const [pageTools, setPageTools] = useState<any>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [pageAside, setPageAside] = useState<any>('')

  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageAside,
    setPageAside,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageTools,
    setPageTools
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
}

const PageTitle: FC<Props & WithChildren> = ({children, description, breadcrumbs}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children)
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}


const PageToolbar: FC<WithChildren> = ({children}) => {
  const {setPageTools} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTools(children)
    }
    return () => {
      setPageTools('')
    }
  }, [children])
  return <></>
}


const PageAside: FC<WithChildren> = ({children}) => {
  const {setPageAside} = usePageData()
  useEffect(() => {
    if (children) {
      setPageAside(children)
    }
    return () => {
      setPageAside('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageToolbar, PageAside, PageDataProvider, usePageData}
