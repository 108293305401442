import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider, useIntl} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import enMessages from './messages/en.json'

import {WithChildren} from '../helpers'

const viMessages = () => {
  const viAllMessages = {
    ...require('./messages/vi.json'),
    ...require('./old-messages/vi.json'),
    ...require('../../app/modules/teams/i18n/vi.json'),
    ...require('../../app/modules/profile/i18n/vi.json'),
    ...require('../../app/modules/auth/i18n/vi.json'),
    ...require('../../app/modules/products/i18n/vi.json'),
    ...require('../../app/modules/order-batch/i18n/vi.json'),
    ...require('../../app/modules/order/i18n/vi.json'),
    ...require('../../app/modules/customers/i18n/vi.json'),
    ...require('../../app/modules/price-lists/i18n/vi.json'),
    ...require('../../app/modules/short-links/i18n/vi.json'),
    ...require('../../app/modules/product-category/i18n/vi.json'),
    ...require('../../app/modules/customer-category/i18n/vi.json'),
    ...require('../../app/modules/landing-page-order/i18n/vi.json'),
    ...require('../../app/modules/seller/i18n/vi.json'),
  }
  return viAllMessages
}

const allMessages = {
  en: enMessages,
  vi: viMessages(),
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages} timeZone='asia/ho_chi_minh'>
      {children}
    </IntlProvider>
  );
}

export {I18nProvider}

export const useTranslate = () => {
  const {formatMessage} = useIntl()

  const t = (id: string) => {
    return formatMessage({id: id})
  }

  return {t}
}
