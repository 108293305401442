import React from 'react';
import {Field, Form, Formik} from 'formik';
import {isEqual} from 'lodash';
import {InputGroup} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import {SelectField} from 'app/common/_partials/controls';

const prepareFilter = (queryParams, values) => {
  const {searchText} = values;
  const newQueryParams = {...queryParams};
  if (!newQueryParams.filter) {
    newQueryParams.filter = {};
  }
  newQueryParams.filter.q = searchText;
  newQueryParams.searchText = searchText;
  return newQueryParams;
};

export default function ProductCategoryListFilter({listLoading, queryParams, setQueryParams}) {
  const {t} = useTranslate();

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(queryParams, values);
    if (!newQueryParams.paging) {
      newQueryParams.paging = {};
    }
    newQueryParams.paging.pageNumber = 1;
    setQueryParams(newQueryParams);
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: '',
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({values, handleSubmit, handleBlur, setFieldValue}) => (
          <Form className='form form-label-right d-flex w-100 d-flex'>
            <InputGroup size='sm' className='w-250px'>
              <input
                type='search'
                className='form-control'
                name='searchText'
                autoComplete='off'
                placeholder={'Tìm kiếm theo tên'}
                onBlur={handleBlur}
                value={values.searchText}
                onChange={(e) => {
                  setFieldValue('searchText', e.target.value);
                }}
              />
              <button type='button' onClick={handleSubmit} className='btn btn-secondary d-inline'>
                {t('common_search')}
              </button>
            </InputGroup>
          </Form>
        )}
      </Formik>
    </>
  );
}
