import {Input, LoadingDialog} from 'app/common/_partials/controls';
import {Field, FieldArray, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {shallowEqual, useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import DropFileInput from 'app/common/_partials/controls/forms/DropFileInput';
import { newId } from 'app/common/_helpers/idHelpers';

function PriceListForm({savePriceList, initPriceList, onHide, isEdit}) {
  const {t} = useTranslate();
  const priceListAddSchema = Yup.object().shape({
    name: Yup.string().required('Tên là bắt buộc'),
  });

  const {actionsLoading} = useSelector((state) => state.priceList, shallowEqual);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initPriceList}
        validationSchema={priceListAddSchema}
        onSubmit={(values) => {
          savePriceList(values);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({handleSubmit, values}) => {
          return (
            <>
              <LoadingDialog isLoading={actionsLoading} text={t('common_loading')} />
              <Modal.Body className='overlaycursor-default'>
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <Field
                        name='name'
                        component={Input}
                        placeholder={'Tên'}
                        label={'Tên'}
                        focus
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                  {t('common_cancel')}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary btn-elevate'
                  onClick={handleSubmit}
                >
                  {isEdit ? t('common_save') : t('common_add_new')}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default PriceListForm;
