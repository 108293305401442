import React from 'react';
import logo from './assets/logo.jpg';
import mask from './assets/mask.png';
import './orderPageHeroSection.scss';
import './orderPage.scss';

function OrderPageHeroSection() {
  return (
    <div className='container d-flex flex-column align-items-center mt-10'>
      <div>
        <img src={logo} className='logo' />
      </div>
      <div className='hero-section-container w-100 align-items-center'>
        <div className='hero-content'>
          <p className='hero-title'>Dự án khởi nghiệp</p>
          <h1 className='hero-heading'>Tinh dầu Bambi</h1>
          <div className='image-mobile'>
            <img className='hero-image' src={mask} />
          </div>
          <ul className='mt-5 mb-5 d-flex flex-wrap section-list-product'>
            <li>Tinh dầu mùi già</li>
            <li>Tinh dầu sả chanh</li>
            <li>Tinh dầu bạc hà</li>
          </ul>
          <button className='order-page-button-primary'>mua ngay</button>
        </div>
        <div className='image-desktop'>
          <img className='hero-image' src={mask} />
        </div>
      </div>
    </div>
  );
}

export default OrderPageHeroSection;
