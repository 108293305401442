import React from 'react';
import aboutImage from './assets/about_us.jpg';
import './orderPageAboutSection.scss';
import './orderPage.scss';
function OrderPageAboutSection() {
  return (
    <div className='about-section-container container'>
      <div>
        <p className='text-content'>
          Xin chào, chúng tôi là <strong>7LTD!</strong>
        </p>

        <p className='text-content'>
          7 Ltd, hay 7 Lọ Tinh Dầu, là một <strong>dự án startup tinh dầu</strong> của một nhóm học
          sinh lớp 11 thuộc chương trình IBDP Trường PTLC Olympia, với sản phẩm đầu tay là Tinh Dầu
          Bambi. 7Ltd tập trung vào sản xuất các sản phẩm tinh dầu được chiết xuất sử dụng các
          nguyên liệu hoàn toàn tự nhiên. Sản phẩm Bambi của 7LTD được nghiên cứu và kiểm chứng dưới
          sự hướng dẫn của các chuyên gia, và lợi nhuận của dự án sẽ được sử dụng cho{' '}
          <strong>mục đích từ thiện</strong>
        </p>

        <p className='text-content'>
          Nhân dịp Tết Nguyên Đán, 7LTD cho <strong>ra mắt sản phẩm đầu tay</strong> với ba dòng sản
          phẩm là tinh dầu Bambi hương mùi già, chanh sả và bạc hà. Tinh dầu Bambi hứa hẹn sẽ là một{' '}
          <strong>món quà Tết hoàn hảo</strong> cho bạn dành cho người thân và gia đình!
        </p>
      </div>
      <div className='about-image-container'>
        <img src={aboutImage} />
      </div>
    </div>
  );
}

export default OrderPageAboutSection;
