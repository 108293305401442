import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';
import ProductCategoryListPage from './ProductCategoryListPage';
const ProductCategoryPage = () => {
  return (
    <div>
      <PageAside>
        <Menu />
      </PageAside>
      <ProductCategoryListPage/>
    </div>
  );
};

export default ProductCategoryPage;
