
export const menus: ReadonlyArray<{link: string; icon: string; tooltip: string; secondary: boolean}> = [
  {
    link: 'product',
    icon: '/media/icons/duotune/abstract/abs027.svg',
    tooltip: 'Product',
    secondary: true,
  },
  {
    link: 'batch-order',
    icon: '/media/icons/duotune/abstract/abs027.svg',
    tooltip: 'OrderBatch',
    secondary: true,
  },
  {
    link: 'order',
    icon: '/media/icons/duotune/abstract/abs027.svg',
    tooltip: 'order',
    secondary: true,
  },
];