import {Input, LoadingDialog} from 'app/common/_partials/controls';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import Select from 'react-select';
import {useEffect} from 'react';
import {fetchCustomerCategoriesAction} from '../../redux/customerActions';
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function CustomerForm({saveCustomer, initCustomer, onHide, isEdit, customerCategory}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const customerAddSchema = Yup.object().shape({
    name: Yup.string().required('Tên là bắt buộc'),
    phone: Yup.string()
      .required('Số điện thoại là thông tin bắt buộc')
      .matches(phoneRegExp, 'Số điện thoại không đúng định dạng'),
    phone_delivery: Yup.string()
      .required('Số điện thoại nhận hàng là thông tin bắt buộc')
      .matches(phoneRegExp, 'Số điện thoại không đúng định dạng'),
  });

  const {actionsLoading, customerCategories} = useSelector((state) => state.customer, shallowEqual);

  useEffect(() => {
    dispatch(fetchCustomerCategoriesAction());
  }, []);

  const getCustomerCategoriesOption = () => {
    return customerCategories.map((item) => {
      return {value: item._id, label: item.name, code: item.code};
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initCustomer}
        validationSchema={customerAddSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({handleSubmit, values, setFieldValue}) => {
          return (
            <>
              <LoadingDialog isLoading={actionsLoading} text={t('common_loading')} />
              <Modal.Body className='overlaycursor-default'>
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <Field
                        name='name'
                        component={Input}
                        placeholder={t('customer_name')}
                        label={t('customer_name')}
                        focus
                      />
                    </div>
                  </div>
                  <div className='form-group row mt-4'>
                    <div className='col-lg-12'>
                      <Field
                        name='address'
                        component={Input}
                        placeholder={t('customer_address')}
                        label={t('customer_address')}
                      />
                    </div>
                  </div>
                  <div className='form-group row mt-4'>
                    <div className='col-lg-12'>
                      <Field
                        name='phone'
                        component={Input}
                        placeholder={t('customer_phone')}
                        label={t('customer_phone')}
                      />
                    </div>
                  </div>
                  <div className='form-group row mt-4'>
                    <div className='col-lg-12'>
                      <Field
                        name='phone_delivery'
                        component={Input}
                        placeholder={t('customer_phone_deliver')}
                        label={t('customer_phone_deliver')}
                      />
                    </div>
                  </div>
                  <div className='form-group row mt-4'>
                    <div className='col-lg-12'>
                      <label>{t('customer_category')}</label>
                      <Select
                        defaultValue={values.customer_categories ?? []}
                        isMulti
                        name='customer_categories'
                        options={getCustomerCategoriesOption()}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        onChange={(value) => setFieldValue('customer_categories', value)}
                      />
                    </div>
                  </div>
                  {!isEdit && (
                    <div className='form-group row mt-4'>
                      <div className='col-lg-12'>
                        <Field
                          type='password'
                          name='password'
                          component={Input}
                          placeholder={t('customer_password')}
                          label={t('customer_password')}
                        />
                      </div>
                    </div>
                  )}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                  {t('common_cancel')}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary btn-elevate'
                  onClick={handleSubmit}
                >
                  {isEdit ? t('common_save') : t('common_add_new')}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default CustomerForm;
