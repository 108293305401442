import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useRef, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactToPrint from 'react-to-print';
import {OrderPrint} from './OrderPrint';
import {FormattedNumber} from 'react-intl';
import {cloneDeep, forEach, isNumber} from 'lodash';
import {Dropdown} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import './orderDetail.scss';
import {
  getPaymentQrCodeContentAction,
  getOrderAction,
  getOrderBatchDetailAction,
  getOrderLandingPageByOrderBatchAction,
  getSellerAction,
  updateOrderAction,
} from '../redux/orderActions';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {orderBatchDetail} from '../redux/orderApi';
import Select from 'react-select';
import {OrderPrintRau} from './OrderPrintRau';
import {removeSign} from 'app/common/_helpers';

function OrderDetail({ orderId, onLoadOrder}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const invoicePrintRef = useRef();
  const [orderDetail, setOrderDetail] = useState({});
  const [orderBatch, setOrderBatch] = useState(null);
  const [isUpdateOrder, setIsUpdateOrder] = useState(false);
  const [productById, setProductById] = useState({});
  const [orderLandingPage, setOrderLandingPage] = useState(null);
  const [qrOderImage, setQrOderImage] = useState(null);
  const [seller, setSeller] = useState(null);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderAction(orderId)).then((res) => {
        const detailOrder = cloneDeep(res?.data?.details)
          ?.map((order, idx) => ({
            index: idx,
            ...order,
          }))
          .filter((product) => product.package_quantity > 0 || product.package_quantity === null);

        const orderDetail = {
          ...res.data,
          details: detailOrder,
        };
        if (orderDetail.invoice) {
          setOrderDetail(orderDetail);
        } else {
          orderDetail.invoice = {label: 'Rau', value: 'rau'};
          setOrderDetail(orderDetail);
        }

        dispatch(getOrderBatchDetailAction(res.data.order_batch_id)).then((resOrderBatch) => {
          if (resOrderBatch.data.data) {
            setOrderBatch(resOrderBatch.data.data);
            const orderBatchById = {};
            resOrderBatch.data.data?.products.forEach((orderBatch) => {
              orderBatchById[orderBatch._id] = orderBatch;
            });
            setProductById(orderBatchById);
          }
        });
      });
    }
  }, [orderId]);

  useEffect(() => {
    if (orderDetail?.order_batch_id) {
      dispatch(
        getOrderLandingPageByOrderBatchAction({orderBatchId: orderDetail.order_batch_id})
      ).then((res) => {
        if (res.data.data) {
          setOrderLandingPage(res.data.data);
        }
      })
    }
  }, [orderDetail.order_batch_id, dispatch]);

  useEffect(() => {
    if (orderBatch?.seller) {
      dispatch(getSellerAction(orderBatch.seller)).then((res) => {
        if (res.data.data) {
          setSeller(res.data.data);
        }
      });
    }
  }, [orderBatch?.seller]);

  useEffect(() => {
    if (seller?.settings?.bank && seller?.settings?.bankAccount && orderDetail?.total_amount) {
      const params = {
        bankId: seller.settings.bank,
        bankAccount: seller.settings.bankAccount,
        price: orderDetail?.total_amount.toString(),
        content: `${removeSign(orderDetail.customer.name)} ${orderDetail.phone} ${orderDetail.order_code}` || '',
      };
      
      dispatch(getPaymentQrCodeContentAction(params)).then((res) => {
        const uri = res.data.data;
        setQrOderImage(uri);
      });
    }
  }, [seller?.settings, orderDetail]);

  const packageColumn = [
    {
      dataField: 'product.name',
      text: 'Sản phẩm',
      editable: false,
    },
    {
      dataField: 'product.code',
      text: 'Code',
      editable: false,
    },
    {
      dataField: 'package_name',
      text: 'Gói',
      editable: false,
    },
    {
      dataField: 'package_quantity',
      text: 'Số gói',
      editable: false,
      classes: 'text-end',
      headerClasses: 'text-end',
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={Math.round(cell * 100) / 100} />
          </span>
        );
      },
      style: {
        width: '100px',
      },
    },
    {
      dataField: 'price',
      text: 'Giá/ĐVT',
      editable: false,
      classes: 'text-end',
      headerClasses: 'text-end',
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell} />
          </span>
        );
      },
    },
    {
      dataField: 'total_quantity',
      text: 'Tổng Số',
      editorStyle: {
        height: '28px',
        width: '90px',
      },
      editorClasses: 'text-end',
      classes: 'text-end',
      headerClasses: 'text-end',
      style: {
        width: '100px',
      },
      formatter: (cell) => {
        return (
          <div className='border h-30px'>
            <FormattedNumber value={Math.round(cell * 100) / 100} />
          </div>
        );
      },
    },
    {
      dataField: 'amount',
      text: 'Thành tiền',
      editable: false,
      classes: 'text-end',
      headerClasses: 'text-end',
      style: {
        width: '100px',
      },
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell} />
          </span>
        );
      },
    },
  ];

  const statusOption = [
    {
      value: 'placed',
      label: t('order_status_placed'),
    },
    {
      value: 'delivered',
      label: t('order_status_delivered'),
    },
    {
      value: 'success',
      label: t('order_status_success'),
    },
  ];

  const paymentStatusOption = [
    {
      value: 'paid',
      label: 'Đã thanh toán',
    },
    {
      value: 'unpaid',
      label: 'Chưa thanh toán',
    },
  ];

  const statusOrder = (status) => {
    if (status === 'placed') {
      return <span className='badge bg-info'>{t('order_status_placed')}</span>;
    }
    if (status === 'delivered') {
      return <span className='badge bg-primary'>{t('order_status_delivered')}</span>;
    }
    if (status === 'success') {
      return <span className='badge bg-success'>{t('order_status_success')}</span>;
    }
  };

  const statusPlaymentOrder = (statusPayment) => {
    if (statusPayment === 'paid') {
      return <span className='badge bg-success'>Đã thanh toán</span>;
    }
    if (statusPayment === 'unpaid') {
      return <span className='badge bg-info'>Chưa thanh toán</span>;
    }
  };

  const handleEditOrder = (field, oldValue, newValue) => {
    if (oldValue !== newValue) {
      const _orderDetail = cloneDeep(orderDetail);
      _orderDetail[field] = newValue;
      const params = {
        [field]: newValue,
      };
      dispatch(updateOrderAction(params, orderDetail._id)).then((res) => {
        if (res.data.success) {
          setOrderDetail(_orderDetail);
          setIsUpdateOrder(true);
        }
      });
    }
  };

  const calculateCartSummary = (products) => {
    let totalQuantity = 0;
    let totalProduct = 0;
    let totalAmount = 0;
    products.forEach((product) => {
      totalAmount += product.amount;
      totalQuantity += product.totalQuantity;
      totalProduct += 1;
    });

    return {
      totalQuantity,
      totalProduct,
      totalAmount,
    };
  };

  const handleChangePackageQuantity = (oldValue, newValue, row, column) => {
    const packageQuantity = Number(newValue);
    if (isNumber(packageQuantity) && oldValue !== packageQuantity) {
      const newTotalQuantity = packageQuantity - oldValue + row.product.totalQuantity;
      if (newTotalQuantity > row.product.maxOrderQuantity) {
        snackNotification.showWarning(
          `Số lượng còn lại là ${row.product.maxOrderQuantity - row.product.totalQuantity}`
        );
        return;
      }

      const _orderDetail = cloneDeep(orderDetail.details);
      _orderDetail[row.index]['product'] = {
        ..._orderDetail[row.index]['product'],
        totalQuantity: packageQuantity,
      };
      _orderDetail[row.index]['package_quantity'] = packageQuantity;
      _orderDetail[row.index]['amount'] =
        _orderDetail[row.index].price *
        packageQuantity *
        _orderDetail[row.index].package_unit_quantity;
      const {totalAmount, totalQuantity} = calculateCartSummary(_orderDetail);
      const params = {
        details: _orderDetail,
        total_amount: totalAmount,
        total_quantity: totalQuantity,
      };

      dispatch(updateOrderAction(params, orderDetail._id)).then((res) => {
        if (res.data.success) {
          setOrderDetail((prev) => ({...prev, details: _orderDetail, total_amount: totalAmount}));
          setIsUpdateOrder(true);
        }
      });
    }
  };

  const handleChangeTotalQuantity = (oldValue, newValue, row, column) => {
    if(newValue.includes(',')){
     newValue = newValue.replaceAll(',','.'); 
    };
    const _totalProductQuantity = Number(newValue);
    if (isNumber(_totalProductQuantity) && oldValue !== _totalProductQuantity) {
      const newTotalQuantity = _totalProductQuantity;

      if (newTotalQuantity > row.product.maxOrderQuantity) {
        snackNotification.showWarning(
          `Số lượng còn lại là ${row.product.maxOrderQuantity - row.product.totalQuantity}`
        );
        return;
      }

      const _orderDetails = cloneDeep(orderDetail.details);
      _orderDetails[row.index]['product'] = {
        ..._orderDetails[row.index]['product'],
      };
      _orderDetails[row.index]['package_quantity'] =
        _totalProductQuantity / _orderDetails[row.index].package_unit_quantity;
      _orderDetails[row.index]['total_quantity'] = _totalProductQuantity;
      _orderDetails[row.index]['amount'] = _orderDetails[row.index].price * _totalProductQuantity;
      const {totalAmount, totalQuantity} = calculateCartSummary(_orderDetails);
      const params = {
        details: _orderDetails,
        total_amount: totalAmount,
        total_quantity: totalQuantity,
      };

      setOrderDetail((prev) => ({...prev, details: _orderDetails, total_amount: totalAmount}));

      dispatch(updateOrderAction(params, orderDetail._id)).then((res) => {
        if (res.data.success) {
          //setOrderDetail((prev) => ({...prev, details: _orderDetails, total_amount: totalAmount}));
          setIsUpdateOrder(true);
        }
      });
    }
  };

  useEffect(() => {
    return () => {
      if (isUpdateOrder) {
        onLoadOrder && onLoadOrder();
      }
    };
  }, [isUpdateOrder]);

  return (
    <div className='p-5 mt-10'>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Mã</div>
        <div className='col-lg-7'>
          <span>{orderDetail?.order_code}</span>
        </div>
      </div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Tên</div>
        <div className='col-lg-7'>
          <input
            type='text'
            className='form-control form-control-sm'
            defaultValue={orderDetail?.name}
            onBlur={(e) => handleEditOrder('name', orderDetail?.name, e.target.value)}
          />
        </div>
      </div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Số điện thoại</div>
        <div className='col-lg-7'>
          <input
            type='text'
            className='form-control form-control-sm'
            defaultValue={orderDetail?.phone}
            onBlur={(e) => handleEditOrder('phone', orderDetail?.phone, e.target.value)}
          />
        </div>
      </div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Địa chỉ</div>
        <div className='col-lg-7'>
          <input
            type='text'
            className='form-control form-control-sm'
            defaultValue={orderDetail?.address}
            onBlur={(e) => handleEditOrder('address', orderDetail?.address, e.target.value)}
          />
        </div>
      </div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Đợt hàng</div>
        <div className='col-lg-7'>{orderDetail?.order_batch?.name}</div>
      </div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Trạng thái giao hàng</div>
        <div className='col-lg-7'>
          <Dropdown className=''>
            <Dropdown.Toggle variant='none' size='sm'>
              {statusOrder(orderDetail?.status)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {statusOption &&
                statusOption.map((option) => {
                  return (
                    <Dropdown.Item
                      key={option.value}
                      onClick={() => {
                        handleEditOrder('status', orderDetail?.status, option.value);
                      }}
                    >
                      {option.label}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Ghi chú</div>
        <div className='col-lg-7'>
          <textarea
            className='form-control form-control-sm'
            defaultValue={orderDetail?.note}
            onBlur={(e) => handleEditOrder('note', orderDetail?.note, e.target.value)}
          />
        </div>
      </div>
      <div className='separator'></div>
      <div className='mb-2 mt-2'>
        <div className=''>
          <BootstrapTable
            wrapperClasses='table-responsive'
            classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
            bootstrap4
            hover
            keyField='package_id'
            data={!orderDetail?.details ? [] : cloneDeep(orderDetail.details)}
            columns={packageColumn}
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              afterSaveCell: (oldValue, newValue, row, column) => {
                handleChangeTotalQuantity(oldValue, newValue, row, column);
              },
            })}
          />
        </div>
      </div>
      <div className='separator'></div>
      <div className='row mb-5 mt-2'>
        <div className='col-lg-5'>Tổng sản phẩm</div>
        <div className='col-lg-7 text-end'>
          <FormattedNumber value={orderDetail?.total_amount} />
        </div>
      </div>
      <div className='row mb-2 mt-2 d-none'>
        <div className='col-lg-5'>Giảm giá</div>
        <div className='col-lg-7'>
          <input
            type='text'
            className='form-control form-control-sm text-end'
            defaultValue={orderDetail?.discount}
            onBlur={(e) => handleEditOrder('discount', orderDetail?.discount, e.target.value)}
          />
        </div>
      </div>
      <div className='row mb-5 mt-2'>
        <div className='col-lg-5 fw-bold  fs-2'>Thành tiền</div>
        <div className='col-lg-7 fw-bold text-danger text-end fs-2'>
          <FormattedNumber value={orderDetail?.total_amount} />
        </div>
      </div>
      <div className='separator'></div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Trạng thái thanh toán</div>
        <div className='col-lg-7'>
          <Dropdown className=''>
            <Dropdown.Toggle variant='none' size='sm'>
              {statusPlaymentOrder(orderDetail?.payment_status)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {paymentStatusOption &&
                paymentStatusOption.map((option) => {
                  return (
                    <Dropdown.Item
                      key={option.value}
                      onClick={() => {
                        handleEditOrder(
                          'payment_status',
                          orderDetail?.payment_status,
                          option.value
                        );
                      }}
                    >
                      {option.label}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Khách Thanh Toán</div>
        <div className='col-lg-7'>
          <input
            type='text'
            className='form-control form-control-sm  text-end'
            defaultValue={orderDetail?.payment_amount}
            onBlur={(e) =>
              handleEditOrder('payment_amount', orderDetail?.payment_amount, e.target.value)
            }
          />
        </div>
      </div>
      <div className='row mb-2 mt-2'>
        <div className='col-lg-5'>Kênh Thanh Toán</div>
        <div className='col-lg-7'>
          <Select
            classNamePrefix='select'
            placeholder='Tài khoản nhận tiền'
            value={orderDetail?.payment_channel}
            onChange={(value) => {
              handleEditOrder('payment_channel', orderDetail?.payment_channel, {
                label: value.label,
                value: value.value,
              });
            }}
            options={orderBatch?.settings?.order?.payment_accounts.map((option) => {
              return {value: option.value, label: option.name};
            })}
          />
        </div>
       </div> 
      <div className='h-50px'>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <button className='btn btn-primary float-end'>
                <i className='las la-print'></i> {'In đơn'}
              </button>
            );
          }}
          // onAfterPrint={onHide}
          content={() => invoicePrintRef.current}
        />
      </div>
      {orderDetail && (
        <div className='h-10px'>
          {orderBatch?.order_print_template === 'tinhdau' ? (
            <OrderPrint order={orderDetail || {}} ref={invoicePrintRef} />
          ) : (
            <OrderPrintRau
              order={orderDetail || {}}
              productById={productById}
              ref={invoicePrintRef}
              qrOderImage={qrOderImage}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default OrderDetail;
