import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api'

const endPoints = {
  listCustomer: '/api/customer/search',
  addCustomer: '/api/customer',
  editCustomer: (id) => `/api/customer/${id}`,
  customerDetail: (id) => `/api/customer/${id}`,
  deleteCustomer: (id) => `/api/customer/${id}`,
  listCustomerCategory: '/api/customerCategory'
};

export function getCustomerCategories(){
  return getApiV2(endPoints.listCustomerCategory)
}

export function getCustomers(params) {
  return postApiV2(endPoints.listCustomer, params)
}

export function addCustomer(params) {
  return postApiV2(endPoints.addCustomer, params);
}

export function editCustomer(params, id) {
  return putApiV2(endPoints.editCustomer(id), params);
}

export function customerDetail(id) {
  return getApiV2(endPoints.customerDetail(id));
}

export function deleteCustomer(id) {
  return deleteApiV2(endPoints.deleteCustomer(id));
}
