import { createSlice } from "@reduxjs/toolkit";

const initialOrderBatchState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  listOrderBatch: [],
  countOrderBatch: 0,
  products: [],
  countProducts: 0,
  prices: [],
  countPrices: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const orderBatchSlice = createSlice({
  name: 'orderBatch',
  initialState: initialOrderBatchState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    listOrderBatchFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.listOrderBatch = data.data ? data.data : [];
      state.countOrderBatch = data.count ? data.count : 0;
    },

    listProductFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.products = data.data ? data.data : [];
      state.countProducts = data.count ? data.count : 0;
    },

    listPriceFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.prices = data.data ? data.data : [];
      state.countPrices = data.count ? data.count : 0;
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
  },
});
