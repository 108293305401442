import * as requestFromServer from './customerApi'
import {customerSlice, callTypes} from './customerSlice'

const {actions} = customerSlice

export const fetchCustomersAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getCustomers(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.customersFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get customers";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}

export const fetchCustomerCategoriesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getCustomerCategories()
    .then((res) => {
      const data = res.data;
      dispatch(actions.customerCategoriesFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get customer categories";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}

export const addCustomersAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .addCustomer(params)
    .catch((error) => {
      error.clientMessage = "Can't add customer";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
}


export const editCustomersAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .editCustomer(params, id)
    .catch((error) => {
      error.clientMessage = "Can't edit customer";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}



export const getCustomerDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .customerDetail(id)
    .catch((error) => {
      error.clientMessage = "Can't get customer";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const deleteCustomerAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .deleteCustomer(id)
    .catch((error) => {
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}
