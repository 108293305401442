export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const secondsToMinuteString = (time) => {
  const m = String(Math.floor(time / 60)).padStart(2, "0");
  time %= 60;
  const s = String(time).padStart(2, "0");

  return `${m}:${s}`;
};

export const roundingByStartTime = (time) => {
  const minutes = time / 60;
  const h = String(Math.floor(minutes / 60)).padStart(2, "0");
  const m = String(minutes % 60).padStart(2, "0");
  return `${h}:${parseInt(m) + 15 > 59 ? 59 : parseInt(m) + 15}`;
};
