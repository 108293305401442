import {Input, LoadingDialog} from 'app/common/_partials/controls';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {shallowEqual, useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import './customerCategoryForm.scss';

function CustomerCategoryForm({saveCustomerCategory, initCustomerCategory, onHide, isEdit}) {
  const {t} = useTranslate();
  const customerCategoryAddSchema = Yup.object().shape({
    name: Yup.string().required(t('customerCategory_name_is_required')),
  });

  const {actionsLoading} = useSelector((state) => state.customerCategory, shallowEqual);


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initCustomerCategory}
        validationSchema={customerCategoryAddSchema}
        onSubmit={(values) => {
          saveCustomerCategory(values);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({handleSubmit, values, setFieldValue}) => {
          return (
            <>
              <LoadingDialog isLoading={actionsLoading} text={t('common_loading')} />
              <Modal.Body className='overlaycursor-default'>
                <Form className='form form-label-right'>
                  <div className='row'>
                      <div className='col-7'>
                          <div className='form-group row'>
                              <div className='col-lg-12'>
                                  <Field
                                      name='name'
                                      component={Input}
                                      placeholder={t('customerCategory_name')}
                                      label={t('customerCategory_name')}
                                      focus
                                  />
                              </div>
                          </div>
                          <div className='form-group row mt-4'>
                              <div className='col-lg-12'>
                                  <Field
                                      name='code'
                                      component={Input}
                                      placeholder={t('customerCategory_code')}
                                      label={t('customerCategory_code')}
                                  />
                              </div>
                          </div>
                          <div className='form-group row mt-4'>
                              <div className='col-lg-12'>
                                  <Field
                                      name='description'
                                      component={Input}
                                      placeholder={t('customerCategory_description')}
                                      label={t('customerCategory_description')}
                                  />
                              </div>
                          </div>

                      </div>

                  </div>
                </Form>
              </Modal.Body>
                <Modal.Footer>
                    <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                        {t('common_cancel')}
                    </button>
                    <button
                        type='submit'
                        className='btn btn-primary btn-elevate'
                        onClick={handleSubmit}
                    >
                  {isEdit ? t('common_save') : t('common_add_new')}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default CustomerCategoryForm;
