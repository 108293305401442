import {CardHeaderToolbar, LoadingDialog, Pagination} from 'app/common/_partials/controls';
import {useIntl} from 'react-intl';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  defaultTableOptions,
  getHandlerTableChange,
} from 'app/common/_helpers';
import {useCallback, useEffect, useState} from 'react';
import {isEqual, isFunction} from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from 'app/common/_helpers';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import EditCustomerCategory from './Modal/EditCustomerCategory';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import './customerCategoryList.scss';
import CustomerCategoryListFilter from './CustomerCategoryListFilter';
import AddCustomerCategory from './Modal/AddCustomerCategory';
import { addCustomerCategoryAction, deleteCustomerCategoryAction, fetchCustomerCategoriesAction } from '../redux/CustomerCategoryActions';
import { addCustomerCategory } from '../redux/CustomerCategoryApi';

export default function CustomerCategoryListPage() {
  const intl = useIntl();
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {customerCategories, countCustomerCategories} = useSelector((state) => state.customerCategory, shallowEqual);
  const [customerCategory, setCustomerCategory] = useState(null);
  const [customerCategoryDelete, setCustomerCategoryDelete] = useState(null);
  const [showAddCustomerCategory, setShowAddCustomerCategory] = useState(false);
  const [newCustomerCategoryValue, setNewCustomerCategoryValue] = useState('');

  const tableOptions = {
    ...defaultTableOptions,
    searchText: null,
  };

  const [queryParams, setQueryParamsBase] = useState(tableOptions);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const onAddItemInputBlur = (e) => {
    if (e.target.value !== '') {
      handleAddItemEvent(e);
    }
  };

  const onAddItemInputPasted = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const input = e.target;

    let lines = pastedText.split('\r\n');
    lines = lines.filter((x) => {
      return x !== '';
    });
    if (lines.length > 1) {
      handleAddItems(lines, () => {
        setTimeout(() => (input.value = ''), 0);
      });
    }
  };

  const onAddItemInputKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      handleAddItemEvent(e);
    }
  };

  const handleAddItems = (items) => {
    const requests = items.map((item) => {
      const params = {
        name: item,
        unit_name: 'kg'
      };
      return addCustomerCategory(params);
    });
    Promise.all(requests).then((res) => {
      setNewCustomerCategoryValue('');
      onSaveSuccess();
    });
  };

  const handleAddItemEvent = (e) => {
    const value = e.target.value;
    const params = {
      name: value,
    };
    dispatch(addCustomerCategoryAction(params)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('customerCategory_add_success'));
        onSaveSuccess();
        setNewCustomerCategoryValue('');
      } else {
        snackNotification.showError(t('customerCategory_add_error'));
      }
    });
  };

  const customerCategoryColumns = [
    {
      dataField: 'name',
      text: t('customerCategory_name'),

      footer: '',
      footerClasses: 'border-top',
      footerFormatter: () => {
        return (
          <div>
            <input
              type='text'
              value={newCustomerCategoryValue}
              onChange={(e) => setNewCustomerCategoryValue(e.target.value)}
              className='w-100 input-add-new-customerCategory rounded-0  form-control  form-control-xs item-text-input'
              placeholder={`+ ${t('customerCategory_add_title')}`}
              onBlur={(e) => onAddItemInputBlur(e)}
              onKeyDown={(e) => onAddItemInputKeyDown(e)}
              onPasteCapture={(e) => onAddItemInputPasted(e)}
            />
          </div>
        );
      },
    },
    
    {
      dataField: 'action',
      text: '',
      footer: '',
      footerClasses: 'border-top',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEdit: setCustomerCategory,
        openDelete: setCustomerCategoryDelete,
      },
      classes: 'text-right pe-0',
      headerClasses: 'text-right pe-3',
      style: {
        width: '50px',
      },
    },
  ];

  const onSaveSuccess = () => {
    loadCustomerCategorys();
    setShowAddCustomerCategory(false);
    setCustomerCategory(null);
    setCustomerCategoryDelete(null);
  };

  const handleDeleteCustomerCategory = () => {
    dispatch(deleteCustomerCategoryAction(customerCategoryDelete._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('customerCategory_delete_success'));
        loadCustomerCategorys();
        setCustomerCategoryDelete(null);
      } else {
        snackNotification.showError(t('customerCategory_delete_error'));
        setCustomerCategoryDelete(null);
      }
    });
  };

  useEffect(() => {
    loadCustomerCategorys();
  }, [dispatch, queryParams]);

  const loadCustomerCategorys = () => {
    const params = {
      ...queryParams,
      paging: {
        pageNumber: queryParams.pageNumber,
        pageSize: queryParams.pageSize,
      },
    };
    dispatch(fetchCustomerCategoriesAction(params));
  };

  return (
    <>
      <LoadingDialog isLoading={false} text='Loading ...' />
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('customerCategory_title')}</h3>
              <CardHeaderToolbar>
                <div className='d-flex'>
                  <CustomerCategoryListFilter setQueryParams={setQueryParams} queryParams={queryParams} />
                  <button
                    type='button'
                    className='btn btn-sm btn-primary ms-2 w-100'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowAddCustomerCategory(true);
                    }}
                  >
                    {intl.formatMessage({id: 'common_add_new'})}
                  </button>
                </div>
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <PaginationProvider
                pagination={paginationFactory({
                  ...tableOptions,
                  totalSize: countCustomerCategories,
                  page: queryParams.pageNumber,
                  withFirstAndLast: false,
                  hideSizePerPage: true,
                })}
              >
                {({paginationProps, paginationTableProps}) => {
                  return (
                    <Pagination isLoading={false} paginationProps={paginationProps}>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                        bootstrap4
                        remote
                        hover
                        keyField='_id'
                        onTableChange={getHandlerTableChange(setQueryParams)}
                        data={customerCategories === null ? [] : customerCategories}
                        columns={customerCategoryColumns}
                        {...paginationTableProps}
                      >
                        <PleaseWaitMessage entities={customerCategories === null ? [] : customerCategories} />
                        <NoRecordsFoundMessage entities={customerCategories === null ? [] : customerCategories} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </div>
      <AddCustomerCategory
        show={showAddCustomerCategory}
        onHide={() => setShowAddCustomerCategory(false)}
        onSaveSuccess={onSaveSuccess}
      />
      {customerCategory && (
        <EditCustomerCategory
          show={customerCategory ? true : false}
          customerCategory={customerCategory}
          onHide={() => setCustomerCategory(null)}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {customerCategoryDelete && (
        <ModalDelete
          onClick={handleDeleteCustomerCategory}
          show={customerCategoryDelete ? true : false}
          title={'Xoá nhóm khách hàng'}
          content={'Bạn muốn xoá nhóm khách hàng'}
          deleteName={customerCategoryDelete.name}
          close={() => setCustomerCategoryDelete(null)}
        />
      )}
    </>
  );
}

const ActionsColumnFormatter = (cellContent, row, rowIndex, {openEdit, openDelete}) => (
  <div className='d-flex'>
    <a
      className='btn btn-icon  btn-hover-primary btn-sm mx-2'
      key='edit'
      onClick={(event) => {
        event.stopPropagation();
        openEdit(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
      </span>
    </a>
    <a
      className='btn btn-icon  btn-hover-primary btn-sm mx-2'
      key='delete'
      onClick={(event) => {
        event.stopPropagation();
        openDelete(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
      </span>
    </a>
  </div>
);
