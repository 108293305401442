import { createSlice } from "@reduxjs/toolkit";

const initialOrderLandingPageState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  listOrderLandingPage: [],
  countOrderLandingPage: 0,
  products: [],
  countProducts: 0,
  prices: [],
  countPrices: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const orderLandingPageSlice = createSlice({
  name: 'orderLandingPage',
  initialState: initialOrderLandingPageState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    listOrderBatchFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.listOrderLandingPage = data.data ? data.data : [];
      state.countOrderLandingPage = data.count ? data.count : 0;
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
  },
});
