import * as requestFromServer from './orderApi'
import {orderSlice, callTypes} from './orderSlice'

const {actions} = orderSlice


export const getOrderLandingPageDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getOrderLandingPageDetail(id).catch((error) => {
    error.clientMessage = "Can't get order landing page";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

export const getOrderLandingPageByCodeAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getOrderLandingPageByCode(params).catch((error) => {
    error.clientMessage = "Can't get order landing page by code";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

export const getOrderBatchDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.orderBatchDetail(id).catch((error) => {
    error.clientMessage = "Can't get order batch";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

export const getProductByIds = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getProductByIds(params)
    .catch((error) => {
      error.clientMessage = "Can't get products";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const updateMultipleProductAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.updateMultipleProduct(id).catch((error) => {
    error.clientMessage = "Can't update product";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const fetchOrderAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .searchOrders(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.ordersFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get list order";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const searchOrdersAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .searchOrders(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get list order";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const createOrder = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .createOrder(params)
    .catch((error) => {
      error.clientMessage = "Can't create order";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
};

export const getCustomerByPhoneNumberAction = (phoneNumber) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer
    .getCustomerByPhoneNumber(phoneNumber)
    .catch((error) => {
      error.clientMessage = "Can't phone number";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
};

export const createCustomerAction = (phoneNumber) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.createCustomer(phoneNumber).catch((error) => {
    error.clientMessage = "Can't create customer";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const updateCustomerAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.updateCustomer(params, id).catch((error) => {
    error.clientMessage = "Can't update customer";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const getPriceDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getPriceDetail(id).catch((error) => {
    error.clientMessage = "Can't get price";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};


export const getProductsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getProducts(params).catch((error) => {
    error.clientMessage = "Can't get products";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

export const getOrderBatchSummaryProductOrderAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getOrderBatchSummaryProductOrder(id)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get order";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
};


export const getOrderAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.getOrder(id).then((res) => {
    const data = res.data;
    return data;
  }).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const updateOrderAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.updateOrder(params, id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const deleteOrderAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.deleteOrder(id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const getOrderByPhoneAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getOrderByPhone(params).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

export const getSellerAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getSeller(id).catch((error) => {
    error.clientMessage = "Can't get seller";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

export const getCountOrderInDayAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getCountOrderInDayAction(params).catch((error) => {
    error.clientMessage = "Can't get count order";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

export const genPaymentQrCodeAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.genPaymentQrCode(params).catch((error) => {
    error.clientMessage = "Can't gen payment qr code";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};