import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getShortLinkFromCodeAction } from "../redux/shortLinkActions";
import { useNavigate, useParams } from "react-router-dom";


const ShortLinkPublicPage = () => {

  const {shortLink} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getShortLinkFromCodeAction(shortLink)).then((result) => {
      navigate(result.data.fullLink);
    });
  },[shortLink])


  return (
    <div>
      
    </div>
  );
};

export default ShortLinkPublicPage;
