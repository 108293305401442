/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {ThemeModeType, useThemeMode} from '../theme-mode/ThemeModeProvider'
import clsx from 'clsx'
import {useAppDispatch, useAppSelector} from 'core/store/hooks'
import {actions} from 'core/store/auth'
import {useSelector} from 'react-redux'
import {useSession} from 'core/store/core/hooks'

const HeaderUserMenu: FC = () => {
  const dispatch = useAppDispatch()
  const {user} = useSession()
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  const doLogout = () => {
    dispatch(actions.logout())
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-danger text-danger fw-bold'>
              {user?.name?.[0]}
            </span>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'></span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to='/teams' className='menu-link px-5'>
          Người dùng
        </Link>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to='/profile' className='menu-link px-5'>
          Profile
        </Link>
      </div>
      <div className='separator my-2'></div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>Theme</span>
          <span className='menu-arrow'></span>
        </a>
        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a
              href='#'
              className={clsx('menu-link px-3 py-2', {active: menuMode === 'light'})}
              onClick={() => switchMode('light')}
            >
              <span className='menu-icon' data-kt-element='icon'>
                <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-3' />
              </span>
              <span className='menu-title'>Light</span>
            </a>
          </div>

          <div className='menu-item px-3'>
            <a
              href='#'
              className={clsx('menu-link px-3 py-2', {active: menuMode === 'dark'})}
              onClick={() => switchMode('dark')}
            >
              <span className='menu-icon' data-kt-element='icon'>
                <KTSVG path='/media/icons/duotune/general/gen061.svg' className='svg-icon-3' />
              </span>
              <span className='menu-title'>Dark</span>
            </a>
          </div>

          <div className='menu-item px-3'>
            <a
              href='#'
              className={clsx('menu-link px-3 py-2', {active: menuMode === 'system'})}
              onClick={() => switchMode('system')}
            >
              <span className='menu-icon' data-kt-element='icon'>
                <KTSVG path='/media/icons/duotune/general/gen062.svg' className='svg-icon-3' />
              </span>
              <span className='menu-title'>System</span>
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <a onClick={doLogout} className='menu-link px-5'>
          Đăng xuất
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
