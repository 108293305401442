import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';
import PriceListListPage from './PriceListListPage';
const PriceListPage = () => {
  return (
    <div>
      <PageAside>
        <Menu />
      </PageAside>
      <PriceListListPage/>
    </div>
  );
};

export default PriceListPage;
