import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import PriceListForm from './PriceListForm';
import {useDispatch} from 'react-redux';
import {editPriceListsAction} from '../../redux/priceListActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { useTranslate } from 'core/i18n/i18nProvider';

function EditPriceList({show, onHide, onSaveSuccess, priceList}) {
  const dispatch = useDispatch();
  const {t} = useTranslate()
  const snackNotification = useSnackNotification();
  const initPriceList = {
    name: '',
    description: '',
    maxOrder: '',
    image: '',
    packages: [],
    products: [],
  };

  const [priceListEdit, setPriceListEdit] = useState(initPriceList);

  useEffect(() => {
    const priceListEdit = {
      name: priceList.name,
      description: priceList.description,
      maxOrder: priceList.max_order,
      image: priceList.image,
      packages: priceList.packages,
    };
    setPriceListEdit(priceListEdit);
  }, [priceList]);

  const savePriceList = (priceListValue) => {
    const params = {
      ...priceListValue,
      max_order: priceListValue.maxOrder,
    };
    dispatch(editPriceListsAction(params, priceList._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('priceList_edit_success'));
        onSaveSuccess();
      } else {
        snackNotification.showError(t('priceList_edit_error'));
        onHide();
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('priceList_edit_title')}</Modal.Title>
        </Modal.Header>
        <PriceListForm
          initPriceList={priceListEdit}
          onHide={onHide}
          savePriceList={savePriceList}
          isEdit={true}
        />
      </Modal>
    </div>
  );
}

export default EditPriceList;
