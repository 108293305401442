export const listBankCode = [
  {
    value: '970452',
    label: 'KienLongBank',
  },
  {
    value: '970403',
    label: 'Sacombank',
  },
  {
    value: '970455',
    label: 'IBKHN',
  },
  {
    value: '970418',
    label: 'BIDV',
  },
  {
    value: '970421',
    label: 'VRB',
  },
  {
    value: '970466',
    label: 'KebHanaHCM',
  },
  {
    value: '970443',
    label: 'SHB',
  },
  {
    value: '970439',
    label: 'PublicBank',
  },
  {
    value: '796500',
    label: 'DBSBank',
  },
  {
    value: '970405',
    label: 'Agribank',
  },
  {
    value: '970460',
    label: 'VietCredit',
  },
  {
    value: '970422',
    label: 'MBBank',
  },
  {
    value: '970406',
    label: 'DongABank',
  },
  {
    value: '970433',
    label: 'VietBank',
  },
  {
    value: '970431',
    label: 'Eximbank',
  },
  {
    value: '971011',
    label: 'VNPTMoney',
  },
  {
    value: '970400',
    label: 'SaigonBank',
  },
  {
    value: '546034',
    label: 'CAKE',
    isDisburse: true,
  },
  {
    value: '970430',
    label: 'PGBank',
  },
  {
    value: '970419',
    label: 'NCB',
  },
  {
    value: '458761',
    label: 'HSBC',
    isDisburse: true,
  },
  {
    value: '970410',
    label: 'StandardChartered',
    isDisburse: true,
  },
  {
    value: '970407',
    label: 'Techcombank',
  },
  {
    value: '970434',
    label: 'IndovinaBank',
  },
  {
    value: '970436',
    label: 'VietcomBank',
  },
  {
    value: '970467',
    label: 'KebHanaHN',
  },
  {
    value: '970424',
    label: 'ShinhanBank',
  },
  {
    value: '970462',
    label: 'KookminHN',
  },
  {
    value: '970449',
    label: 'LienVietPostBank',
  },
  {
    value: '970412',
    label: 'PVcomBank',
  },
  {
    value: '970425',
    label: 'ABBANK',
  },
  {
    value: '970444',
    label: 'CBBank',
    isDisburse: true,
  },
  {
    value: '970463',
    label: 'KookminHCM',
  },
  {
    value: '970437, 970420',
    label: 'HDBank',
  },
  {
    value: '970423',
    label: 'TPBank',
  },
  {
    value: '970432',
    label: 'VPBank',
  },
  {
    value: '546035',
    label: 'Ubank',
    isDisburse: true,
  },
  {
    value: '970457',
    label: 'Woori',
  },
  {
    value: '970414',
    label: 'Oceanbank',
  },
  {
    value: '971005',
    label: 'ViettelMoney',
  },
  {
    value: '970440',
    label: 'SeABank',
  },
  {
    value: '970456',
    label: 'IBKHCM',
  },
  {
    value: '970446',
    label: 'COOPBANK',
    isDisburse: true,
  },
  {
    value: '970426',
    label: 'MSB',
  },
  {
    value: '970416',
    label: 'ACB',
  },
  {
    value: '970409',
    label: 'BacABank',
  },
  {
    value: '422589',
    label: 'CIMB',
    isDisburse: true,
  },
  {
    value: '970454',
    label: 'VietCapitalBank',
  },
  {
    value: '668888',
    label: 'KBank',
    isDisburse: true,
  },
  {
    value: '970415',
    label: 'VietinBank',
  },
  {
    value: '970458',
    label: 'UnitedOverseas',
  },
  {
    value: '970442',
    label: 'HongLeong',
    isDisburse: true,
  },
  {
    value: '970428',
    label: 'NamABank',
  },
  {
    value: '970441',
    label: 'VIB',
  },
  {
    value: '970438',
    label: 'BaoVietBank',
  },
  {
    value: '970448',
    label: 'OCB',
  },
  {
    value: '963388',
    label: 'Timo',
  },
  {
    value: '801011',
    label: 'Nonghyup',
    isDisburse: true,
  },
  {
    value: '970468',
    label: 'MiraeAsset',
  },
  {
    value: '970429',
    label: 'SCB',
  },
  {
    value: '970427',
    label: 'VietABank',
  },
  {
    value: '970408',
    label: 'GPBank',
  },
];
