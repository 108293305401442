import React from 'react';
import {Modal} from 'react-bootstrap';
import PriceListForm from './PriceListForm';
import {useDispatch} from 'react-redux';
import {addPriceListsAction} from '../../redux/priceListActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { useTranslate } from 'core/i18n/i18nProvider';

function AddPriceList({show, onHide, onSaveSuccess}) {
  const dispatch = useDispatch();
  const {t} = useTranslate()
  const snackNotification = useSnackNotification();

  const initPriceList = {
    name: '',
    description: '',
    maxOrder: '',
    image: '',
    prices: [],
  };

  const savePriceList = (priceList) => {
    const params = {
      ...priceList
    };
    dispatch(addPriceListsAction(params)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('priceList_add_success'));
        onSaveSuccess();
      } else {
        snackNotification.showError(t('priceList_add_error'));
        onHide();
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('priceList_add_title')}</Modal.Title>
        </Modal.Header>
        <PriceListForm initPriceList={initPriceList} onHide={onHide} savePriceList={savePriceList} />
      </Modal>
    </div>
  );
}

export default AddPriceList;
