import {useTranslate} from 'core/i18n/i18nProvider';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  createOrder,
  getOrderBatchDetailAction,
  getOrderBatchSummaryProductOrderAction,
  getPriceDetailAction,
  getProductsAction,
} from '../redux/orderActions';
import {toAbsoluteUrl} from 'core/helpers';
import './orderPage.scss';
import {cloneDeep, groupBy} from 'lodash';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import ModalCustomerOrder from './modal/ModalCustomerOrder';
import {FormattedNumber} from 'react-intl';
import clsx from 'clsx';
import copy from 'clipboard-copy';
import {Tab, Nav, Modal} from 'react-bootstrap';

function OrderPagePublic(_props) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {orderBatchId, price} = useParams();
  const [orderBatchDetail, setOrderBatchDetail] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [productById, setProductById] = useState({});
  const [validProductsForOrder, setValidProductsForOrder] = useState([]);
  const [productByCategory, setProductByCategory] = useState({});
  const [cart, setCart] = useState({});
  const [showCartOrder, setShowCartOrder] = useState(false);
  const [showCustomerOrder, setShowCustomerOrder] = useState(false);
  const [productsAvailableQuantityById, setProductsAvailableQuantityById] = useState({});
  const [payCartSuccess, setPayCartSuccess] = useState(false);
  const [productInventoryById, setProductInventoryById] = useState([]);
  const CART_STORAGE = 'cart';
  const timeExpire = 24 * 60 * 60 * 7;

  const [cartProducts, setCardProducts] = useState([]);
  const [cartProductsById, setCartProductsById] = useState({});
  const [payments, setPayments] = useState('transfer');
  const [textNote, setTextNote] = useState('');
  const [selectedTab, setSelectedTab] = useState('product');

  const [cartSummary, setCartSummary] = useState({
    totalQuantity: 0,
    totalProduct: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    const prevTitle = document.title;
    if (orderBatchDetail) {
      document.title = orderBatchDetail.pageTitle;
    }

    return () => {
      document.title = prevTitle;
    };
  }, [orderBatchDetail]);

  useEffect(() => {
    const getOrderBatch = async () => {
      try {
        const res = await dispatch(getOrderBatchDetailAction(orderBatchId));
        if (!res?.data?.data) {
          return;
        }
        const orderBatch = res.data.data;
        setOrderBatchDetail(orderBatch);
        const productInventoryById = {};
        orderBatch.products?.forEach((product) => {
          productInventoryById[product._id] = product;
        });
        setProductInventoryById(productInventoryById);
        const resProduct = await dispatch(getProductsAction());
        if (!resProduct.data.data) {
          return;
        }
        const products = resProduct.data.data;
        setAllProducts(products);
      } catch (error) {
        console.log(error);
      }
    };
    if (orderBatchId) {
      getOrderBatch();
    }

    dispatch(getOrderBatchSummaryProductOrderAction(orderBatchId)).then((result) => {
      const productsAvailableQuantityById = {};
      if (result.data) {
        result.data.forEach((product) => {
          productsAvailableQuantityById[product._id] = product;
        });
        setProductsAvailableQuantityById(productsAvailableQuantityById);
      }
    });
  }, [orderBatchId, dispatch]);

  useEffect(() => {
    const getPrice = async () => {
      try {
        const res = await dispatch(getPriceDetailAction(price));
        if (res?.data?.data) {
          const prices = res.data.data;
          const productById = {};
          prices.prices?.forEach((productPrice) => {
            let priceFullAmount = Number(productPrice?.price) || 0;
            let priceDiscountAmount =
              (Number(productPrice?.price) * Number(productPrice?.discount_price_percent)) / 100 ||
              Number(productPrice?.discount_price) ||
              0;
            let priceAmount = priceFullAmount - priceDiscountAmount;
            if (!price) {
              priceAmount = 0;
            }
            productById[productPrice._id] = {
              ...productPrice,
              price: priceAmount,
              priceDiscount: priceDiscountAmount,
              productPrice: priceFullAmount,
            };
          });
          setProductById(productById);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (price) {
      getPrice();
    }
  }, [price, dispatch]);

  useEffect(() => {
    let _productForOrder = allProducts.flatMap((product) => {
      const productPrice = productById[product._id];
      const productQuantity = productInventoryById[product._id]?.quantity;
      if (productPrice?.price && productPrice?.price !== '' && productQuantity) {
        const totalBatchQuantity = productQuantity;
        const totalOrderQuantity = productsAvailableQuantityById[product._id]?.total_quantity
          ? productsAvailableQuantityById[product._id]?.total_quantity
          : 0;
        const availableQuantity = Math.round((totalBatchQuantity - totalOrderQuantity) * 100) / 100;
        const maxOrderQuantity = product.max_order !== '' ? product.max_order : 1000000;

        let _product = {
          ...product,
          price: productPrice,
          quantity: productQuantity,
          maxOrderQuantity: maxOrderQuantity,
          batchQuantity: totalBatchQuantity,
          orderQuantity: totalOrderQuantity,
          availableQuantity: availableQuantity,
          productCategoryId: product?.productCategory?._id,
        };

        if (!product.packages || product.packages?.length === 0) {
          _product.packages = [
            {
              _id: product._id,
              name: '1 ' + (product.unit_name || 'kg'),
              quantity: 1,
            },
          ];
        }
        return _product;
      } else {
        return [];
      }
    });

    _productForOrder = _productForOrder.sort((a, b) => {
      if (a.availableQuantity > 0 && b.availableQuantity > 0) {
        return (a.name > b.name)? 1: -1 ;
      } else if (a.availableQuantity > 0) {
        return 1 ;
      } else {
        return -1;
      }
    })
    setValidProductsForOrder(_productForOrder);
    const productByCategory = groupBy(
      cloneDeep(_productForOrder),
      ({productCategoryId}) => productCategoryId
    );
    setProductByCategory(productByCategory);
    setSelectedTab(Object.keys(productByCategory || {})?.[0]);
  }, [allProducts, productById, productInventoryById, productsAvailableQuantityById]);

  const handelAddToCart = (packageItem, product) => {
    let _cartProductsById = cloneDeep(cartProductsById);
    if (_cartProductsById[product._id]) {
      if (_cartProductsById[product._id].packageItems[packageItem._id]) {
        _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity =
          1 + _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity;
        _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity =
          _cartProductsById[product._id].packageItems[packageItem._id].quantity *
          _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity;
      } else {
        _cartProductsById[product._id].packageItems[packageItem._id] = {
          ...packageItem,
          packageQuantity: 1,
          totalQuantity: packageItem.quantity,
        };
      }
      _cartProductsById[product._id].packageItems[packageItem._id].amount =
        _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity *
        product.price.price;
      _cartProductsById[product._id].totalQuantity =
        _cartProductsById[product._id].totalQuantity + packageItem.quantity;
      _cartProductsById[product._id].totalAmount =
        product.price.price * _cartProductsById[product._id].totalQuantity;
    } else {
      _cartProductsById[product._id] = {
        ...product,
        totalAmount: 0,
        totalQuantity: 0,
        packageItems: {},
      };
      _cartProductsById[product._id].packageItems[packageItem._id] = {
        ...packageItem,
        packageQuantity: 1,
        totalQuantity: packageItem.quantity,
      };
      _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity =
        _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity *
        _cartProductsById[product._id].packageItems[packageItem._id].quantity;
      _cartProductsById[product._id].packageItems[packageItem._id].amount =
        _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity *
        product.price.price;

      _cartProductsById[product._id].totalQuantity =
        _cartProductsById[product._id].totalQuantity + packageItem.quantity;
      _cartProductsById[product._id].totalAmount =
        product.price.price * _cartProductsById[product._id].totalQuantity;
    }

    if (
      _cartProductsById[product._id].totalQuantity > product.availableQuantity ||
      _cartProductsById[product._id].totalQuantity > product.maxOrderQuantity
    ) {
      //Over limit, stop add
      return;
    }
    setCartProductsById(_cartProductsById);
    setCartSummary(calculateCartSummary(_cartProductsById));

    //Old logic
    let cartClone = cloneDeep(cart);
    if (!cartClone?.[product?._id]) {
      cartClone[product._id] = {[packageItem._id]: 1};
    } else {
      const numberProduct = cartClone[product._id][packageItem._id]
        ? cartClone[product._id][packageItem._id] + 1
        : 1;
      cartClone[product._id] = {...cartClone[product._id], [packageItem._id]: numberProduct};
    }
    setCart(cartClone);
    //setStorage(CART_STORAGE, JSON.stringify(cartClone), timeExpire);

    let _cartProducts = cloneDeep(cartProducts);
    let existingIndex = _cartProducts.findIndex((item) => item._id === product._id);
    if (existingIndex === -1) {
      _cartProducts.push({...product, quantity: 1});
    } else {
      _cartProducts[existingIndex].quantity += 1;
    }

    setCardProducts(_cartProducts);
  };

  const handelDeleteToCart = (packageItem, product) => {
    const cartClone = cloneDeep(cart);
    if (cartClone?.[product?._id]) {
      const numberProduct = cartClone[product._id][packageItem._id]
        ? cartClone[product._id][packageItem._id] - 1
        : 0;
      if (numberProduct > 0) {
        cartClone[product._id] = {...cartClone[product._id], [packageItem._id]: numberProduct};
      } else {
        cartClone[product._id][packageItem._id] = null;
        const isPackage = Object.values(cartClone[product._id]).some((packageItem) => {
          return packageItem !== null;
        });
        if (!isPackage) {
          cartClone[product._id] = null;
        }
      }
    }
    setCart(cartClone);
    //setStorage(CART_STORAGE, JSON.stringify(cartClone), timeExpire);

    let _cartProducts = cloneDeep(cartProducts);
    let existingIndex = _cartProducts.findIndex((item) => item._id === product._id);
    if (existingIndex === -1) {
      //_cartProducts.push({...product, quantity: 1});
    } else {
      _cartProducts[existingIndex].quantity = _cartProducts[existingIndex].quantity - 1;
    }

    _cartProducts = _cartProducts.flatMap((product) => {
      if (product.quantity > 0) {
        return product;
      }
      return [];
    });
    setCardProducts(_cartProducts);

    let _cartProductsById = cloneDeep(cartProductsById);
    if (_cartProductsById[product._id]?.packageItems?.[packageItem._id]) {
      _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity -= 1;
      _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity =
        _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity *
        _cartProductsById[product._id].packageItems[packageItem._id].quantity;
      _cartProductsById[product._id].totalQuantity =
        _cartProductsById[product._id].totalQuantity - packageItem.quantity;
      _cartProductsById[product._id].totalAmount =
        product.price.price * _cartProductsById[product._id].totalQuantity;

      _cartProductsById[product._id].packageItems[packageItem._id].amount =
        _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity *
        product.price.price;
    }

    for (const [key, value] of Object.entries(_cartProductsById)) {
      if (value.totalQuantity === 0) {
        delete _cartProductsById[key];
      }
    }

    setCartProductsById(_cartProductsById);
    setCartSummary(calculateCartSummary(_cartProductsById));
  };

  const validateAndEnterCustomer = () => {
    dispatch(getOrderBatchSummaryProductOrderAction(orderBatchId)).then((result) => {
      const productsAvailableQuantityById = {};
      if (result.data) {
        result.data.forEach((product) => {
          productsAvailableQuantityById[product._id] = product;
        });
        setProductsAvailableQuantityById(productsAvailableQuantityById);

        let orderValid = true;
        let invalidProductMessage = '';
        for (const [productId, cartProduct] of Object.entries(cartProductsById)) {
          const productQuantity = productInventoryById[productId]?.quantity;
          const totalBatchQuantity = productQuantity;
          const totalOrderQuantity = productsAvailableQuantityById[productId]?.total_quantity
            ? productsAvailableQuantityById[productId]?.total_quantity
            : 0;
          const availableQuantity =
            Math.round((totalBatchQuantity - totalOrderQuantity) * 100) / 100;

          if (cartProduct.totalQuantity > availableQuantity) {
            invalidProductMessage = cartProduct.name + ' chỉ còn ' + availableQuantity;
            orderValid = false;
            break;
          }
        }

        if (orderValid) {
          setShowCustomerOrder(true);
        } else {
          setPayCartSuccess(false);
          setShowCartOrder(true);
          setShowCustomerOrder(false);
          snackNotification.showError(
            'Đơn hàng đặt vượt quá số lượng có sẵn: ' + invalidProductMessage
          );
        }
      }
    });
  };

  const handlePayCart = (user) => {
    let orderDetails = [];
    for (const [productId, cartProduct] of Object.entries(cartProductsById)) {
      for (const [packageId, pack] of Object.entries(cartProduct.packageItems)) {
        orderDetails.push({
          product_id: productId,
          product: cartProduct,
          package_id: packageId,
          package_quantity: pack.packageQuantity,
          package_name: pack.name,
          total_quantity: pack.totalQuantity,
          package_unit_quantity: pack.quantity,
          price: cartProduct.price.price,
          priceDiscount: cartProduct.price.priceDiscount,
          productPrice: cartProduct.price.productPrice,
          amount: pack.amount,
        });
      }
    }

    const params = {
      name: user?.name || '',
      phone: user?.phone || '',
      address: user?.address || '',
      phone_delivery: user?.phone_delivery || '',
      order_batch_id: orderBatchId,
      order_batch: {id: orderBatchDetail._id, name: orderBatchDetail.name},
      total_quantity: cartSummary.totalQuantity,
      total_amount: cartSummary.totalAmount,
      payments: payments,
      status: 'placed',
      note: textNote,
      payment_status: 'unpaid',
      price_list_id: price,
      details: orderDetails,
      linkOrder: `${window.location.origin}${toAbsoluteUrl(`/p/orders/${orderBatchId}/${price}`)}`,
    };

    dispatch(createOrder(params)).then((res) => {
      if (res.data?.success) {
        // snackNotification.showSuccess(t('order_success'));
        setPayCartSuccess(true);
        setShowCartOrder(false);
        //removeStorage(CART_STORAGE);
        setCart({});
      } else {
        snackNotification.showError(t('order_error'));
      }
    });
  };

  useEffect(() => {
    //const cart = getStorage(CART_STORAGE);
    //setCart(JSON.parse(cart) || {});
  }, []);

  const handlePaySuccess = () => {
    setPayCartSuccess(false);
    setShowCartOrder(false);
    setCardProducts([]);
    setCartProductsById({});
    setCart({});
    setCartSummary({
      totalQuantity: 0,
      totalProduct: 0,
      totalAmount: 0,
    });
    window.location.reload();
  };

  const totalProductCard =
    Object.values(cart || {}).filter((product) => product !== null).length || 0;

  const totalProductQuantity = (product) => {
    const cartPackage = cart?.[product?._id];

    if (product.packages?.length > 0 && product.packages) {
      return product.packages?.reduce((totalQuantity, item) => {
        if (cartPackage?.[item._id]) {
          return totalQuantity + item.quantity * cartPackage?.[item._id];
        }
        return totalQuantity;
      }, 0);
    }
    return 0;
  };

  const totalProductAmount = (product) => {
    let productItem = product;
    let priceAmount = Number(productById?.[product?._id]?.price);

    const cartPackage = cart?.[productItem?._id];
    return productItem?.packages?.reduce((accumulator, item) => {
      let pricePackage = 0;
      if (price) {
        pricePackage = item.quantity * priceAmount;
      } else {
        pricePackage = item.price;
      }
      if (cartPackage?.[item._id]) {
        return accumulator + pricePackage * cartPackage?.[item._id];
      }
      return accumulator;
    }, 0);
  };

  const calculateCartSummary = (cartProductsById) => {
    let totalQuantity = 0;
    let totalProduct = 0;
    let totalAmount = 0;
    for (const [key, value] of Object.entries(cartProductsById)) {
      totalAmount += value.totalAmount;
      totalQuantity += value.totalQuantity;
      totalProduct += 1;
    }
    return {
      totalQuantity,
      totalProduct,
      totalAmount,
    };
  };

  const handleCopySTK = () => {
    snackNotification.showSuccess('Sao chép thành công');
    copy(`${orderBatchDetail?.settings?.bankAccount}` || `1039901029`);
  };

  if (payCartSuccess && payments === 'transfer') {
    return (
      <div className='d-flex px-4 bg-white flex-column align-items-center pt-10 h-100 page-qr'>
        <div className='mt-3 fs-2 text-info fw-bold'>Chúc mừng bạn đã đặt hàng thành công!</div>
        <div className='mt-3 fs-5 text-center'>
          Vui lòng chuyển khoản vào tài khoản như dưới đây
        </div>
        <div className='mt-3 fs-5 text-center text-warning fw-bolder'>
          Nội dung chuyển khoản: Tên - SĐT
        </div>
        <div className='mt-3 fs-5  text-danger fw-bold'>
          Số tiền: <FormattedNumber value={cartSummary.totalAmount} />
        </div>
        <div className='mt-3 fs-5 text-center'>
          Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất.
        </div>

        <div className='mt-15 p-5 rounded shadow'>
          <img
            src={
              orderBatchDetail?.settings?.qr_code_url || toAbsoluteUrl('/media/qr/1039901029.jpg')
            }
            className='w-250px'
            alt=''
          />
        </div>
        {!orderBatchDetail?.settings?.bankAccount && (
          <>
            <div className='mt-3 fs-5 '>Vietcombank (VCB)</div>
            <div className='mt-3 fs-5 '>PHAM THI NGOC ANH</div>
          </>
        )}
        <div className='mt-3 fs-5 d-flex align-items-center'>
          <span>STK: </span>
          <span className='fw-bolder'>
            {orderBatchDetail?.settings?.bankAccount || 1039901029}
          </span>{' '}
          <span
            onClick={handleCopySTK}
            className='border fw-normal text-gray-600 fs-7 ms-2 rounded-1'
          >
            Copy <i className='text-primary lar la-copy ms-1'></i>
          </span>
        </div>
        <div
          className='w-50px h-50px shadow rounded-circle bg-primary d-flex align-items-center justify-content-center mt-auto mb-20 cursor-pointer'
          onClick={() => handlePaySuccess()}
        >
          <i className='las la-times text-dark fs-1 text-white'></i>
        </div>
        <div className='h-40px'></div>
      </div>
    );
  }

  if (payCartSuccess) {
    return (
      <div className='d-flex px-4 bg-white flex-column align-items-center pt-10 h-100 page-qr'>
        <div className='mt-3 fs-2 text-info fw-bold'>Chúc mừng bạn đã đặt hàng thành công!</div>
        <div className='mt-3 fs-5 text-center'>
          Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất.
        </div>
        <div
          className='w-50px h-50px shadow rounded-circle bg-primary d-flex align-items-center justify-content-center mt-auto mb-20 cursor-pointer'
          onClick={() => handlePaySuccess()}
        >
          <i className='las la-times text-dark fs-1 text-white'></i>
        </div>
        <div className='h-40px'></div>
      </div>
    );
  }

  if (showCartOrder) {
    return (
      <>
        <div className='px-4 pt-4 bg-white d-flex justify-content-center'>
          <div style={{maxWidth: '600px'}}>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='mt-4 fs-2 fw-bold'>{t('order_store')}</div>
              <i
                className='las la-times text-dark fs-1'
                onClick={() => setShowCartOrder(false)}
              ></i>
            </div>
            <div className='px-2 mt-2 pb-15'>
              {totalProductCard ? (
                validProductsForOrder.map((product) => {
                  const cartProduct = cartProductsById?.[product?._id];
                  if (cartProduct) {
                    return (
                      <div
                        key={product._id}
                        className='row shadow border-light bg-white border-bottom rounded p-1 mb-3'
                      >
                        <div className='col-2 align-items-center'>
                          <img
                            src={product.image_url || toAbsoluteUrl('/media/products/23.png')}
                            className={`w-100 rounded ${product.image_url ? '' : 'opacity-25'} `}
                            alt=''
                          />
                        </div>
                        <div className='col-10'>
                          <div className='mb-1 fw-bold'>{product.name}</div>
                          <div>
                            <span className=''>
                              Có sẵn: {product.availableQuantity} {product.unit_name || 'kg'}
                            </span>
                          </div>
                          <div className='product-package mt-3'>
                            {product?.packages?.map((packageItem) => {
                              const cartPackage = cartProduct?.[packageItem?._id];
                              if (!cartPackage) {
                                return <React.Fragment key={packageItem._id}></React.Fragment>;
                              }
                              return (
                                <div className='d-flex mb-2' key={packageItem._id}>
                                  <div className='d-flex align-items-center mt-auto mb-2'>
                                    <span>{packageItem.name} </span>
                                    <span className=' mx-2'>x {cartPackage}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className='product-package w-100 mt-3'>
                            {product?.packages?.map((packageItem) => {
                              const cartPackage = cartProduct?.packageItems?.[packageItem?._id];
                              return (
                                <div
                                  className='d-flex w-100 justify-content-end align-items-center mt-auto mb-2'
                                  key={packageItem._id}
                                >
                                  <span className='w-100px'>{packageItem.name}</span>
                                  <div className='d-flex'>
                                    <i
                                      className={`las la-minus border border-dark text-dark rounded p-1 h-25px w-25px d-flex align-items-center justify-content-center ${clsx(
                                        {'opacity-0': !cartPackage}
                                      )}`}
                                      onClick={() => {
                                        if (cartPackage) {
                                          handelDeleteToCart(packageItem, product);
                                        }
                                      }}
                                    ></i>
                                    <span
                                      className={`fw-bold  mx-2 h-25px w-25px d-flex align-items-center justify-content-center`}
                                    >
                                      {cartPackage?.packageQuantity || ''}
                                    </span>
                                    <i
                                      className='las la-plus bg-primary text-white rounded  p-1 h-25px w-25px d-flex align-items-center justify-content-center'
                                      onClick={() => handelAddToCart(packageItem, product)}
                                    ></i>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className='d-flex justify-content-between'>
                            {totalProductQuantity(product) > 0 && (
                              <div className='me-2 '>
                                <span>
                                  Tổng đặt: {totalProductQuantity(product)}{' '}
                                  {product.unit_name || 'kg'}
                                </span>
                              </div>
                            )}
                            {totalProductAmount(product) > 0 && (
                              <div className='me-2 text-danger'>
                                <span>
                                  <FormattedNumber value={totalProductAmount(product)} /> đ
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return <React.Fragment key={product._id}></React.Fragment>;
                })
              ) : (
                <div>Không có sản phẩm này trong giỏ hàng</div>
              )}
            </div>
            <div className='mt-4'>
              <div className='fw-bold'>Hình thức thanh toán</div>
              <div className='mt-4'>
                <label className='radio radio-lg radio-single d-flex align-items-center'>
                  <input
                    className='w-15px h-15px'
                    name='payments'
                    type='radio'
                    onChange={(val) => {
                      setPayments('transfer');
                    }}
                    checked={payments === 'transfer'}
                  />
                  <span />
                  <span>&nbsp; Chuyển khoản</span>
                </label>
              </div>
              <div className='mt-6'>
                <label className='radio radio-lg radio-single d-flex align-items-center'>
                  <input
                    className='w-15px h-15px'
                    name='payments'
                    type='radio'
                    onChange={(val) => {
                      setPayments('cod');
                    }}
                    checked={payments === 'cod'}
                  />
                  <span />
                  <span>&nbsp; COD</span>
                </label>
              </div>
            </div>
            <div className='mt-4'>
              <div className='fw-bold'>Ghi chú</div>

              <div className='mt-3'>
                <textarea
                  className='w-100 p-2'
                  name=''
                  id=''
                  cols='30'
                  rows='5'
                  value={textNote}
                  onChange={(e) => setTextNote(e.target.value)}
                ></textarea>
              </div>
              <div className='d-flex align-items-center mt-2 mb-2'>
                <div
                  className='border rounded-pill py-1 px-3 me-2'
                  onClick={() => setTextNote('Giao ngoài giờ hành chính')}
                >
                  Giao ngoài giờ hành chính
                </div>
                <div
                  className='border rounded-pill py-1 px-3 me-2'
                  onClick={() => setTextNote('Giao trong giờ hành chính')}
                >
                  Giao trong giờ hành chính
                </div>
              </div>
            </div>
          </div>
          <div className='cart-order shadow'>
            <div className='h-100 d-flex align-items-center justify-content-between bg-white'>
              <button type='button' className='btn btn-white position-relative p-1 ms-3'>
                <i className='las text-primary la-cart-plus cursor-pointer cart-icon'></i>
                <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                  {cartSummary.totalProduct}
                </span>
              </button>
              <div className='d-flex h-100 d-flex align-items-center'>
                <div className='fw-bold text-danger fs-5 px-3'>
                  <FormattedNumber value={cartSummary.totalAmount} />đ
                </div>
                <div
                  className='h-100 w-100px bg-primary fw-bold fs-4 text-white d-flex align-items-center justify-content-center cursor-pointer'
                  onClick={() => validateAndEnterCustomer()}
                >
                  <span>Đặt hàng</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalCustomerOrder
          className='mw-100 modal-dialog-centered'
          show={showCustomerOrder}
          onHide={() => setShowCustomerOrder(false)}
          onPayCart={handlePayCart}
        />
      </>
    );
  }

  return (
    <>
      <div className='px-4 pt-4 bg-white h-100 d-flex justify-content-center'>
        <div style={{maxWidth: '600px'}}>
          <div className=' fw-bold text pt-5 fs-2'>{orderBatchDetail.name}</div>
          <div
            className='fs-5 pt-2'
            dangerouslySetInnerHTML={{__html: orderBatchDetail.description}}
          ></div>
          <Tab.Container defaultActiveKey={selectedTab}>
            <div className='d-flex align-items-center justify-content-between mb-5 border-bottom'>
              <Nav variant='underline' onSelect={setSelectedTab} as='ul' role='tablist'>
                {Object.entries(productByCategory).map(([key, categories]) => {
                  return (
                    <Nav.Item as='li' key={key}>
                      <Nav.Link
                        eventKey={key}
                        className={`cursor-pointer nav-link fw-bold fs-3 text-warning ${
                          selectedTab === key ? 'border-bottom border-primary border-2' : ''
                        }`}
                      >
                        <span className={clsx({'text-primary': selectedTab === key})}>
                          {categories[0]?.productCategory?.name || 'Khác'}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </div>
            {/*end::Header*/}

            {/*begin::Content*/}
            <div className=''>
              <div className='tab-content'>
                {Object.entries(productByCategory).map(([key, categories]) => {
                  return (
                    <div
                      key={key}
                      id='kt_quick_panel_products'
                      role='tabpanel'
                      className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                        selectedTab === key ? 'active show' : ''
                      }`}
                    >
                      <div className='mb-15'>
                        <div className='px-2 mt-2 pb-20'>
                          {categories?.map((product) => {
                            const cartProduct = cartProductsById?.[product?._id];
                            let productPrice = productById?.[product?._id];
                            let priceFullAmount = Number(productPrice?.price) || 0;
                            let priceDiscountAmount =
                              (Number(productPrice?.price) *
                                Number(productPrice?.discount_price_percent)) /
                                100 || 0;
                            let priceAmount = priceFullAmount - priceDiscountAmount;
                            if (!price) {
                              priceAmount = product.price ? product.price : 0;
                            }

                            return (
                              <div
                                key={product._id}
                                className='row  border-light border-bottom rounded p-1 mb-3'
                              >
                                <div className='col-3 align-items-center '>
                                  <img
                                    src={
                                      product.image_url || toAbsoluteUrl('/media/products/23.png')
                                    }
                                    className={`w-100 rounded ${
                                      product.image_url ? '' : 'opacity-50'
                                    } `}
                                    alt=''
                                  />
                                </div>
                                <div className='col-9'>
                                  <div className='mb-1 fw-bold fs-4'>{product.name}</div>
                                  <div className='mb-1 d-flex justify-content-between'>
                                    <div>
                                      {productPrice?.priceDiscount > 0 && (
                                        <span className='text-decoration-line-through me-1'>
                                          <FormattedNumber value={productPrice?.productPrice} />
                                        </span>
                                      )}
                                      <span className='text-danger'>
                                        <FormattedNumber value={productPrice?.price} />đ
                                      </span>
                                      <span className='ms-2'>
                                        Còn: {product.availableQuantity} {product.unit_name || 'kg'}
                                      </span>
                                    </div>
                                    <div className='mb-1'>
                                      {product.max_order > 0 &&
                                        `Đặt dưới: ${product.max_order} ${
                                          product.unit_name || 'kg'
                                        } `}
                                    </div>
                                  </div>

                                  <div className='product-package w-100 mt-3'>
                                    {product?.packages?.map((packageItem) => {
                                      const cartPackage =
                                        cartProduct?.packageItems?.[packageItem?._id];
                                      return (
                                        <div
                                          className='d-flex w-100 justify-content-end align-items-center mt-auto mb-2'
                                          key={packageItem._id}
                                        >
                                          <span className='w-100px'>{packageItem.name}</span>
                                          <div className='d-flex'>
                                            <i
                                              className={`las la-minus border border-dark text-dark rounded p-1 h-25px w-25px d-flex align-items-center justify-content-center ${clsx(
                                                {'opacity-0': !cartPackage}
                                              )}`}
                                              onClick={() => {
                                                if (cartPackage) {
                                                  handelDeleteToCart(packageItem, product);
                                                }
                                              }}
                                            ></i>
                                            <span
                                              className={`fw-bold  mx-2 h-25px w-25px d-flex align-items-center justify-content-center`}
                                            >
                                              {cartPackage?.packageQuantity || ''}
                                            </span>
                                            <i
                                              className='las la-plus bg-primary text-white rounded  p-1 h-25px w-25px d-flex align-items-center justify-content-center'
                                              onClick={() => handelAddToCart(packageItem, product)}
                                            ></i>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  {totalProductQuantity(product) > 0 && (
                                    <div className='me-2 badge bg-success d-none '>
                                      <span>
                                        Đã đặt: {totalProductQuantity(product)}{' '}
                                        {product.unit_name || 'kg'}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/*end::Content*/}
          </Tab.Container>
        </div>
        <div className='cart-order shadow'>
          <div className='h-100 d-flex align-items-center justify-content-between bg-white'>
            <button type='button' className='btn btn-white position-relative p-1 ms-3'>
              <i className='las text-primary la-cart-plus cursor-pointer cart-icon'></i>
              <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                {cartSummary.totalProduct}
              </span>
            </button>
            <div className='d-flex h-100 d-flex align-items-center'>
              <div className='fw-bold text-primary fs-5 px-3'>
                <FormattedNumber value={cartSummary.totalAmount} />đ
              </div>
              <div
                className='h-100 w-100px bg-primary fw-bold fs-4 text-white d-flex align-items-center cursor-pointer justify-content-center'
                onClick={() => {
                  cartSummary?.totalProduct > 0 && setShowCartOrder(true);
                }}
                disabled={cartSummary.totalProduct === 0}
              >
                <span>Đặt hàng</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderPagePublic;
