import {Input, LoadingDialog} from 'app/common/_partials/controls';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Field, Form, Formik} from 'formik';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import { toAbsoluteUrl } from 'core/helpers';

function ShortLinkEditForm({saveShortLink, initShortLink, onHide, isEdit}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const {shortLinkId} = useParams();
  const shortLinkSchema = Yup.object().shape({
    name: Yup.string().required('Tên là bắt buộc'),
  });

  const {actionsLoading} = useSelector((state) => state.shortLink, shallowEqual);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initShortLink}
        validationSchema={shortLinkSchema}
        onSubmit={(values) => {
          saveShortLink(values);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({handleSubmit, values}) => {
          return (
            <>
              <LoadingDialog isLoading={actionsLoading} text={t('common_loading')} />
              <Modal.Body className='overlaycursor-default'>
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <Field
                        name='name'
                        component={Input}
                        placeholder={'Tên'}
                        label={'Tên'}
                        focus
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <Field
                        name='shortLink'
                        component={Input}
                        placeholder={'Link rút gọn'}
                        label={'Link rút gọn'}
                        
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <Field
                        name='fullLink'
                        component={Input}
                        placeholder={'Link đầy đủ'}
                        label={'Link đầy đủ'}
                      />
                    </div>
                  </div>
                  <span>{`${window.location.origin}${toAbsoluteUrl(`/p/short/${values.shortLink}`)}`}</span>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' onClick={onHide} className='btn btn-light btn-elevate me-2'>
                  {t('common_cancel')}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary btn-elevate'
                  onClick={handleSubmit}
                >
                  {isEdit ? t('common_save') : t('common_add_new')}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default ShortLinkEditForm;
