import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api'

const endPoints = {
  listShortLink: '/api/shortLink/search',
  addShortLink: '/api/shortLink',
  editShortLink: (id) => `/api/shortLink/${id}`,
  shortLinkDetail: (id) => `/api/shortLink/${id}`,
  deleteShortLink: (id) => `/api/shortLink/${id}`,
  duplicateShortLink: (id) => `/api/shortLink/${id}/duplicate`,
  shortLinkFromCode: '/api/shortLink/fromCode'
};

export function getShortLinks(params) {
  return postApiV2(endPoints.listShortLink, params)
}

export function addShortLink(params) {
  return postApiV2(endPoints.addShortLink, params);
}

export function editShortLink(params, id) {
  return putApiV2(endPoints.editShortLink(id), params);
}

export function shortLinkDetail(id) {
  return getApiV2(endPoints.shortLinkDetail(id));
}

export function deleteShortLink(id) {
  return deleteApiV2(endPoints.deleteShortLink(id));
}


export function duplicateShortLink(id) {
  return postApiV2(endPoints.duplicateShortLink(id));
}



export function shortLinkFromCode(code) {
  return getApiV2(endPoints.shortLinkFromCode, {code});
}
