import {lazy, FC, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../core/layouts/layout2/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '../../core/assets/ts/_utils';
import {WithChildren} from '../../core/helpers';
import OrderRoutes from 'app/modules/order/OrderRoutes';
import CustomerRoutes from 'app/modules/customers/CustomerRoutes';
import PriceListRoutes from 'app/modules/price-lists/PriceListRoutes';
import ProductCategoryRoutes from 'app/modules/product-category/ProductCategoryRoutes';
import ShortLinkRoutes from 'app/modules/short-links/ShortLinkRoutes';
import CustomerCategoryRoutes from 'app/modules/customer-category/CustomerCategoryRoutes';
import SellerRoutes from 'app/modules/seller/SellerRoutes';
import ReportRoutes from 'app/modules/reports/ReportRoutes';

const PrivateRoutes = () => {
  const TeamPage = lazy(() => import('../modules/teams/TeamPage'));
  const ProfilePage = lazy(() => import('app/modules/profile/ProfilePage'));
  const ProductRoutes = lazy(() => import('app/modules/products/ProductRoutes'));
  const OrderBatchRoutes = lazy(() => import('app/modules/order-batch/OrderBatchRoutes'));
  const OrderLandingPageRoutes = lazy(
    () => import('app/modules/landing-page-order/OrderLandingPageRoutes')
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}

        {/* Lazy Modules */}
        <Route
          path='products/*'
          element={
            <>
              <SuspensedView>
                <ProductRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='batch-order/*'
          element={
            <>
              <SuspensedView>
                <OrderBatchRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='customers/*'
          element={
            <>
              <SuspensedView>
                <CustomerRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='customer-category/*'
          element={
            <>
              <SuspensedView>
                <CustomerCategoryRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='seller/*'
          element={
            <>
              <SuspensedView>
                <SellerRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='orders/*'
          element={
            <>
              <SuspensedView>
                <OrderRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='price-list/*'
          element={
            <>
              <SuspensedView>
                <PriceListRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='pro-category/*'
          element={
            <>
              <SuspensedView>
                <ProductCategoryRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='short-link/*'
          element={
            <>
              <SuspensedView>
                <ShortLinkRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='teams/*'
          element={
            <SuspensedView>
              <TeamPage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='page-order/*'
          element={
            <>
              <SuspensedView>
                <OrderLandingPageRoutes />
              </SuspensedView>
            </>
          }
        />
         <Route
          path='report/*'
          element={
            <>
              <SuspensedView>
                <ReportRoutes />
              </SuspensedView>
            </>
          }
        />

        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
