import {CardHeaderToolbar, LoadingDialog, Pagination} from 'app/common/_partials/controls';
import {useIntl} from 'react-intl';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  defaultTableOptions,
  getHandlerTableChange,
} from 'app/common/_helpers';
import {useCallback, useEffect, useState} from 'react';
import {isEqual, isFunction} from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from 'app/common/_helpers';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { deleteSellerAction, fetchSellersAction} from '../redux/sellerActions';
import AddSeller from './Modal/AddSeller';
import EditSeller from './Modal/EditSeller';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import SellerListFilter from './SellerListFilter';

export default function SellerListPage() {
  const intl = useIntl();
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {sellers, countSellers} = useSelector((state) => state.seller, shallowEqual);
  const [seller, setSeller] = useState(null);
  const [sellerDelete, setSellerDelete] = useState(null);

  const [showAddSeller, setShowAddSeller] = useState(false);

  const tableOptions = {
    ...defaultTableOptions,
    searchText: null,
  };

  const [queryParams, setQueryParamsBase] = useState(tableOptions);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      return nextQueryParams;
    });
  }, []);

  const customerColumns = [
    {
      dataField: 'name',
      text: t('customer_name'),
    },
    {
      dataField: 'address',
      text: t('customer_address'),
    },
    {
      dataField: 'phone',
      text: t('customer_phone'),
    },
    {
      dataField: 'action',
      text: '',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEdit: setSeller,
        openDelete: setSellerDelete,
      },
      classes: 'text-right pe-0',
      headerClasses: 'text-right pe-3',
      style: {
        width: '50px',
      },
    },
  ];

  const onSaveSuccess = () => {
    loadSellers();
    setShowAddSeller(false);
    setSeller(null);
    setSellerDelete(null);
  };

  const handleDeleteSeller = () => {
    dispatch(deleteSellerAction(sellerDelete._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('seller_delete_success'));
        loadSellers();
        setSellerDelete(null);
      } else {
        snackNotification.showError(t('seller_delete_error'));
        setSellerDelete(null);
      }
    });
  };

  useEffect(() => {
    loadSellers();
  }, [dispatch, queryParams]);

  const loadSellers = () => {
    const params = {
      ...queryParams,
      paging: {
        pageNumber: queryParams.pageNumber,
        pageSize: queryParams.pageSize,
      },
    };
    dispatch(fetchSellersAction(params));
  }

  return (
    <>
      <LoadingDialog isLoading={false} text='Loading ...' />
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('customer_title')}</h3>
              <CardHeaderToolbar>
                <div className='d-flex'>
                  <SellerListFilter setQueryParams={setQueryParams} queryParams={queryParams} />
                  <button
                    type='button'
                    className='btn btn-sm btn-primary ms-2 w-100'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowAddSeller(true);
                    }}
                  >
                    {intl.formatMessage({id: 'common_add_new'})}
                  </button>
                </div>
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <PaginationProvider
                pagination={paginationFactory({
                  ...tableOptions,
                  totalSize: countSellers,
                  page: queryParams.pageNumber,
                  withFirstAndLast: false,
                  hideSizePerPage: true,
                })}
              >
                {({paginationProps, paginationTableProps}) => {
                  return (
                    <Pagination isLoading={false} paginationProps={paginationProps}>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                        bootstrap4
                        remote
                        hover
                        keyField='_id'
                        onTableChange={getHandlerTableChange(setQueryParams)}
                        data={sellers === null ? [] : sellers}
                        columns={customerColumns}
                        {...paginationTableProps}
                      >
                        <PleaseWaitMessage entities={sellers === null ? [] : sellers} />
                        <NoRecordsFoundMessage entities={sellers === null ? [] : sellers} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </div>
      <AddSeller
        show={showAddSeller}
        onHide={() => setShowAddSeller(false)}
        onSaveSuccess={onSaveSuccess}
      />
      {seller && (
        <EditSeller
          show={seller ? true : false}
          seller={seller}
          onHide={() => setSeller(null)}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {sellerDelete && (
        <ModalDelete
          onClick={handleDeleteSeller}
          show={sellerDelete ? true : false}
          title={'Xoá người bán'}
          content={'Bạn muốn xoá người bán'}
          deleteName={sellerDelete.name}
          close={() => setSellerDelete(null)}
        />
      )}
    </>
  );
}

const ActionsColumnFormatter = (cellContent, row, rowIndex, {openEdit, openDelete}) => (
  <div className='d-flex'>
    <a
      className='btn btn-hover-primary btn-xs mx-2'
      key='edit'
      onClick={(event) => {
        event.stopPropagation();
        openEdit(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
      </span>
    </a>
    <a
      className='btn btn-hover-primary btn-xs mx-2'
      key='delete'
      onClick={(event) => {
        event.stopPropagation();
        openDelete(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
      </span>
    </a>
  </div>
);
