import {CardHeaderToolbar, LoadingDialog, Pagination} from 'app/common/_partials/controls';
import {useIntl} from 'react-intl';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  defaultTableOptions,
  getHandlerTableChange,
} from 'app/common/_helpers';
import {useCallback, useEffect, useState} from 'react';
import {isEqual, isFunction} from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from 'app/common/_helpers';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {
  deletePriceListAction,
  duplicatePriceListAction,
  fetchPriceListsAction,
} from '../redux/priceListActions';
import AddPriceList from './Modal/AddPriceList';
import EditPriceList from './Modal/EditPriceList';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useNavigate} from 'react-router-dom';
import PriceListListFilter from './PriceListListFilter';

export default function PriceListListPage() {
  const intl = useIntl();
  const {t} = useTranslate();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {priceLists, countPriceLists} = useSelector((state) => state.priceList, shallowEqual);
  const [priceList, setPriceList] = useState(null);
  const [priceListDelete, setPriceListDelete] = useState(null);

  const [showAddPriceList, setShowAddPriceList] = useState(false);

  const tableOptions = {
    ...defaultTableOptions,
    searchText: null,
  };

  const [queryParams, setQueryParamsBase] = useState(tableOptions);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const priceListColumns = [
    {
      dataField: 'name',
      text: t('priceList_name'),
    },
    {
      dataField: 'action',
      text: '',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEdit: (row) => {
          navigate(`/price-list/${row._id}`);
        },
        openDelete: setPriceListDelete,
        openDuplicate: (row) => {
          duplicatePriceList(row._id);
        },
      },
      classes: 'text-right pe-0',
      headerClasses: 'text-right pe-3',
      style: {
        width: '50px',
      },
    },
  ];

  const duplicatePriceList = (id) => {
    dispatch(duplicatePriceListAction(id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('priceList_duplicate_success'));
        loadPriceLists();
      } else {
        snackNotification.showError(t('priceList_duplicate_error'));
      }
    });
  };

  const onSaveSuccess = () => {
    loadPriceLists();
    setShowAddPriceList(false);
    setPriceList(null);
    setPriceListDelete(null);
  };

  const handleDeletePriceList = () => {
    dispatch(deletePriceListAction(priceListDelete._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('priceList_delete_success'));
        loadPriceLists();
        setPriceListDelete(null);
      } else {
        snackNotification.showError(t('priceList_delete_error'));
        setPriceListDelete(null);
      }
    });
  };

  useEffect(() => {
    loadPriceLists();
  }, [dispatch, queryParams]);

  const loadPriceLists = () => {
    const params = {
      ...queryParams,
      paging: {
        pageNumber: queryParams.pageNumber,
        pageSize: queryParams.pageSize,
      },
    };
    dispatch(fetchPriceListsAction(params));
  };

  return (
    <>
      <LoadingDialog isLoading={false} text='Loading ...' />
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('priceList_title')}</h3>
              <CardHeaderToolbar>
                <div className='d-flex'>
                  <PriceListListFilter setQueryParams={setQueryParams} queryParams={queryParams} />
                  <button
                    type='button'
                    className='btn btn-sm btn-primary ms-2 w-100'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowAddPriceList(true);
                    }}
                  >
                    {intl.formatMessage({id: 'common_add_new'})}
                  </button>
                </div>
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <PaginationProvider
                pagination={paginationFactory({
                  ...tableOptions,
                  totalSize: countPriceLists,
                  page: queryParams.pageNumber,
                  withFirstAndLast: false,
                  hideSizePerPage: true,
                })}
              >
                {({paginationProps, paginationTableProps}) => {
                  return (
                    <Pagination isLoading={false} paginationProps={paginationProps}>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                        bootstrap4
                        remote
                        hover
                        keyField='_id'
                        onTableChange={getHandlerTableChange(setQueryParams)}
                        data={priceLists === null ? [] : priceLists}
                        columns={priceListColumns}
                        {...paginationTableProps}
                      >
                        <PleaseWaitMessage entities={priceLists === null ? [] : priceLists} />
                        <NoRecordsFoundMessage entities={priceLists === null ? [] : priceLists} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </div>
      <AddPriceList
        show={showAddPriceList}
        onHide={() => setShowAddPriceList(false)}
        onSaveSuccess={onSaveSuccess}
      />
      {priceList && (
        <EditPriceList
          show={priceList ? true : false}
          priceList={priceList}
          onHide={() => setPriceList(null)}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {priceListDelete && (
        <ModalDelete
          onClick={handleDeletePriceList}
          show={priceListDelete ? true : false}
          title={'Xoá sản phẩm'}
          content={'Bạn muốn xoá sản phẩm'}
          deleteName={priceListDelete.name}
          close={() => setPriceListDelete(null)}
        />
      )}
    </>
  );
}

const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {openEdit, openDelete, openDuplicate = null}
) => (
  <div className='d-flex'>
    <a
      className='btn  btn-icon btn-hover-primary btn-sm mx-2'
      key='edit'
      onClick={(event) => {
        event.stopPropagation();
        openEdit(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
      </span>
    </a>
    <a
      className='btn  btn-icon btn-hover-primary btn-sm mx-2'
      key='duplicate'
      title='Duplicate'
      onClick={(event) => {
        event.stopPropagation();
        openDuplicate && openDuplicate(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Duplicate.svg')} />
      </span>
    </a>
    <a
      className='btn  btn-icon  btn-hover-primary btn-sm mx-2'
      key='delete'
      onClick={(event) => {
        event.stopPropagation();
        openDelete(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
      </span>
    </a>
  </div>
);
