import {useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';
import {debounce} from 'lodash';
import {useTranslate} from 'core/i18n/i18nProvider';
import {DatePickerField} from 'app/common/_partials/controls';
import {
  getCustomersAction,
  getProductsAction,
  getProductReportByPaginationAction,
  getProductExcelReportAction
} from '../redux/reportActions';
import AsyncSelect from "react-select/async";
import './report.scss';
import { useEffect, useState } from 'react';

export default function ReportProductListFilter({queryParams, setQueryParams}) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const prepareFilter = (queryParams, values) => {
    const {startDate,endDate,nameProduct, name} = values;
    const newQueryParams = {...queryParams};
    newQueryParams.name = name;
    newQueryParams.startDate = startDate ? new Date(startDate.getTime()).toDateString(): '';
    newQueryParams.nameProduct = nameProduct;
    newQueryParams.endDate = endDate ? new Date(endDate.getTime() + 86400000).toDateString() : '';
    return newQueryParams;
  };
  const [productReportByPagination, setProductReportByPagination] = useState([]); 
  const [products, setProducts] = useState([])
  const [customers, setCustomers] = useState([])
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(queryParams, values);
    if (!newQueryParams.paging) {
      newQueryParams.paging = {};
    }
    newQueryParams.pageNumber = 1;
    setQueryParams(newQueryParams);
  };

  const searchCustomer = (query) => {
    const params = {
      filter: {
        q: query
      },
      paging: {
        pageNumber: 1,
        pageSize: 1000,
      },
    };
    return dispatch(getCustomersAction(params)).then((response) => {
      let customers = response.data;
      return customers.map((customer) => {
          return {
            value: customer.name,
            label: customer.name,
          };
      });
    });
  };

  const searchProduct = (query) => {
    const params = {
      filter: {
        q: query
      },
      paging: {
        pageNumber: 1,
        pageSize: 1000,
      },
    };
    return dispatch(getProductsAction(params)).then((response) => {
      let products = response.data;
      return products.map((product) => {
        return {
          value: product.name,
          label: product.name,
        };
      });
    });
  }; 

  useEffect(() => {
    loadProductReport();
  }, [dispatch, queryParams]);

  useEffect(() => {
    loadProduct();
    loadCustomer();
  }, [dispatch]);

  const loadProduct = () => {
    const params = {
      paging: {
        pageNumber: 1,
        pageSize: 1000,
      },
    };
    dispatch(getProductsAction(params)).then(res => {
      let products = res.data;
      products = products.map((product) => {
        return {
          value: product.name,
          label: product.name,
        };
      });
        setProducts(products);
    });
  };

  const loadProductReport = () => {
    const params = {
      ...queryParams,
      paging: {
        pageNumber: queryParams.pageNumber,
        pageSize: queryParams.pageSize,
      },
    };
    dispatch(getProductReportByPaginationAction(params)).then(res => {
      let productReports = res.data.data ?? [];
      let totalQuantityReport = 0;
      let totalRevenueReport = 0;
      productReports = productReports.map((productReport) => {
        totalQuantityReport += productReport.quantity;
        totalRevenueReport += productReport.totalAmount;
      });
      setProductReportByPagination(res.data.data);
    });
  };

  const loadCustomer = () => {
    const params = {
      paging: {
        pageNumber: 1,
        pageSize: 1000,
      },
    };
    dispatch(getCustomersAction(params)).then(res => {
      let customers = res.data;
      customers = customers.map((customer) => {
        return {
          value: customer.name,
          label: customer.name,
        };
      });
      setCustomers(customers);
    });
  };



  const exportExcel = () => {
    const params = {
      ...queryParams,
    };
    dispatch(getProductExcelReportAction(params)).then(res => {
      window.open(res.data);
    });
  }
  return (
    <>
      <Formik
        initialValues={{
          searchText: '',
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >         
        {({handleSubmit, handleBlur, setFieldValue}) => (
          <Form className='row d-flex w-100'>
              <div className='col-6 mt-4 d-flex align-items-center justify-content-between mb-5'>
                <div className='col-lg-5 customer'>
                <AsyncSelect
                    cacheOptions
                    maxMenuHeight={200}
                    placeholder="Tên khách hàng"
                    defaultOptions= {customers}
                    loadOptions={debounce(searchCustomer, 100)} 
                    onBlur={handleBlur}
                    isClearable
                    onChange={(val) => {
                      setFieldValue('name',val ? val.value : "");
                    }}
                  />
                </div>
                <div className='col-lg-5 product'>
                  <AsyncSelect
                      className='product-filter-select'
                      cacheOptions
                      maxMenuHeight={200}
                      defaultOptions= {products}
                      placeholder="Tên sản phẩm"
                      loadOptions={debounce(searchProduct, 100)}
                      onBlur={handleBlur}
                      isMulti
                      styles={'height: 50px'}
                      onChange={(val) => {
                        setFieldValue('nameProduct',val);
                      }}
                  />
                </div>
               
              </div>
              <div className='col-6 mt-4 d-flex align-items-center justify-content-between mb-5'>
              <div className='col-lg-4'>
                  <DatePickerField className='custom-date-picker' name='startDate' dateFormat='dd/MM/yyyy' placeholderText={'Ngày bắt đầu'}/>
                </div>
                <div className='col-lg-4'>
                  <DatePickerField className='custom-date-picker' name='endDate' dateFormat='dd/MM/yyyy' placeholderText={'Ngày kết thúc'} />
                </div>
                 <div>
                    <button type='button' onClick={handleSubmit} className='btn btn-secondary btn-sm float-right h-40px'>
                      {t('common_search')}
                    </button>
                </div>
                <div>
                    <button type='button' onClick={exportExcel} className='btn btn-primary btn-sm float-right ms-4 w-100px h-40px'>
                    Xuất excel
                    </button>
                </div>
              </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
