import {Route, Routes, Outlet, Navigate} from 'react-router-dom';
import OrderPage from './page/OrderPage';

const OrderRoutes = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          element={
            <>
              <OrderPage />
            </>
          }
        />
      </Route>
      <Route index element={<OrderPage />} />
    </Routes>
  );
};

export default OrderRoutes;
