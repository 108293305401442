import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api'

const endPoints = {
  listOrderLandingPage: '/api/orderLandingPage/search',
  addOrderLandingPage: '/api/orderLandingPage',
  listOrderBatch: '/api/orderBatch/search',
  editOrderLandingPage: (id) => `/api/orderLandingPage/${id}`,
  orderLandingPageDetail: (id) => `/api/orderLandingPage/${id}`,
  deleteOrderLandingPage: (id) => `/api/orderLandingPage/${id}`,
  listCustomerCategory: '/api/customerCategory/search',

  listProduct: '/api/product',
  listProductById: '/api/product/ids',
  orderByOrderBatch: (id) => `/api/order/orderBatch/${id}`,
  getPackageSummaryByOrderBatch: (id) => `/api/order/order-batch/${id}/package-summary`,
  getOrderBatchSummaryProductOrder: (id) => `/api/order/order-batch/${id}/product-summary`,
  getOrderBatchSummaryProductCustomerOrder: (id) =>
    `/api/order/order-batch/${id}/product-customer-summary`,
  getOrderBatchSalesSummary: (id) => `/api/order/order-batch/${id}/sales-summary`,
  getPriceList: '/api/price-list',
  uploadFile: `/api/file`,
  update: (id) => `/api/order/${id}`,
  deleteOrder: (id) => `/api/order/${id}`,
};


export function getListOrderLandingPage(params) {
  return postApiV2(endPoints.listOrderLandingPage, params);
}

export function addOrderLandingPage(params) {
  return postApiV2(endPoints.addOrderLandingPage, params);
}

export function getListOrderBatch(params) {
  return postApiV2(endPoints.listOrderBatch, params);
}

export function editOrderLandingPage(params, id) {
  return putApiV2(endPoints.editOrderLandingPage(id), params);
}

export function orderLandingPageDetail(id) {
  return getApiV2(endPoints.orderLandingPageDetail(id));
}

export function deleteOrderLandingPage(id) {
  return deleteApiV2(endPoints.deleteOrderLandingPage(id));
}

export function getProducts(params) {
  return getApiV2(endPoints.listProduct, params);
}

export function getProductByIds(params) {
  return getApiV2(endPoints.listProductById, params);
}

export function orderByOrderBatch(id) {
  return getApiV2(endPoints.orderByOrderBatch(id));
}

export function getPrices(params) {
  return getApiV2(endPoints.getPriceList, params);
}

export function getPackageSummaryByOrderBatch(id) {
  return getApiV2(endPoints.getPackageSummaryByOrderBatch(id));
}

export function getOrderBatchSummaryProductOrder(id) {
  return getApiV2(endPoints.getOrderBatchSummaryProductOrder(id));
}


export function getOrderBatchSummaryProductCustomerOrder(id) {
  return getApiV2(endPoints.getOrderBatchSummaryProductCustomerOrder(id));
}


export function getOrderBatchSalesSummary(id) {
  return getApiV2(endPoints.getOrderBatchSalesSummary(id));
}

export function uploadFile(params) {
  var bodyFormData = new FormData();

  bodyFormData.append('file', params.file);
  return postApiV2(endPoints.uploadFile, bodyFormData);
}


export function updateOrder(params, id) {
  return putApiV2(endPoints.update(id), params);
}

export function deleteOrder(id) {
  return deleteApiV2(endPoints.deleteOrder(id));
}

export function getCustomerCategories(params) {
  return postApiV2(endPoints.listCustomerCategory, params);
}