import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';
import CustomerListPage from './CustomerListPage';
const CustomerPage = () => {
  return (
    <div>
      <PageAside>
        <Menu />
      </PageAside>
      <CustomerListPage/>
    </div>
  );
};

export default CustomerPage;
