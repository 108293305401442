import * as requestFromServer from './CustomerCategoryApi'
import {customerCategorySlice, callTypes} from './CustomerCategorySlice'

const {actions} = customerCategorySlice

export const fetchCustomerCategoriesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getCustomerCategorys(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.customerCategoriesFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get customerCategorys";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const addCustomerCategoryAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .addCustomerCategory(params)
    .catch((error) => {
      error.clientMessage = "Can't add customerCategory";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
}


export const editCustomerCategoryAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .editCustomerCategory(params, id)
    .catch((error) => {
      error.clientMessage = "Can't edit CustomerCategory";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}



export const getCustomerCategoryDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .customerCategoryDetail(id)
    .catch((error) => {
      error.clientMessage = "Can't get CustomerCategory";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}

export const fetchCustomersAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getCustomers(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.customersFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get customers";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}

export const deleteCustomerCategoryAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .deleteCustomerCategory(id)
    .catch((error) => {
      error.clientMessage = "Can't delete CustomerCategory";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}


export const uploadFileAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.uploadFile(params).catch((error) => {
    error.clientMessage = "Can't upload file";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};
