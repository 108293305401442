import React, { useEffect } from 'react';
import './orderPrint.scss';
import {FormattedDate, FormattedTime} from 'react-intl';
import { toAbsoluteUrl } from 'core/helpers';

export const OrderPrint = React.forwardRef(({order}, ref) => {

  return (
    <div className='order-print-area' ref={ref}>
      <div className='print-k80 ' style={{paddingLeft: '0.3cm', paddingRight: '0.3cm'}}>
        <div className='text-center'>
          <img
            style={{textAlign: 'center', width: '70%'}}
            src={toAbsoluteUrl('/media/logos/7ltd-print-logo.jpg')}
          />
        </div>
        <div className='title fs-2' style={{textAlign: 'center', fontSize: '20px'}}>
          7LTD xin chân thành cảm ơn Quý khách
        </div>
        <div
          className='title'
          style={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginTop: '10px'}}
        >
          {order?.name}
        </div>
        <div className='title' style={{textAlign: 'center', fontSize: '18px', marginTop: '10px'}}>
          Đã tin tưởng và ủng hộ Bambi Essential Oil.
        </div>
        <div
          className='title'
          style={{textAlign: 'center', fontSize: '16px', fontWeight: 'bold', marginTop: '18px'}}
        >
          THÔNG TIN CHI TIẾT ĐƠN HÀNG CỦA QUÝ KHÁCH
        </div>
        <table>
          <thead>
            <tr>
              <th style={{width: '0.7cm'}}>STT</th>
              <th>Tên hàng</th>
              <th style={{width: '1cm'}}>ĐVT</th>
              <th style={{width: '1cm'}}>Số lượng</th>
              <th style={{width: '1.5cm'}}>Đơn giá (VND)</th>
              <th style={{width: '1.5cm'}}>Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {order?.details?.map((pack, idx) => {
              const totalAmount =
                Number(pack?.price) *
                  pack?.package_quantity *
                  pack?.product?.packageItems?.[pack?.package_id]?.quantity || 0;
              return (
                <tr>
                  <td style={{textAlign: 'center'}}>{idx + 1}</td>
                  <td>{pack.product.name}</td>
                  <td style={{textAlign: 'center'}}>{pack.product.unit_name}</td>
                  <td style={{textAlign: 'center'}}>{Math.round(pack.package_quantity * 100) / 100}</td>
                  <td style={{textAlign: 'right'}}>{formatQuantity(pack.price)}</td>
                  <td style={{textAlign: 'right'}}>{formatQuantity(totalAmount)}</td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td style={{fontWeight: 'bold'}}>Tổng giá trị đơn hàng</td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{textAlign: 'right'}}>{formatQuantity(order?.total_amount)}</td>
            </tr>
            <tr className='info-payment'>
              <td className='border-none'></td>
              <td className='border-none'>Phương thức thanh toán</td>
              <td className='border-none' colSpan={5}>
                {order?.payments === 'transfer' ? 'Chuyển khoản' : 'COD'}
              </td>
            </tr>
            <tr className='info-payment'>
              <td className='border-none'></td>
              <td className='border-none'>Địa chỉ giao hàng</td>
              <td className='border-none' colSpan={5}>
                {order?.address || order?.customer?.address}
              </td>
            </tr>
            <tr className='info-payment'>
              <td className='border-none'></td>
              <td className='border-none'>Số điện thoại</td>
              <td className='border-none' colSpan={5}>
                {order?.phone_delivery || order?.phone || order?.customer?.phone}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='user'>
          <div>Người giao</div>
          <div>Người nhận</div>
        </div>
        <div className='end'>Chúc Quý Khách năm mới an lành và gặp nhiều may mắn.</div>
      </div>
    </div>
  );
});

function formatCurrency(currency) {
  return Intl.NumberFormat().format(currency);
}

function formatPercent(percent) {
  return Intl.NumberFormat().format(percent) + '%';
}

function formatQuantity(quantity) {
  return Intl.NumberFormat().format(quantity);
}
