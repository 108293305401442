import React from 'react';
import {Modal} from 'react-bootstrap';
import ShortLinkForm from './ShortLinkForm';
import {useDispatch} from 'react-redux';
import {addShortLinksAction} from '../../redux/shortLinkActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { useTranslate } from 'core/i18n/i18nProvider';

function AddShortLink({show, onHide, onSaveSuccess}) {
  const dispatch = useDispatch();
  const {t} = useTranslate()
  const snackNotification = useSnackNotification();

  const initShortLink = {
    name: '',
    shortLink: '',
    fullLink: '',
  };

  const saveShortLink = (shortLink) => {
    const params = {
      ...shortLink
    };
    dispatch(addShortLinksAction(params)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('shortLink_add_success'));
        onSaveSuccess();
      } else {
        snackNotification.showError(t('shortLink_add_error'));
        onHide();
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('shortLink_add_title')}</Modal.Title>
        </Modal.Header>
        <ShortLinkForm initShortLink={initShortLink} onHide={onHide} saveShortLink={saveShortLink} />
      </Modal>
    </div>
  );
}

export default AddShortLink;
