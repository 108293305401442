import {MenuItem} from './MenuItem';
import {MenuInnerWithSub} from './MenuInnerWithSub';
import {useIntl} from 'react-intl';

export function MenuInner() {
  const intl = useIntl();
  return (
    <>
      <MenuInnerWithSub title="" icon="/media/icons/duotune/abstract/abs030.svg" to='/apps' menuPlacement='bottom-start'  >
        {/* PAGES */}
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/apps/user-management/users'
          title='User management'
        />
      </MenuInnerWithSub>
    </>
  );
}
