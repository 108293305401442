import React from 'react';
import logo from './assets/logo.jpg';
import page from './assets/page.png';
import './orderPageFooter.scss';
import './orderPage.scss';

function OrderPageFooter() {
  return (
    <div className='footer-container container'>
      <div className='d-flex justify-content-center align-items-center footer-item'>
        <img src={logo} style={{objectFit: 'contain', width: '50%'}} />
      </div>
      <div className='footer-item'>
        <p className='section-title title-decoration'>Thông tin liên hệ</p>
        <p>Đồng CEO/Trưởng ban điều hành: Phạm Nhật Minh - 0973757979</p>
        <p>Đồng CEO/Trưởng ban R&D: Phí Ngọc Lâm Uyên - 0936024526</p>
        <p>7ltd.contact@gmail.com</p>
      </div>
      <div className='footer-item'>
        <a
          className='d-flex justify-content-center align-items-center flex-column'
          href='https://www.facebook.com/profile.php?id=61555402496379'
          target='_blank'
        >
          <span className='section-title title-decoration'>Theo dõi chúng tôi</span>
          <img src={page} style={{objectFit: 'contain', width: '75%'}} />
        </a>
      </div>
    </div>
  );
}

export default OrderPageFooter;
