import * as requestFromServer from './priceListApi'
import {priceListSlice, callTypes} from './priceListSlice'

const {actions} = priceListSlice

export const fetchPriceListsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getPriceLists(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.priceListsFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get priceLists";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const addPriceListsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .addPriceList(params)
    .catch((error) => {
      error.clientMessage = "Can't add priceList";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
}


export const editPriceListsAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .editPriceList(params, id)
    .catch((error) => {
      error.clientMessage = "Can't edit priceList";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}



export const getPriceListDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .priceListDetail(id)
    .catch((error) => {
      error.clientMessage = "Can't get priceList";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const deletePriceListAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .deletePriceList(id)
    .catch((error) => {
      error.clientMessage = "Can't delete priceList";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}


export const duplicatePriceListAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .duplicatePriceList(id)
    .catch((error) => {
      error.clientMessage = "Can't suplicate priceList";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}



export const fetchProductsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getProducts(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.listProductFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get products";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};
