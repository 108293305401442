import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import SellerForm from './SellerForm';
import {useDispatch} from 'react-redux';
import {editSellerAction} from '../../redux/sellerActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { useTranslate } from 'core/i18n/i18nProvider';

function EditSeller({show, onHide, onSaveSuccess, seller}) {
  const dispatch = useDispatch();
  const {t} = useTranslate()
  const snackNotification = useSnackNotification();
  const initSeller = {
    name: '',
    code: '',
    address: '',
    phone: '',
    settings: {
      name: '',
      bank: '',
      bankAccount: '',
    },
  };

  const [sellerEdit, setSellerEdit] = useState(initSeller);

  useEffect(() => {
    const sellerEdit = {
      name: seller.name,
      code: seller.code,
      address: seller.address,
      phone: seller.phone,
      settings: seller.settings
    };
    setSellerEdit(sellerEdit);
  }, [seller]);

  const saveSeller = (sellerValue) => {
    const params = {
      ...sellerValue,
    };
    dispatch(editSellerAction(params, seller._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('seller_edit_success'));
        onSaveSuccess();
      } else {
        snackNotification.showError(t('seller_edit_error'));
        onHide();
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('seller_edit_title')}</Modal.Title>
        </Modal.Header>
        <SellerForm
          initSeller={sellerEdit}
          onHide={onHide}
          saveSeller={saveSeller}
          isEdit={true}
        />
      </Modal>
    </div>
  );
}

export default EditSeller;
