import React from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useTranslate} from 'core/i18n/i18nProvider';
import CustomerCategoryForm from './CustomerCategoryForm';
import { addCustomerCategoryAction, uploadFileAction } from '../../redux/CustomerCategoryActions';

function AddCustomerCategory({show, onHide, onSaveSuccess}) {
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const snackNotification = useSnackNotification();

  const initCustomerCategory = {
    name: '',
    code: '',
    description: ''
  };

  const saveCustomerCategory = async (customerCategory) => {
    try {
      dispatch(addCustomerCategoryAction(customerCategory)).then((res) => {
        if (res?.data?.success) {
          snackNotification.showSuccess(t('customerCategory_add_success'));
          onSaveSuccess();
        } else {
          snackNotification.showError(t('customerCategory_add_error'));
          onHide();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('customerCategory_add_title')}</Modal.Title>
        </Modal.Header>
        <CustomerCategoryForm initCustomerCategory={initCustomerCategory} onHide={onHide} saveCustomerCategory={saveCustomerCategory} />
      </Modal>
    </div>
  );
}

export default AddCustomerCategory;
