import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const slice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    startCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    endCall: (state, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
  },
})
