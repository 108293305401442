import ProductReportView from './ProductReportView';
import CustomerReportView from './CustomerReportView';
import {useState} from 'react';
import {Tab, Nav, Modal} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
const ReportPage = () => {
  const navigate = useNavigate();

  const {tab} = useParams();

  const [selectedTab, setSelectedTab] = useState(tab || 'report-product');

  const handleChangeTab = (tab) => {
    navigate(`/report/${tab}`, {replace: true});
    setSelectedTab(tab);
  };
  return (
    <div>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>Báo cáo</h3>
            </div>
            <div className='card-body'>
            <Modal.Body className='overlaycursor-default'>
              <div className='form-group row mt-4'>
                <Tab.Container defaultActiveKey={selectedTab}>
                  {/*begin::Header*/}
                    <div className='d-flex align-items-center justify-content-between mb-5'>
                      <Nav
                        onSelect={handleChangeTab}
                        as='ul'
                        role='tablist'
                        className='nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10'
                      >
                        <Nav.Item as='li'>
                          <Nav.Link
                            eventKey='report-product'
                            className={`nav-link ${selectedTab === 'report-product' ? 'active' : ''}`}
                          >
                            Báo cáo sản phẩm
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as='li'>
                          <Nav.Link
                            eventKey='report-customer'
                            className={`nav-link ${selectedTab === 'report-customer' ? 'active' : ''}`}
                          >
                            Báo cáo khách hàng
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>

                  {/*end::Header*/}

                  {/*begin::Content*/}
                  <div className=''>
                    <div className='tab-content'>
                      <div
                        id='kt_quick_panel_totalOrder'
                        role='tabpanel'
                        className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                          selectedTab === 'report-product' ? 'active show' : ''
                        }`}
                      >
                        <ProductReportView />
                      </div>
                      <div
                        id='kt_quick_panel_totalOrder'
                        role='tabpanel'
                        className={`tab-pane fade pt-3 pe-5 me-n5 scroll ps ${
                          selectedTab === 'report-customer' ? 'active show' : ''
                        }`}
                      >
                        <CustomerReportView />
                      </div>
                    </div>
                  </div>
                  {/*end::Content*/}
                </Tab.Container>
              </div>
            </Modal.Body>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default ReportPage;
