import * as requestFromServer from './reportApi'
import {reportSlice, callTypes} from './reportSlice'

const {actions} = reportSlice

export const getProductsAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getProducts(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get products";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    }).finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    });
}

export const getProductExcelReportAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getProductExcelReport(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get products";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    }).finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    });
}

export const getProductReportByPaginationAction = (data, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getProductReportByPagination(data,params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get products";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    }).finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    });
}

export const getProductReportNotByPaginationAction = (data, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getProductReportNotByPagination(data,params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get products";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    }).finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    });
}

export const getCustomerExcelReportAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getCustomerExcelReport(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get customers";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    }).finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    });
}

export const getCustomerReportByPaginationAction = (data, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getCustomerReportByPagination(data,params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get customers";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    }).finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    });
}

export const getCustomerReportNotByPaginationAction = (data, params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getCustomerReportNotByPagination(data,params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get customers";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    }).finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    });
}

export const getListOrderBatchAction = (params) => (dispatch) => {
    dispatch(actions.startCall({callType: callTypes.list}));

    return requestFromServer
        .getListOrderBatch(params)
        .then((res) => {
          const data = res.data;
          return data;
        })
        .catch((error) => {
            error.clientMessage = "Can't get list order batch";
            dispatch(actions.catchError({error, callType: callTypes.list}));
        }).finally(() => {
          dispatch(actions.endCall({callType: callTypes.action}))
        });
};

export const getCustomersAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getCustomers(params)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((error) => {
      error.clientMessage = "Can't get customers";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    })
    .finally(() => {
      dispatch(actions.endCall({callType: callTypes.action}))
    });
}
