import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api'

const endPoints = {
  listProductCategory: '/api/productCategory/search',
  addProductCategory: '/api/productCategory',
  editProductCategory: (id) => `/api/productCategory/${id}`,
  productCategoryDetail: (id) => `/api/productCategory/${id}`,
  deleteProductCategory: (id) => `/api/productCategory/${id}`,
  uploadFile: `/api/file`,
};

export function getProductCategorys(params) {
  return postApiV2(endPoints.listProductCategory, params)
}

export function addProductCategory(params) {
  return postApiV2(endPoints.addProductCategory, params);
}

export function editProductCategory(params, id) {
  return putApiV2(endPoints.editProductCategory(id), params);
}

export function productCategoryDetail(id) {
  return getApiV2(endPoints.productCategoryDetail(id));
}

export function deleteProductCategory(id) {
  return deleteApiV2(endPoints.deleteProductCategory(id));
}

export function uploadFile(params) {
    var bodyFormData = new FormData();

  bodyFormData.append('file', params.file);
  return postApiV2(endPoints.uploadFile, bodyFormData);
}
