import {FC} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from 'core/helpers'
import {
  HeaderUserMenu,
} from '../../../../partials'
import {useLayout} from '../../core'
import { ThemeModeSwitcher } from '../../partials/theme-mode/ThemeModeSwitcher'
import { useSession } from 'core/store/hooks'

const itemClass = 'ms-1 ms-lg-3',
  btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {config} = useLayout()
  const {user} = useSession();

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center d-none', itemClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(btnClass, 'position-relative')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <i className='bi bi-grid fs-2' />
        </div>
        {/* end::Menu wrapper */}
      </div>

      {/* begin::Theme mode */}
      <div className={clsx('d-flex align-items-center', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={btnClass} />
      </div>
      {/* end::Theme mode */}

      {/* begin::User */}
      <div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {user?.avatar ? (
            <img src={user?.avatar} alt='avatar' />
          ) : (
            <span className='symbol-label bg-light-danger text-danger fw-bold'>
              {user?.name?.[0] || ''}
            </span>
          )}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  );
}

export {Topbar}
