import {Route, Routes, Outlet, Navigate} from 'react-router-dom';
import CustomerCategoryPage from './page/CustomerCategoryPage';


const CustomerCategoryRoutes = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          element={
            <>
              <CustomerCategoryPage />
            </>
          }
        />
      </Route>
      <Route index element={<CustomerCategoryPage />} />
    </Routes>
  );
};

export default CustomerCategoryRoutes;
