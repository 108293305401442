import {Snackbar} from '@material-ui/core';
import React, {createContext, useState, useContext} from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';

const SnackNotificationContext = createContext();

export function useSnackNotification() {
  return useContext(SnackNotificationContext);
}

export const SnackNotificationConsumer = SnackNotificationContext.Consumer;

export function SnackNotificationProvider({children}) {
  const [snack, setSnack] = useState({
    open: false,
    id: null,
    message: '',
    variant: 'success',
  });

  const showSuccess = (message) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: 'success',
    });
  };

  const showError = (message) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: 'error',
    });
  };

  const showWarning = (message) => {
    setSnack({
      open: true,
      id: new Date().getTime(),
      message: message,
      variant: 'warning',
    });
  };

  const value = {snack, setSnack, showSuccess, showError, showWarning};

  return (
    <SnackNotificationContext.Provider value={value}>
      {children}
      {/*
      <ToastContainer className="p-3" position='top-end'>
        <Toast bg={snack.variant?.toLowerCase()} onClose={() => setSnack({ open: false })} show={snack.open} delay={3000} autohide>
          <Toast.Body>{snack.message}</Toast.Body>
        </Toast>
      </ToastContainer>
      */}
      {
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={snack.open}
          onClose={() => setSnack({...snack, open: false})}
          autoHideDuration={5000}
          options={snack}
          id={snack.id}
          color={snack.variant}
          variant={snack.variant}
          severity={snack.variant}
          message={snack.message}
        >
          
        </Snackbar>
      }
    </SnackNotificationContext.Provider>
  );
}
