import { createSlice } from "@reduxjs/toolkit";

const initialOrderState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  orders: [],
  countOrders: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: initialOrderState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    ordersFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.orders = data.data ? data.data : [];
      state.countOrders = data.count ? data.count : 0;
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
  },
});
