import * as requestFromServer from "./coreApi";
import {coreSlice, callTypes} from "./coreSlice";

const {actions} = coreSlice;

export const fetchDomainInformation = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getDomainInformation(params)
    .then(response => {
      const data = response.data;
      dispatch(actions.domainInformationFetched({ data }));
    })
    .catch(error => {
      error.clientMessage = "Can't get domain information";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};



export const fetchAllUsersAction = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getAllUsers(params)
    .then(response => {
      const data = response.data;
      dispatch(actions.allUsersFetched({ data }));
    })
    .catch(error => {
      error.clientMessage = "Can't get users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const fetchCurrentUserInfoAction = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .getCurrentUserInfo(params)
    .then(response => {
      const data = response.data;
      dispatch(actions.currentUserInfoFetched({ data }));
      return data;
    })
    .catch(error => {
      error.clientMessage = "Can't get users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
