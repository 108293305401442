import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';
import ShortLinkListPage from './ShortLinkListPage';
const ShortLinkPage = () => {
  return (
    <div>
      <PageAside>
        <Menu />
      </PageAside>
      <ShortLinkListPage/>
    </div>
  );
};

export default ShortLinkPage;
