import React, {useEffect, useState} from 'react';
import OrderLandingPageDefault from './templates/Default/OrderLandingPageDefault';
import {useAppDispatch} from 'core/store/hooks';
import {getOrderLandingPageByCodeAction} from '../redux/orderActions';
import {useParams} from 'react-router-dom';
import OrderLandingPage7LTD from './templates/7LTD/OrderLandingPage7LTD';

function PublicOrderPage(props) {
  const dispatch = useAppDispatch();
  const {code} = useParams();
  const [orderLandingPageDetail, setOrderLandingPageDetail] = useState({});

  useEffect(() => {
    dispatch(getOrderLandingPageByCodeAction({code: code})).then((res) => {
      setOrderLandingPageDetail(res.data.data || {});
    });
  }, [dispatch, code]);

  if (orderLandingPageDetail.template === 'tinhdau') {
    return <OrderLandingPage7LTD orderLandingPageDetail={orderLandingPageDetail} />;
  }
  return <OrderLandingPageDefault orderLandingPageDetail={orderLandingPageDetail} />;
}

export default PublicOrderPage;
