import {CardHeaderToolbar, LoadingDialog, Pagination} from 'app/common/_partials/controls';
import {useIntl} from 'react-intl';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  defaultTableOptions,
  getHandlerTableChange,
} from 'app/common/_helpers';
import {useCallback, useEffect, useState} from 'react';
import {isEqual, isFunction} from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from 'app/common/_helpers';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {
  deleteShortLinkAction,
  duplicateShortLinkAction,
  fetchShortLinksAction,
} from '../redux/shortLinkActions';
import AddShortLink from './Modal/AddShortLink';
import EditShortLink from './Modal/EditShortLink';
import {useTranslate} from 'core/i18n/i18nProvider';
import {ModalDelete} from 'app/common/_partials/controls/ModalDelete';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import {useNavigate} from 'react-router-dom';
import ShortLinkListFilter from './ShortLinkListFilter';
import copy from 'clipboard-copy';

export default function ShortLinkListPage() {
  const intl = useIntl();
  const {t} = useTranslate();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {shortLinks, countShortLinks} = useSelector((state) => state.shortLink, shallowEqual);
  const [shortLink, setShortLink] = useState(null);
  const [shortLinkDelete, setShortLinkDelete] = useState(null);

  const [showAddShortLink, setShowAddShortLink] = useState(false);

  const tableOptions = {
    ...defaultTableOptions,
    searchText: null,
  };

  const [queryParams, setQueryParamsBase] = useState(tableOptions);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);

  const shortLinkColumns = [
    {
      dataField: 'name',
      text: t('shortLink_name'),
    },
    {
      dataField: 'shortLink',
      text: 'Short Link',
      formatter: (cell) => {
        return (
          <>
            <i
              className='cursor-pointer las la-copy fs-3 ms-2'
              onClick={() => copy(`${window.location.origin}${toAbsoluteUrl(`/p/short/${cell}`)}`)}
            ></i>
            {`${window.location.origin}${toAbsoluteUrl(`/p/short/${cell}`)}`}
          </>
        );
      },
    },
    {
      dataField: 'action',
      text: '',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEdit: (row) => {
          navigate(`/short-link/${row._id}`);
        },
        openDelete: setShortLinkDelete,
        openDuplicate: (row) => {
          duplicateShortLink(row._id);
        },
      },
      classes: 'text-right pe-0',
      headerClasses: 'text-right pe-3',
      style: {
        width: '50px',
      },
    },
  ];

  const duplicateShortLink = (id) => {
    dispatch(duplicateShortLinkAction(id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('shortLink_duplicate_success'));
        dispatch(fetchShortLinksAction());
      } else {
        snackNotification.showError(t('shortLink_duplicate_error'));
      }
    });
  };

  const onSaveSuccess = () => {
    dispatch(fetchShortLinksAction());
    setShowAddShortLink(false);
    setShortLink(null);
    setShortLinkDelete(null);
  };

  const handleDeleteShortLink = () => {
    dispatch(deleteShortLinkAction(shortLinkDelete._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('shortLink_delete_success'));
        dispatch(fetchShortLinksAction());
        setShortLinkDelete(null);
      } else {
        snackNotification.showError(t('shortLink_delete_error'));
        setShortLinkDelete(null);
      }
    });
  };

  useEffect(() => {
    const params = {
      ...queryParams,
      paging: {
        pageNumber: queryParams.pageNumber,
        pageSize: queryParams.pageSize,
      },
    };
    dispatch(fetchShortLinksAction({params}));
  }, [dispatch, queryParams]);

  return (
    <>
      <LoadingDialog isLoading={false} text='Loading ...' />
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('shortLink_title')}</h3>
              <CardHeaderToolbar>
                <div className='d-flex'>
                  <ShortLinkListFilter setQueryParams={setQueryParams} queryParams={queryParams} />
                  <button
                    type='button'
                    className='btn btn-sm btn-primary ms-2 w-100'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowAddShortLink(true);
                    }}
                  >
                    {intl.formatMessage({id: 'common_add_new'})}
                  </button>
                </div>
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <PaginationProvider
                pagination={paginationFactory({
                  ...tableOptions,
                  totalSize: countShortLinks,
                  page: queryParams.pageNumber,
                  withFirstAndLast: false,
                  hideSizePerPage: true,
                })}
              >
                {({paginationProps, paginationTableProps}) => {
                  return (
                    <Pagination isLoading={false} paginationProps={paginationProps}>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                        bootstrap4
                        remote
                        hover
                        keyField='_id'
                        onTableChange={getHandlerTableChange(setQueryParams)}
                        data={shortLinks === null ? [] : shortLinks}
                        columns={shortLinkColumns}
                        {...paginationTableProps}
                      >
                        <PleaseWaitMessage entities={shortLinks === null ? [] : shortLinks} />
                        <NoRecordsFoundMessage entities={shortLinks === null ? [] : shortLinks} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </div>
      <AddShortLink
        show={showAddShortLink}
        onHide={() => setShowAddShortLink(false)}
        onSaveSuccess={onSaveSuccess}
      />
      {shortLink && (
        <EditShortLink
          show={shortLink ? true : false}
          shortLink={shortLink}
          onHide={() => setShortLink(null)}
          onSaveSuccess={onSaveSuccess}
        />
      )}
      {shortLinkDelete && (
        <ModalDelete
          onClick={handleDeleteShortLink}
          show={shortLinkDelete ? true : false}
          title={'Xoá link'}
          content={'Bạn muốn xoá link'}
          deleteName={shortLinkDelete.name}
          close={() => setShortLinkDelete(null)}
        />
      )}
    </>
  );
}

const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {openEdit, openDelete, openDuplicate = null}
) => (
  <div className='d-flex'>
    <a
      className='btn  btn-icon btn-hover-primary btn-sm mx-2'
      key='edit'
      onClick={(event) => {
        event.stopPropagation();
        openEdit(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
      </span>
    </a>
    <a
      className='btn  btn-icon btn-hover-primary btn-sm mx-2'
      key='duplicate'
      title='Duplicate'
      onClick={(event) => {
        event.stopPropagation();
        openDuplicate && openDuplicate(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Duplicate.svg')} />
      </span>
    </a>
    <a
      className='btn  btn-icon  btn-hover-primary btn-sm mx-2'
      key='delete'
      onClick={(event) => {
        event.stopPropagation();
        openDelete(row);
      }}
    >
      <span className='svg-icon svg-icon-md'>
        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
      </span>
    </a>
  </div>
);
