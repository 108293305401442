import {getStorage, removeStorage} from 'app/common/_helpers';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import copy from 'clipboard-copy';
import {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  createOrder,
  genPaymentQrCodeAction,
  getCountOrderInDayAction,
  getCustomerByPhoneNumberAction,
  getOrderBatchDetailAction,
  getOrderByPhoneAction,
  getOrderLandingPageByCodeAction,
  getPriceDetailAction,
  getProductsAction,
  getSellerAction,
} from '../../redux/orderActions';
import {getOrderBatchSummaryProductOrderAction} from '../../redux/actions';
import {cloneDeep, groupBy} from 'lodash';
import {toAbsoluteUrl} from 'core/helpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import moment from 'moment';
import {format} from 'date-fns'
import { removeSign } from 'app/common/_helpers';
export const useOrder = ({orderLandingPageDetail}) => {
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {t} = useTranslate();

  const [orderBatchDetail, setOrderBatchDetail] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [productById, setProductById] = useState({});
  const [validProductsForOrder, setValidProductsForOrder] = useState([]);
  const [productByCategory, setProductByCategory] = useState({});
  const [cart, setCart] = useState({});
  const [showCartOrder, setShowCartOrder] = useState(false);
  const [showCustomerOrder, setShowCustomerOrder] = useState(false);
  const [productsAvailableQuantityById, setProductsAvailableQuantityById] = useState({});
  const [payCartSuccess, setPayCartSuccess] = useState(false);
  const [productInventoryById, setProductInventoryById] = useState([]);
  const CART_STORAGE = 'cart';
  const timeExpire = 24 * 60 * 60 * 7;

  const [cartProducts, setCardProducts] = useState([]);
  const [cartProductsById, setCartProductsById] = useState({});
  const [payments, setPayments] = useState('transfer');
  const [textNote, setTextNote] = useState('');
  const [selectedTab, setSelectedTab] = useState('product');
  const [userCustomer, setUserCustomer] = useState({});
  const [phoneCustomer, setPhoneCustomer] = useState('');
  const [showHistoryOrder, setShowHistoryOrder] = useState(false);
  const [listHistory, setListHistory] = useState([]);
  const [historyOrder, setHistoryOrder] = useState(null);
  const [customerCategory, setCustomerCategory] = useState(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [price, setPrice] = useState(null);
  const [seller, setSeller] = useState(null);
  const [qrOderImage, setQrOderImage] = useState(null);
  const [orderCode, setOrderCode] = useState('')

  const [cartSummary, setCartSummary] = useState({
    totalQuantity: 0,
    totalProduct: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    const prevTitle = document.title;
    if (orderLandingPageDetail) {
      document.title = orderLandingPageDetail.pageTitle;
    }

    return () => {
      document.title = prevTitle;
    };
  }, [orderLandingPageDetail]);

  useEffect(() => {
    let _defaultPriceId = orderLandingPageDetail.priceDefault;
    setPrice(_defaultPriceId);
  }, [orderLandingPageDetail]);

  useEffect(() => {
    const _orderBatchId = orderLandingPageDetail.orderBatch;
    const getOrderBatch = async () => {
      try {
        const res = await dispatch(getOrderBatchDetailAction(_orderBatchId));
        if (!res?.data?.data) {
          return;
        }
        const orderBatch = res.data.data;
        setOrderBatchDetail(orderBatch);
        const productInventoryById = {};
        orderBatch.products?.forEach((product) => {
          productInventoryById[product._id] = product;
        });
        setProductInventoryById(productInventoryById);
        const resProduct = await dispatch(getProductsAction());
        if (!resProduct.data.data) {
          return;
        }
        const products = resProduct.data.data;
        setAllProducts(products);
      } catch (error) {
        console.log(error);
      }
    };
    if (_orderBatchId) {
      getOrderBatch();
      dispatch(getOrderBatchSummaryProductOrderAction(_orderBatchId)).then((result) => {
        const productsAvailableQuantityById = {};
        if (result.data) {
          result.data.forEach((product) => {
            productsAvailableQuantityById[product._id] = product;
          });
          setProductsAvailableQuantityById(productsAvailableQuantityById);
        }
      });
    }
  }, [orderLandingPageDetail.orderBatch, dispatch]);

  useEffect(() => {
    if(orderBatchDetail.seller){
      dispatch(getSellerAction(orderBatchDetail.seller)).then(res => {
        if(res.data.data){
          setSeller(res.data.data)
        }
      })
    }
  },[orderBatchDetail.seller, dispatch])

  const timeOut = useRef();
  useEffect(() => {
    timeOut.current = setInterval(() => {
      const currentTime = moment(new Date()).unix();
      const expiredTime = moment(orderBatchDetail.expired_time).unix();
      if (currentTime > expiredTime) {
        setShowAlert(true);
      }
    }, 5000);
    return () => {
      clearInterval(timeOut.current);
    };
  }, [orderBatchDetail.expired_time]);

  useEffect(() => {
    if (showAlert) {
      clearInterval(timeOut.current);
    }
  }, [showAlert]);

  useEffect(() => {
    let priceId = price || orderLandingPageDetail.priceDefault;
    if (orderLandingPageDetail?.customerCategory?.[customerCategory?.value]) {
      priceId = orderLandingPageDetail?.customerCategory?.[customerCategory?.value];
    }
    const getPrice = async () => {
      try {
        const res = await dispatch(getPriceDetailAction(priceId));
        if (res?.data?.data) {
          const prices = res.data.data;
          const productById = {};
          prices.prices?.forEach((productPrice) => {
            let priceFullAmount = Number(productPrice?.price) || 0;
            let priceDiscountAmount =
              (Number(productPrice?.price) * Number(productPrice?.discount_price_percent)) / 100 ||
              Number(productPrice?.discount_price) ||
              0;
            let priceAmount = priceFullAmount - priceDiscountAmount;
            if (!priceId) {
              priceAmount = 0;
            }
            productById[productPrice._id] = {
              ...productPrice,
              price: priceAmount,
              priceDiscount: priceDiscountAmount,
              productPrice: priceFullAmount,
            };
          });
          setProductById(productById);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (priceId) {
      getPrice();
    }
  }, [
    price,
    dispatch,
    orderLandingPageDetail.priceDefault,
    orderLandingPageDetail.customerCategory,
    customerCategory,
  ]);

  useEffect(() => {
    let _productForOrder = allProducts.flatMap((product) => {
      const productPrice = productById[product._id];
      const productQuantity = productInventoryById[product._id]?.quantity;
      if (productPrice?.price && productPrice?.price !== '' && productQuantity) {
        const totalBatchQuantity = productQuantity;
        const totalOrderQuantity = productsAvailableQuantityById[product._id]?.total_quantity
          ? productsAvailableQuantityById[product._id]?.total_quantity
          : 0;
        const availableQuantity = Math.round((totalBatchQuantity - totalOrderQuantity) * 100) / 100;
        const maxOrderQuantity = product.max_order !== '' ? product.max_order : 1000000;

        let _product = {
          ...product,
          price: productPrice,
          quantity: productQuantity,
          maxOrderQuantity: maxOrderQuantity,
          batchQuantity: totalBatchQuantity,
          orderQuantity: totalOrderQuantity,
          availableQuantity: availableQuantity,
          productCategoryId: product?.productCategory?._id,
        };

        if (!product.packages || product.packages?.length === 0) {
          _product.packages = [
            {
              _id: product._id,
              name: '1 ' + (product.unit_name || 'kg'),
              quantity: 1,
            },
          ];
        }
        return _product;
      } else {
        return [];
      }
    });

    _productForOrder = _productForOrder.sort((a, b) => {
      if (a.availableQuantity > 0 && b.availableQuantity > 0) {
        return a.name > b.name ? 1 : -1;
      } else if (a.availableQuantity > 0) {
        return -1;
      } else {
        return 1;
      }
    });
    setValidProductsForOrder(_productForOrder);
    const productByCategory = groupBy(
      cloneDeep(_productForOrder),
      ({productCategoryId}) => productCategoryId
    );
    setProductByCategory(productByCategory);
    setSelectedTab(Object.keys(productByCategory || {})?.[0]);
  }, [allProducts, productById, productInventoryById, productsAvailableQuantityById]);

  const handelAddToCart = (packageItem, product) => {
    let _cartProductsById = cloneDeep(cartProductsById);
    if (_cartProductsById[product._id]) {
      if (_cartProductsById[product._id].packageItems[packageItem._id]) {
        _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity =
          1 + _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity;
        _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity =
          _cartProductsById[product._id].packageItems[packageItem._id].quantity *
          _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity;
      } else {
        _cartProductsById[product._id].packageItems[packageItem._id] = {
          ...packageItem,
          packageQuantity: 1,
          totalQuantity: packageItem.quantity,
        };
      }
      _cartProductsById[product._id].packageItems[packageItem._id].amount =
        _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity *
        product.price.price;
      _cartProductsById[product._id].totalQuantity =
        _cartProductsById[product._id].totalQuantity + packageItem.quantity;
      _cartProductsById[product._id].totalAmount =
        product.price.price * _cartProductsById[product._id].totalQuantity;
    } else {
      _cartProductsById[product._id] = {
        ...product,
        totalAmount: 0,
        totalQuantity: 0,
        packageItems: {},
      };
      _cartProductsById[product._id].packageItems[packageItem._id] = {
        ...packageItem,
        packageQuantity: 1,
        totalQuantity: packageItem.quantity,
      };
      _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity =
        _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity *
        _cartProductsById[product._id].packageItems[packageItem._id].quantity;
      _cartProductsById[product._id].packageItems[packageItem._id].amount =
        _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity *
        product.price.price;

      _cartProductsById[product._id].totalQuantity =
        _cartProductsById[product._id].totalQuantity + packageItem.quantity;
      _cartProductsById[product._id].totalAmount =
        product.price.price * _cartProductsById[product._id].totalQuantity;
    }

    if (
      _cartProductsById[product._id].totalQuantity > product.availableQuantity ||
      _cartProductsById[product._id].totalQuantity > product.maxOrderQuantity
    ) {
      //Over limit, stop add
      return;
    }
    setCartProductsById(_cartProductsById);
    setCartSummary(calculateCartSummary(_cartProductsById));

    //Old logic
    let cartClone = cloneDeep(cart);
    if (!cartClone?.[product?._id]) {
      cartClone[product._id] = {[packageItem._id]: 1};
    } else {
      const numberProduct = cartClone[product._id][packageItem._id]
        ? cartClone[product._id][packageItem._id] + 1
        : 1;
      cartClone[product._id] = {...cartClone[product._id], [packageItem._id]: numberProduct};
    }
    setCart(cartClone);
    //setStorage(CART_STORAGE, JSON.stringify(cartClone), timeExpire);

    let _cartProducts = cloneDeep(cartProducts);
    let existingIndex = _cartProducts.findIndex((item) => item._id === product._id);
    if (existingIndex === -1) {
      _cartProducts.push({...product, quantity: 1});
    } else {
      _cartProducts[existingIndex].quantity += 1;
    }

    setCardProducts(_cartProducts);
  };

  const handelDeleteToCart = (packageItem, product) => {
    const cartClone = cloneDeep(cart);
    if (cartClone?.[product?._id]) {
      const numberProduct = cartClone[product._id][packageItem._id]
        ? cartClone[product._id][packageItem._id] - 1
        : 0;
      if (numberProduct > 0) {
        cartClone[product._id] = {...cartClone[product._id], [packageItem._id]: numberProduct};
      } else {
        cartClone[product._id][packageItem._id] = null;
        const isPackage = Object.values(cartClone[product._id]).some((packageItem) => {
          return packageItem !== null;
        });
        if (!isPackage) {
          cartClone[product._id] = null;
        }
      }
    }
    setCart(cartClone);
    //setStorage(CART_STORAGE, JSON.stringify(cartClone), timeExpire);

    let _cartProducts = cloneDeep(cartProducts);
    let existingIndex = _cartProducts.findIndex((item) => item._id === product._id);
    if (existingIndex === -1) {
      //_cartProducts.push({...product, quantity: 1});
    } else {
      _cartProducts[existingIndex].quantity = _cartProducts[existingIndex].quantity - 1;
    }

    _cartProducts = _cartProducts.flatMap((product) => {
      if (product.quantity > 0) {
        return product;
      }
      return [];
    });
    setCardProducts(_cartProducts);

    let _cartProductsById = cloneDeep(cartProductsById);
    if (_cartProductsById[product._id]?.packageItems?.[packageItem._id]) {
      _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity -= 1;
      _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity =
        _cartProductsById[product._id].packageItems[packageItem._id].packageQuantity *
        _cartProductsById[product._id].packageItems[packageItem._id].quantity;
      _cartProductsById[product._id].totalQuantity =
        _cartProductsById[product._id].totalQuantity - packageItem.quantity;
      _cartProductsById[product._id].totalAmount =
        product.price.price * _cartProductsById[product._id].totalQuantity;

      _cartProductsById[product._id].packageItems[packageItem._id].amount =
        _cartProductsById[product._id].packageItems[packageItem._id].totalQuantity *
        product.price.price;
    }

    for (const [key, value] of Object.entries(_cartProductsById)) {
      if (value.totalQuantity === 0) {
        delete _cartProductsById[key];
      }
    }

    setCartProductsById(_cartProductsById);
    setCartSummary(calculateCartSummary(_cartProductsById));
  };

  const validateAndEnterCustomer = (user) => {
    const _orderBatchId = orderBatchDetail._id;

    dispatch(getOrderBatchSummaryProductOrderAction(_orderBatchId)).then((result) => {
      const productsAvailableQuantityById = {};
      if (result.data) {
        result.data.forEach((product) => {
          productsAvailableQuantityById[product._id] = product;
        });
        setProductsAvailableQuantityById(productsAvailableQuantityById);

        let orderValid = true;
        let invalidProductMessage = '';
        for (const [productId, cartProduct] of Object.entries(cartProductsById)) {
          const productQuantity = productInventoryById[productId]?.quantity;
          const totalBatchQuantity = productQuantity;
          const totalOrderQuantity = productsAvailableQuantityById[productId]?.total_quantity
            ? productsAvailableQuantityById[productId]?.total_quantity
            : 0;
          const availableQuantity =
            Math.round((totalBatchQuantity - totalOrderQuantity) * 100) / 100;

          if (cartProduct.totalQuantity > availableQuantity) {
            invalidProductMessage = cartProduct.name + ' chỉ còn ' + availableQuantity;
            orderValid = false;
            break;
          }
        }

        if (orderValid) {
          handlePayCart({...userCustomer, ...user});
        } else {
          setShowCartOrder(true);
          setShowCustomerOrder(false);
          snackNotification.showError(
            'Đơn hàng đặt vượt quá số lượng có sẵn: ' + invalidProductMessage
          );
        }
      }
    });
  };

  const handlePayCart = (user) => {
    const _orderBatchId = orderLandingPageDetail.orderBatch;
    let orderDetails = [];
    for (const [productId, cartProduct] of Object.entries(cartProductsById)) {
      for (const [packageId, pack] of Object.entries(cartProduct.packageItems)) {
        const rowCode = productInventoryById[productId].row_code;
        orderDetails.push({
          product_id: productId,
          product_code: cartProduct.code,
          product: cartProduct,
          package_id: packageId,
          package_quantity: pack.packageQuantity,
          package_name: pack.name,
          total_quantity: pack.totalQuantity,
          package_unit_quantity: pack.quantity,
          price: cartProduct.price.price,
          priceDiscount: cartProduct.price.priceDiscount,
          productPrice: cartProduct.price.productPrice,
          amount: pack.amount,
          row_code: rowCode,
          quantity: cartProduct.quantity,
        });
      }
    }

    const orderCode = `DH${format(new Date(), 'yyMMddhhmmss')}${new Date()
      .valueOf()
      .toString()
      .slice(-3)}`;
    const params = {
      name: user?.name || '',
      deliver: user?.deliver || '',
      phone: user?.phone || '',
      address: user?.address || '',
      phone_delivery: user?.phone_delivery || '',
      order_batch_id: _orderBatchId,
      order_batch: {
        id: orderBatchDetail._id,
        name: orderBatchDetail.name,
        delivery_date: orderBatchDetail.delivery_date,
      },
      total_quantity: cartSummary.totalQuantity,
      total_amount: cartSummary.totalAmount,
      payments: payments,
      status: 'placed',
      note: textNote,
      payment_status: 'unpaid',
      price_list_id: price,
      details: orderDetails,
      linkOrder: `${window.location.origin}${toAbsoluteUrl(`/p/orders/${_orderBatchId}/${price}`)}`,
      seller: seller,
    };  
    const paramsGenQrCode = {
      bankId: seller.settings.bank,
      bankAccount: seller.settings.bankAccount,
      price: cartSummary.totalAmount?.toString(),
      content: `${removeSign(user.name)} ${user.phone} ${orderCode}`,
    };

    dispatch(genPaymentQrCodeAction(paramsGenQrCode)).then((res) => {
      const uri = res.data.data;
      setQrOderImage(uri);
    });

    dispatch(createOrder(params)).then((res) => {
      if (res.data?.success) {
        // snackNotification.showSuccess(t('order_success'));
        setPayCartSuccess(true);
        setShowCartOrder(false);
        setOrderCode(orderCode)
        //removeStorage(CART_STORAGE);
        setCart({});
      } else {
        snackNotification.showError(t('order_error'));
      }
    });
  };

  useEffect(() => {
    //const cart = getStorage(CART_STORAGE);
    //setCart(JSON.parse(cart) || {});
  }, []);

  useEffect(() => {
    const phoneCustomer = getStorage('phoneCustomer');
    setPhoneCustomer(phoneCustomer);
    dispatch(getCustomerByPhoneNumberAction(phoneCustomer)).then((res) => {
      if (!res?.data?.data) {
        setShowCustomerOrder(true);
      } else {
        const customerCategory = res.data.data?.customer_categories?.[0];
        setCustomerCategory(customerCategory);
        setUserCustomer(res?.data?.data);
      }
    });
  }, [dispatch, showCustomerOrder]);

  useEffect(() => {
    if (showHistoryOrder && phoneCustomer) {
      const params = {
        phone: phoneCustomer,
      };
      dispatch(getOrderByPhoneAction(params)).then((res) => {
        const orderDetail = res.data.data;
        const filterd = orderDetail.map(order => {
          const details = order.details.filter(product => product.package_quantity)
          return {
              ...order,
              details
          }
        })
        setListHistory(filterd);
      });
    }
  }, [showHistoryOrder, phoneCustomer, dispatch]);

  const handlePaySuccess = () => {
    setPayCartSuccess(false);
    setShowCartOrder(false);
    setCardProducts([]);
    setCartProductsById({});
    setCart({});
    setCartSummary({
      totalQuantity: 0,
      totalProduct: 0,
      totalAmount: 0,
    });
    window.location.reload();
  };

  const totalProductCard =
    Object.values(cart || {}).filter((product) => product !== null).length || 0;

  const totalProductQuantity = (product) => {
    const cartPackage = cart?.[product?._id];

    let total = 0;
    if (product.packages?.length > 0 && product.packages) {
      total = product.packages?.reduce((totalQuantity, item) => {
        if (cartPackage?.[item._id]) {
          return totalQuantity + item.quantity * cartPackage?.[item._id];
        }
        return totalQuantity;
      }, 0);
    }
    return parseFloat(total?.toFixed(1)) || 0;
  };

  const totalProductAmount = (product) => {
    let productItem = product;
    let priceAmount = Number(productById?.[product?._id]?.price);

    const cartPackage = cart?.[productItem?._id];
    return productItem?.packages?.reduce((accumulator, item) => {
      let pricePackage = 0;
      if (price) {
        pricePackage = item.quantity * priceAmount;
      } else {
        pricePackage = item.price;
      }
      if (cartPackage?.[item._id]) {
        return accumulator + pricePackage * cartPackage?.[item._id];
      }
      return accumulator;
    }, 0);
  };

  const calculateCartSummary = (cartProductsById) => {
    let totalQuantity = 0;
    let totalProduct = 0;
    let totalAmount = 0;
    for (const [key, value] of Object.entries(cartProductsById)) {
      totalAmount += value.totalAmount;
      totalQuantity += value.totalQuantity;
      totalProduct += 1;
    }
    return {
      totalQuantity,
      totalProduct,
      totalAmount,
    };
  };

  const handleCopySTK = () => {
    snackNotification.showSuccess('Sao chép thành công');
    copy(`${seller?.settings?.bankAccount || 1039901029}` || `1039901029`);
  };

  const handleCopyAmountOfMoney = () => {
    snackNotification.showSuccess('Sao chép thành công');
    copy(`${cartSummary.totalAmount}` || ``);
  };

  const handleCopyContentOfTrensfer = () => {
    snackNotification.showSuccess('Sao chép thành công');
    copy(`${orderCode} ${userCustomer?.phone}` || ``);
  };

  const handleLogout = () => {
    removeStorage('phoneCustomer');
    removeStorage('phoneCustomer_expiresIn');
    setPayCartSuccess(false);
    setShowCartOrder(false);
    setCardProducts([]);
    setCartProductsById({});
    setCart({});
    setCartSummary({
      totalQuantity: 0,
      totalProduct: 0,
      totalAmount: 0,
    });
    window.location.reload();
  };

  return {
    handleLogout,
    handleCopySTK,
    handleCopyAmountOfMoney,
    handleCopyContentOfTrensfer,
    validProductsForOrder,
    showAlert,
    showHistoryOrder,
    setShowHistoryOrder,
    listHistory,
    setHistoryOrder,
    historyOrder,
    orderBatchDetail,
    payCartSuccess,
    payments,
    cartSummary,
    handlePaySuccess,
    showCartOrder,
    setShowCartOrder,
    totalProductCard,
    cartProductsById,
    handelDeleteToCart,
    handelAddToCart,
    totalProductQuantity,
    totalProductAmount,
    setPayments,
    textNote,
    setTextNote,
    setShowModalConfirm,
    userCustomer,
    validateAndEnterCustomer,
    phoneCustomer,
    selectedTab,
    setSelectedTab,
    productByCategory,
    productById,
    price,
    showCustomerOrder,
    setShowCustomerOrder,
    showModalConfirm,
    seller,
    qrOderImage,
    orderCode
  };
};
