import React from 'react';
import {Modal} from 'react-bootstrap';
import CustomerForm from './CustomerForm';
import {useDispatch} from 'react-redux';
import {addCustomersAction} from '../../redux/customerActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { useTranslate } from 'core/i18n/i18nProvider';

function AddCustomer({show, onHide, onSaveSuccess}) {
  const dispatch = useDispatch();
  const {t} = useTranslate()
  const snackNotification = useSnackNotification();

  const initCustomer = {
    name: '',
    address: '',
    phone: '',
    phone_delivery: '',
    customer_categories: [],
    password: '123'
  };

  const saveCustomer = (customer) => {
    const params = {
      ...customer,
    };
    dispatch(addCustomersAction(params)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('customer_add_success'));
        onSaveSuccess();
      } else {
        snackNotification.showError(t('customer_add_error'));
        onHide();
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('customer_add_title')}</Modal.Title>
        </Modal.Header>
        <CustomerForm initCustomer={initCustomer} onHide={onHide} saveCustomer={saveCustomer} />
      </Modal>
    </div>
  );
}

export default AddCustomer;
