import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import ShortLinkForm from './ShortLinkForm';
import {useDispatch} from 'react-redux';
import {editShortLinksAction} from '../../redux/shortLinkActions';
import {useSnackNotification} from 'app/layout/_core/SnackNotificationProvider';
import { useTranslate } from 'core/i18n/i18nProvider';

function EditShortLink({show, onHide, onSaveSuccess, shortLink}) {
  const dispatch = useDispatch();
  const {t} = useTranslate()
  const snackNotification = useSnackNotification();
  const initShortLink = {
    name: '',
    description: '',
    maxOrder: '',
    image: '',
    packages: [],
    products: [],
  };

  const [shortLinkEdit, setShortLinkEdit] = useState(initShortLink);

  useEffect(() => {
    const shortLinkEdit = {
      name: shortLink.name,
      description: shortLink.description,
      maxOrder: shortLink.max_order,
      image: shortLink.image,
      packages: shortLink.packages,
    };
    setShortLinkEdit(shortLinkEdit);
  }, [shortLink]);

  const saveShortLink = (shortLinkValue) => {
    const params = {
      ...shortLinkValue,
      max_order: shortLinkValue.maxOrder,
    };
    dispatch(editShortLinksAction(params, shortLink._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('shortLink_edit_success'));
        onSaveSuccess();
      } else {
        snackNotification.showError(t('shortLink_edit_error'));
        onHide();
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('shortLink_edit_title')}</Modal.Title>
        </Modal.Header>
        <ShortLinkForm
          initShortLink={shortLinkEdit}
          onHide={onHide}
          saveShortLink={saveShortLink}
          isEdit={true}
        />
      </Modal>
    </div>
  );
}

export default EditShortLink;
