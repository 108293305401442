import * as requestFromServer from './sellerApi'
import {sellerSlice, callTypes} from './sellerSlice'

const {actions} = sellerSlice

export const fetchSellersAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))

  return requestFromServer
    .getSeller(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.sellersFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get sellers";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
}


export const addSellerAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .addSeller(params)
    .catch((error) => {
      error.clientMessage = "Can't add seller";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    })
}


export const editSellerAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.editSeller(params, id).catch((error) => {
    error.clientMessage = "Can't edit seller";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const deleteSellerAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}))

  return requestFromServer
    .deleteSeller(id)
    .catch((error) => {
      error.clientMessage = "Can't delete seller";
      dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}
