import { useField, useFormikContext } from "formik";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  return classes.join(" ");
};

export function DatePickerFieldCalendarIcon({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);

  useEffect(
    () => { setFieldValue(field.name, field.value) },
    [field.value]);

  return (
    <>
      <input
        type='date'
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        name={`${field.name}`}
        value={field.value ? new Date(field.value).toLocaleDateString("fr-CA") : null}
        onChange={(e) => {
          setFieldValue(field.name, e.target.value);
        }}
      />
    </>
  )
}
