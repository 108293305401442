import { TypedUseSelectorHook, shallowEqual, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector



declare global {
  interface Window { APP_DOMAIN: any; }
}


export const useSession = () => {
  
  const user = useSelector((state: {auth: any}) => state.auth.info);

  const getDomain = () => {
    if (window.APP_DOMAIN && window.APP_DOMAIN !== '') {
      return window.APP_DOMAIN;
    } else {
      var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
      if (subdomain) {
        return subdomain;
      } else {
        return '';
      }
    }
  }

  
  return {getDomain, user  }
}