import {Route, Routes} from 'react-router-dom';
import ReportPage from './page/ReportPage';
import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';
const ReportRoutes = () => {
  return (
    <Routes>      
      <Route
          path='/:tab'
          element={
            <>
            <PageAside>
              <Menu />
            </PageAside>     
              <ReportPage />
            </>
          }
        />
      <Route index element={
            <ReportPage />
      } />
    </Routes>
  );
};

export default ReportRoutes;
