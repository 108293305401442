import * as requestFromServer from './api'
import {orderLandingPageSlice, callTypes} from './slice'

const {actions} = orderLandingPageSlice

export const fetchListOrderLandingPageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getListOrderLandingPage(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.listOrderBatchFetched({data}));
    })
    .catch((error) => {
      error.clientMessage = "Can't get list order batch";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const addOrderLandingPageAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.addOrderLandingPage(params).catch((error) => {
    error.clientMessage = "Can't add order landing page";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};


export const getListOrderBatchAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getListOrderBatch(params)
    .then((res) => {
      const data = res.data;
      return data
    })
    .catch((error) => {
      error.clientMessage = "Can't get list order batch";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};


export const editOrderLandingPageAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.editOrderLandingPage(params, id).catch((error) => {
    error.clientMessage = "Can't edit order batch";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const getOrderLandingPageDetailAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.orderLandingPageDetail(id).catch((error) => {
    error.clientMessage = "Can't get order landing page";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

export const deleteOrderLandingPageAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.deleteOrderLandingPage(id).catch((error) => {
    error.clientMessage = "Can't delete order landing page";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};


export const getProductByIds = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getProductByIds(params)
    .catch((error) => {
      error.clientMessage = "Can't get products";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const getOrderByOrderBatch = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.orderByOrderBatch(id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};


export const getPackageSummaryByOrderBatchAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getPackageSummaryByOrderBatch(id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  }).then((res) => {
    const data = res.data;
    return data;
  });
};


export const getOrderBatchSummaryProductOrderAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getOrderBatchSummaryProductOrder(id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  }).then((res) => {
    const data = res.data;
    return data;
  });
};


export const getOrderBatchSummaryProductCustomerOrderAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getOrderBatchSummaryProductCustomerOrder(id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  }).then((res) => {
    const data = res.data;
    return data;
  });
};


export const getOrderBatchSalesSummaryAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getOrderBatchSalesSummary(id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  }).then((res) => {
    const data = res.data;
    return data;
  });
};


export const fetchPricesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer
    .getPrices(params)
    .catch((error) => {
      error.clientMessage = "Can't get list price";
      dispatch(actions.catchError({error, callType: callTypes.list}));
    });
};

export const uploadFileAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.uploadFile(params).catch((error) => {
    error.clientMessage = "Can't duplicate";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const updateOrderAction = (params, id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.updateOrder(params, id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};

export const deleteOrderAction = (id) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.action}));

  return requestFromServer.deleteOrder(id).catch((error) => {
    error.clientMessage = "Can't get order";
    dispatch(actions.catchError({error, callType: callTypes.action}));
  });
};


export const getCustomerCategoriesAction = (params) => (dispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}));

  return requestFromServer.getCustomerCategories(params).catch((error) => {
    error.clientMessage = "Can't get customer categories";
    dispatch(actions.catchError({error, callType: callTypes.list}));
  });
};

