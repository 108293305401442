import {Form, Formik} from 'formik';
import {InputGroup} from 'react-bootstrap';
import {useTranslate} from 'core/i18n/i18nProvider';
import {SelectField} from 'app/common/_partials/controls';

const prepareFilter = (queryParams, values) => {
  const {searchText} = values;
  const newQueryParams = {...queryParams};
  if (!newQueryParams.filter) {
    newQueryParams.filter = {};
  }
  newQueryParams.filter.q = searchText;
  return newQueryParams;
};

export default function OrderListFilter({listLoading, queryParams, setQueryParams}) {
  const {t} = useTranslate();

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(queryParams, values);
    if (!newQueryParams.paging) {
      newQueryParams.paging = {};
    }
    newQueryParams.paging.pageNumber = 1;
    setQueryParams(newQueryParams);
  };

  const handleFilter = (key, value) => {
    const newQueryParams = {...queryParams};
    if (!newQueryParams.filter) {
      newQueryParams.filter = {};
    }
    newQueryParams.filter[key] = value;
    setQueryParams(newQueryParams);
  };

  const optionsStatus = [
    {label: t('order_status_placed'), value: 'placed'},
    {label: t('order_status_delivered'), value: 'delivered'},
    {label: t('order_status_success'), value: 'success'},
  ];

  const optionsStatusPayment = [
    {label: 'Đã TT', value: 'paid'},
    {label: 'Chưa TT', value: 'unpaid'},
  ];

  return (
    <>
      <Formik
        initialValues={{
          searchText: '',
          status: '',
          statusPayment: '',
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({values, handleSubmit, handleBlur, setFieldValue}) => (
          <Form className='form form-label-right d-flex w-100'>
            <SelectField
              className='w-200px form-control is-valid-select me-3'
              name='statusPayment'
              customFeedbackLabel
              withFeedbackLabel
              notifyChange={(value) => {
                handleFilter('payment_status', value);
              }}
            >
              <option value=''>All Thanh toán</option>
              {optionsStatusPayment.map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </SelectField>
            <SelectField
              className='w-200px form-control is-valid-select me-3'
              name='status'
              customFeedbackLabel
              withFeedbackLabel
              notifyChange={(value) => {
                handleFilter('status', value);
              }}
            >
              <option value=''>All Giao hàng</option>
              {optionsStatus.map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </SelectField>
            <InputGroup size='sm' className='w-250px'>
              <input
                type='search'
                className='form-control'
                name='searchText'
                autoComplete='off'
                placeholder={'Tìm kiếm theo, SĐT, Đợt hàng'}
                onBlur={handleBlur}
                value={values.searchText}
                onChange={(e) => {
                  setFieldValue('searchText', e.target.value);
                }}
              />
              <button type='button' onClick={handleSubmit} className='btn btn-secondary d-inline'>
                {t('common_search')}
              </button>
            </InputGroup>
          </Form>
        )}
      </Formik>
    </>
  );
}
