import {deleteApiV2, postApiV2, putApiV2} from 'core/store/api'

const endPoints = {
  listSeller: '/api/seller/search',
  addSeller: '/api/seller',
  editSeller: (id) => `/api/seller/${id}`,
  deleteSeller: (id) => `/api/seller/${id}`,
};

export function getSeller(params) {
  return postApiV2(endPoints.listSeller, params);
}

export function addSeller(params) {
  return postApiV2(endPoints.addSeller, params);
}

export function editSeller(params, id) {
  return putApiV2(endPoints.editSeller(id), params);
}

export function deleteSeller(id) {
  return deleteApiV2(endPoints.deleteSeller(id));
}
