import { getApiV2, postApi, postApiV2 } from "../api";

const DOMAIN_INFORMATION_URL =  "/site/get-information-by-domain";
const endPoints = {
  searchUsers: '/api/user/search',
  searchTeams: '/api/team/search',
}

export function getDomainInformation(params) {
  
  var bodyFormData = new FormData();
  bodyFormData.append("_sand_hostname",window.APP_HOSTNAME? window.APP_HOSTNAME: window.location.host);

  return postApiV2(DOMAIN_INFORMATION_URL, bodyFormData)
  
}

export function getAllUsers(params) {
  
  return postApiV2(endPoints.searchUsers, params);

}

export function getCurrentUserInfo() {
  return getApiV2('/api/auth/me');
}

