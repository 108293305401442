import { createSlice } from "@reduxjs/toolkit";

const initialCustomerState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  customers: [],
  customerCategories: [],
  countCustomers: 0
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState: initialCustomerState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    customersFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.customers = data.data ? data.data : [];
      state.countCustomers = data.count ? data.count : 0
    },

    customerCategoriesFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.customerCategories = data.data ? data.data : [];
    },

    catchError: (state, action) => {
      state.lastError = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },
  },
});
