import {FormattedNumber} from 'react-intl';
import {toAbsoluteUrl} from 'core/helpers';
import './orderItem.scss';
import clsx from 'clsx'

function OrderItem({product, cartProduct, handelDeleteToCart, handelAddToCart}) {
  return (
    <div className='product-item'>
      <div>
        <img
          src={product?.image_url || toAbsoluteUrl('/media/products/23.png')}
          className={`w-100 rounded ${product?.image_url ? '' : 'opacity-50'} `}
          style={{objectFit: 'contain'}}
          alt=''
        />
      </div>
      <div>
        <p className='product-name'>{product?.name}</p>
        <div className='mt-3 d-flex gap-4 align-items-center flex-wrap'>
          <span className='product-price'>
            <FormattedNumber value={product?.price?.price} />đ
          </span>
          {product?.price?.discount_price && (
            <span className='product-sale-price'>
              <FormattedNumber value={product?.price?.discount_price} />đ
            </span>
          )}
          {product?.price?.discount_price_percent && (
            <span className='product-sale-percent'>
              <FormattedNumber value={product?.price?.discount_price_percent} />đ
            </span>
          )}
          {product.availableQuantity && (
            <span>
              Còn: {product.availableQuantity} {product.unit_name || 'kg'}
            </span>
          )}
        </div>
        <div className='product-package w-100 mt-3'>
          {product?.packages?.map((packageItem) => {
            const cartPackage = cartProduct?.packageItems?.[packageItem?._id];
            return (
              <div
                className='d-flex w-100 justify-content-end align-items-center mt-auto mb-2'
                key={packageItem._id}
              >
                <span className='w-70px'>{packageItem.name}</span>
                <div className='d-flex align-items-center'>
                  <span className='me-2'>SL: </span>
                  <i
                    className={`las la-minus border border-dark text-dark p-1 h-25px w-25px d-flex align-items-center justify-content-center ${
                      clsx()
                      // {'opacity-0': !cartPackage}
                    }`}
                    onClick={() => {
                      if (cartPackage) {
                        handelDeleteToCart(packageItem, product);
                      }
                    }}
                  ></i>
                  <span
                    className={`fw-bold border border-dark h-25px w-25px d-flex align-items-center justify-content-center`}
                  >
                    {cartPackage?.packageQuantity || 0}
                  </span>
                  <i
                    className='las la-plus border border-dark text-dark p-1 h-25px w-25px d-flex align-items-center justify-content-center'
                    onClick={() => handelAddToCart(packageItem, product)}
                  ></i>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OrderItem;
