import {combineReducers} from 'redux';
import {all} from 'redux-saga/effects';

import * as auth from '../store/auth';

import {coreSlice} from './core/coreSlice';

import {teamSlice} from 'app/modules/teams/redux/teamSlice';
import {slice as teamUserSlice} from 'app/modules/teams/redux/user/userSlice';
import {slice as profileSlice} from 'app/modules/profile/stores/slice';
import { productSlice } from 'app/modules/products/redux/productSlice';
import { orderBatchSlice } from 'app/modules/order-batch/redux/orderBatchSlice';
import { orderSlice } from 'app/modules/order/redux/orderSlice';
import { customerSlice } from 'app/modules/customers/redux/customerSlice';
import { priceListSlice } from 'app/modules/price-lists/redux/priceListSlice';
import { productCategorySlice } from 'app/modules/product-category/redux/productCategorySlice';
import { shortLinkSlice } from 'app/modules/short-links/redux/shortLinkSlice';
import { orderLandingPageSlice } from 'app/modules/landing-page-order/redux/slice';
import { customerCategorySlice } from 'app/modules/customer-category/redux/CustomerCategorySlice';
import { sellerSlice } from 'app/modules/seller/redux/sellerSlice';
import { reportSlice } from 'app/modules/reports/redux/reportSlice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  core: coreSlice.reducer,
  team: teamSlice.reducer,
  teamUser: teamUserSlice.reducer,
  profile: profileSlice.reducer,
  product: productSlice.reducer,
  orderBatch: orderBatchSlice.reducer,
  order: orderSlice.reducer,
  customer: customerSlice.reducer,
  priceList: priceListSlice.reducer,
  productCategory: productCategorySlice.reducer,
  shortLink: shortLinkSlice.reducer,
  orderLandingPage: orderLandingPageSlice.reducer,
  customerCategory: customerCategorySlice.reducer,
  seller: sellerSlice.reducer,
  report: reportSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
