import {useLocation} from 'react-router-dom';
import './menu.scss';

export const Menu = () => {
  const location = useLocation();

  const isMenuActive = (path) => {
    return location.pathname.includes(path);
  };
  return (
    <div className='workspace-page-menu'>
      {/*begin::Container*/}
      <div className='text-center pt-8 mb-20 h-100 d-flex flex-column'>
        {/*begin::Title*/}

        {/*end::Title*/}
        <div className='menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 border-bottom border-gray-400'>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/seller') ? 'active' : ''}`}
              href='/app/seller'
            >
              <span className='menu-icon'>
                <i className='las la-warehouse fs-2'></i>
              </span>
              <span className='menu-title'>Người bán</span>
            </a>
          </div>
          <div className='separator my-2'></div>

          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/customers') ? 'active' : ''}`}
              href='/app/customers'
            >
              <span className='menu-icon'>
                <i className='las la-user-tie fs-2'></i>
              </span>
              <span className='menu-title'>Khách hàng</span>
            </a>
          </div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/customer-category') ? 'active' : ''}`}
              href='/app/customer-category'
            >
              <span className='menu-icon'>
                <i className='las  la-user-friends fs-2'></i>
              </span>
              <span className='menu-title'>Nhóm khách hàng</span>
            </a>
          </div>

          <div className='separator my-2'></div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/products') ? 'active' : ''}`}
              href='/app/products'
            >
              <span className='menu-icon'>
                <i className='las la-tag fs-2'></i>
              </span>
              <span className='menu-title'>Sản phẩm</span>
            </a>
          </div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/pro-category') ? 'active' : ''}`}
              href='/app/pro-category'
            >
              <span className='menu-icon'>
                <i className='las la-tags fs-2'></i>
              </span>
              <span className='menu-title'>Danh mục sản phẩm</span>
            </a>
          </div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/price-list') ? 'active' : ''}`}
              href='/app/price-list'
            >
              <span className='menu-icon'>
                <i className='las la-clipboard-list fs-2'></i>
              </span>
              <span className='menu-title'>Bảng giá</span>
            </a>
          </div>
          <div className='separator my-2'></div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/page-order') ? 'active' : ''}`}
              href='/app/page-order'
            >
              <span className='menu-icon'>
                <i className='las la-bookmark fs-2'></i>
              </span>
              <span className='menu-title'>Trang đặt hàng</span>
            </a>
          </div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/batch-order') ? 'active' : ''}`}
              href='/app/batch-order'
            >
              <span className='menu-icon'>
                <i className='las la-truck-pickup fs-2'></i>
              </span>
              <span className='menu-title'>Đợt hàng</span>
            </a>
          </div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/orders') ? 'active' : ''}`}
              href='/app/orders'
            >
              <span className='menu-icon'>
                <i className='las la-shopping-cart fs-2'></i>
              </span>
              <span className='menu-title'>Đơn hàng</span>
            </a>
          </div>

          <div className='separator my-2'></div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/short-link') ? 'active' : ''}`}
              href='/app/short-link'
            >
              <span className='menu-icon'>
                <i className='las la-link fs-2'></i>
              </span>
              <span className='menu-title'>Link</span>
            </a>
          </div>
          <div className='separator my-2'></div>
          <div className='menu-item'>
            <a
              className={`menu-link fw-bold ${isMenuActive('/report/report-product') ? 'active' : ''}`}
              href='/app/report/report-product'
            >
              <span className='menu-icon'>
                <i className='las la-chart-pie fs-2'></i>
              </span>
              <span className='menu-title'>Báo cáo</span>
            </a>
          </div>
        </div>
      </div>
      {/*end::Container*/}
    </div>
  );
};
