import React, {useEffect} from 'react';
import './orderPrintRau.scss';
import {toAbsoluteUrl} from 'core/helpers';
import {format} from 'date-fns';
import { isNumber } from 'lodash';
import QRCode from 'qrcode.react';

export const OrderPrintRau = React.forwardRef(({order, productById, qrOderImage}, ref) => {
  const totalQuantity = order?.details?.reduce((init, detail) => {
    return init + detail.total_quantity || 0;
  }, 0);
  const viewTotalView = parseFloat(totalQuantity?.toFixed(1)) || 0;
  return (
    <div className='order-print-area' ref={ref}>
      <div className='print-k80 ' style={{paddingLeft: '0.3cm', paddingRight: '0.3cm'}}>
        <div className='text-right text-uppercase fw-bold'>Trang trại hữu cơ DARWIN</div>
        <i className='text-right' style={{fontSize: 11}}>
          Thôn Đồng Giãng, Xã Ngọc Thanh, TP Phúc Yên, Tỉnh Vĩnh Phúc
        </i>
        <div className='justify-content-between mt-4 mb-4'>
          <div className='fw-bold fs-2 text-center'>
            PHIẾU GIAO NHẬN HỮU CƠ - SỐ PHIẾU: {order.order_code}
          </div>
          <div></div>
        </div>
        <div className='d-flex justify-content-between'>
          <div>
            Từ:<span className='fw-bold'> Farm Darwin</span>
          </div>
          <div>
            Ngày sơ chế đóng gói:{' '}
            <span className='fw-bold'>
              {order?.order_batch?.delivery_date
                ? format(new Date(order?.order_batch?.delivery_date * 1000), 'dd/MM/yyyy')
                : format(new Date(), 'dd/MM/yyyy')}
            </span>
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div>Địa chỉ đơn vị sơ chế: </div>
          <div>
            Số xe:<span className='fw-bold'> 29D-129-20</span>
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div>
            Khách hàng: <span className='fw-bold'>{order.name}</span>
          </div>
          <div>
            Ngày thu hoạch:{' '}
            <span className='fw-bold'>
              {order?.order_batch?.delivery_date
                ? format(new Date(order?.order_batch?.delivery_date * 1000), 'dd/MM/yyyy')
                : format(new Date(), 'dd/MM/yyyy')}
            </span>
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div>
            Địa chỉ KH:{' '}
            <span className='fw-bold'>
              {order?.deliver?.name || order?.name} - {order?.deliver?.address || order?.address} -{' '}
              {order?.customer?.phone_delivery || order?.customer?.phone}
            </span>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{width: '0.7cm'}}>STT</th>
              <th style={{width: '6cm'}}>Tên sản phẩm</th>
              <th style={{width: '2.5cm'}}>Mã sản phẩm</th>
              <th>Mã truy xuất</th>
              <th style={{width: '1cm'}}>Số lượng(kg)</th>
              <th style={{width: '1.5cm'}}>Đơn giá (VND)</th>
              <th style={{width: '2cm'}}>Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan='7'>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    Sản phẩm được <br /> chứng nhận hữu cơ
                  </div>
                  <div>
                    <img
                      style={{textAlign: 'center', width: '50px'}}
                      src={toAbsoluteUrl('/media/logos/organic1.jpeg')}
                    />
                  </div>
                  <div>
                  </div>
                  <div>Cu Number:</div>
                  <div>869916</div>
                </div>
              </td>
            </tr>
            {order?.details?.map((pack, idx) => {
              const totalAmount =
                Number(pack?.price) *
                  pack?.package_quantity *
                  pack?.product?.packageItems?.[pack?.package_id]?.quantity || 0;
              const rowCode = productById?.[pack?.product?._id]?.row_code;
              const packageQuantity = pack.total_quantity?.toFixed(1);
              const totalQuantityPackage = parseFloat(packageQuantity) || '';
              return (
                <tr>
                  <td style={{textAlign: 'left'}}>{idx + 1}</td>
                  <td style={{textAlign: 'left'}}>{pack.product.name}</td>
                  <td style={{textAlign: 'left'}}>{pack.product.code}</td>
                  <td style={{textAlign: 'left'}}>
                    {pack.product.code}-({rowCode})-
                    {order?.order_batch?.delivery_date
                      ? format(new Date(order?.order_batch?.delivery_date * 1000), 'ddMMYYY')
                      : format(new Date(), 'ddMMYYY')}
                  </td>
                  <td style={{textAlign: 'right'}}>{Math.round(totalQuantityPackage * 100) / 100}</td>
                  <td style={{textAlign: 'right'}}>{formatQuantity(pack.price)}</td>
                  <td style={{textAlign: 'right'}}>{formatQuantity(totalAmount)}</td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td style={{fontWeight: 'bold'}}>Tổng cộng</td>
              <td></td>
              <td></td>
              <td style={{textAlign: 'right', fontWeight: 'bold'}}>{viewTotalView}</td>
              <td></td>
              <td style={{textAlign: 'right', fontWeight: 'bold'}}>
                {formatQuantity(order?.total_amount)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='d-flex justify-content-between px-20 mt-5'>
          <div className='mt-6 w-200px'>Bên nhận</div>
          {qrOderImage && (
            <div className='d-flex justify-content-end w-100px'>
              <div className='d-flex justify-content-center align-items-center flex-column mt-6 me-5'>
                <div className='fw-bold'>Qr thanh toán</div>
                <QRCode value={qrOderImage} size={90}  />
              </div>
            </div>
          )}
          <div className='d-flex flex-column align-items-center w-150px'>
            <div>
              {isNumber(order?.order_batch?.delivery_date)
                ? format(new Date(order?.order_batch?.delivery_date * 1000), 'dd/MM/yyyy')
                : format(new Date(), 'dd/MM/yyyy')}
            </div>
            <div>Bên giao</div>
            <div className='mt-20 fw-bold'>Nguyễn Thị Tô Duyên</div>
          </div>
        </div>
      </div>
    </div>
  );
});

function formatQuantity(quantity) {
  return Intl.NumberFormat().format(quantity);
}
