import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  defaultTableOptions,
  getHandlerTableChange,
  toAbsoluteUrl,
} from 'app/common/_helpers';
import {
  CardHeaderToolbar,
  LoadingDialog,
  PaginationLinks,
  PaginationToolbar,
} from 'app/common/_partials/controls';
import {cloneDeep, isFunction} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import {shallowEqual} from 'react-intl/src/utils';
import {deleteOrderAction, fetchOrderAction, updateOrderAction} from '../redux/orderActions';
import {useTranslate} from 'core/i18n/i18nProvider';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import {Drawer} from '@material-ui/core';
import OrderDetail from './OrderDetail';
import {FormattedNumber} from 'react-intl';
import SVG from 'react-inlinesvg';
import OrderListFilter from './OrderListFilter';
import { ModalDelete } from 'app/common/_partials/controls/ModalDelete';
import { useSnackNotification } from 'app/layout/_core/SnackNotificationProvider';

function OrderListPage(props) {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const snackNotification = useSnackNotification();
  const {orders, countOrders} = useSelector((state) => state.order, shallowEqual);
  const [orderDetail, setOrderDetail] = useState(null);
  const [orderDelete, setOrderDelete] = useState(null);

  const tableOptions = {
    ...defaultTableOptions,
    searchText: null,
  };

  const orderColumns = [
    {
      dataField: 'name',
      text: t('order_name'),
      editable: false,
    },
    {
      dataField: 'phone',
      text: 'Số điện thoại',
      editable: false,
    },
    {
      dataField: 'address',
      text: 'Địa chỉ',
      editable: false,
      formatter: (cell, row) => {
        return <span>{cell || row?.customer?.address}</span>;
      },
    },
    {
      dataField: 'order_batch.name',
      text: 'Đợt hàng',
      editable: false,
    },
    {
      dataField: 'total_amount',
      text: 'Tổng tiền',
      editable: false,
      formatter: (cell) => {
        return (
          <span>
            <FormattedNumber value={cell} />
          </span>
        );
      },
    },
    {
      dataField: 'product',
      text: 'Sản phẩm',
      editable: false,
      formatter: (cell, row) => {
        return row.details?.map((detail) => (
          <div className='text-nowrap'>
            {detail?.product.name} {detail?.package_name} x{detail?.package_quantity?<FormattedNumber value={detail?.package_quantity?.toFixed(2)} />: ''}
          </div>
        ));
      },
    },
    {
      dataField: 'status',
      text: t('order_status'),
      formatter: (cell) => {
        if (cell === 'placed') {
          return <span className='badge bg-info'>{t('order_status_placed')}</span>;
        }
        if (cell === 'delivered') {
          return <span className='badge bg-primary'>{t('order_status_delivered')}</span>;
        }
        if (cell === 'success') {
          return <span className='badge bg-success'>{t('order_status_success')}</span>;
        }
      },
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: 'placed',
            label: t('order_status_placed'),
          },
          {
            value: 'delivered',
            label: t('order_status_delivered'),
          },
          {
            value: 'success',
            label: t('order_status_success'),
          },
        ],
      },
    },
    {
      dataField: 'payment_status',
      text: 'Trạng thái chuyển tiền',
      formatter: (cell) => {
        if (cell === 'paid') {
          return <span className='badge bg-success'>Đã thanh toán</span>;
        }
        if (cell === 'unpaid') {
          return <span className='badge bg-info'>Chưa thanh toán</span>;
        }
      },
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: 'paid',
            label: 'Đã thanh toán',
          },
          {
            value: 'unpaid',
            label: 'Chưa thanh toán',
          },
        ],
      },
    },
    {
      dataField: 'payments',
      text: 'Hình thức thanh toán',
      editable: false,
      formatter: (cell) => {
        if (cell === 'transfer') {
          return 'Chuyển khoản';
        }
        return 'Khi nhận hàng';
      },
    },
    {
      dataField: 'note',
      text: 'Ghi chú',
      editable: false,
    },
    {
      dataField: 'action',
      text: '',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openDetail: setOrderDetail,
        openDelete: setOrderDelete,
      },
      classes: 'text-right pe-0',
      headerClasses: 'text-right pe-3',
      style: {
        width: '50px',
      },
    },
  ];

  const [queryParams, setQueryParamsBase] = useState(tableOptions);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }
      return nextQueryParams;
    });
  }, []);

  const filterByQuantity = orders.map(order => {
    const details = order.details.filter(product => product.package_quantity)
    return {
        ...order,
        details
    }
  });

  useEffect(() => {
    loadOrders();
  }, [queryParams, dispatch]);

  const handleChangeStatus = (value, row) => {
    const params = {
      status: value,
    };
    dispatch(updateOrderAction(params, row._id)).then((res) => {
      if (res.data.success) {
        loadOrders();
      }
    });
  };

  const handleChangePaymentStatus = (value, row) => {
    const params = {
      payment_status: value,
    };
    dispatch(updateOrderAction(params, row._id)).then((res) => {
      if (res.data.success) {
        loadOrders();
      }
    });
  };

  const loadOrders = () => {
    const params = {
      ...queryParams,
      paging: {
        pageNumber: queryParams.pageNumber,
        pageSize: queryParams.pageSize,
      },
    };
    dispatch(fetchOrderAction(params));
  };


  const handleDeleteProduct = () => {
    dispatch(deleteOrderAction(orderDelete._id)).then((res) => {
      if (res?.data?.success) {
        snackNotification.showSuccess(t('product_delete_success'));
        loadOrders();
        setOrderDelete(null);
      } else {
        snackNotification.showError(t('product_delete_error'));
        setOrderDelete(null);
      }
    });
  };

  const paginationProps = {
    ...queryParams,
    totalSize: countOrders,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
    dataSize: countOrders,
    pageStartIndex: 1,
    onPageChange: (page) => {
      setQueryParams((prev) => ({
        ...prev,
        pageNumber: page,
        page: page,
      }));
    },
  };

  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card card-custom gutter-b'>
            <div className='card-header'>
              <h3 className='card-title font-weight-bolder text-dark'>{t('order_title')}</h3>
              <CardHeaderToolbar>
                <OrderListFilter setQueryParams={setQueryParams} queryParams={queryParams} />
              </CardHeaderToolbar>
            </div>
            <div className='card-body'>
              <BootstrapTable
                wrapperClasses='table-responsive'
                classes='table-sm table-row-bordered table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                hover
                keyField='_id'
                onTableChange={getHandlerTableChange(setQueryParams)}
                data={filterByQuantity === null ? [] : cloneDeep(filterByQuantity)}
                columns={orderColumns}
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    if (column.dataField === 'status') {
                      handleChangeStatus(newValue, row);
                    }
                    if (column.dataField === 'payment_status') {
                      handleChangePaymentStatus(newValue, row);
                    }
                  },
                })}
              >
                <PleaseWaitMessage entities={orders === null ? [] : orders} />
                <NoRecordsFoundMessage entities={orders === null ? [] : orders} />
              </BootstrapTable>
              <div className='d-flex justify-content-between align-items-center flex-wrap'>
                <PaginationLinks paginationProps={paginationProps} />
                <PaginationToolbar isLoading={false} paginationProps={paginationProps} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {orderDetail && <Drawer
        open={orderDetail ? true : false}
        onClose={() => setOrderDetail(null)}
        BackdropProps={{invisible: true}}
        anchor='right'
      >
        <div className='w-500px'>      
          <OrderDetail order={orderDetail} orderId={orderDetail._id} onLoadOrder={loadOrders} />
        </div>
      </Drawer>}
      {orderDelete && (
        <ModalDelete
          onClick={handleDeleteProduct}
          show={orderDelete ? true : false}
          title={'Xoá đơn hàng'}
          content={'Bạn muốn xoá đơn hàng'}
          deleteName={orderDelete.name}
          close={() => setOrderDelete(null)}
        />
      )}
    </>
  );
}

export default OrderListPage;

const ActionsColumnFormatter = (cellContent, row, rowIndex, {openDetail, openDelete}) => {
  return (
    <div className='d-flex'>
      <a
        className='btn btn-icon  btn-hover-primary btn-xs mx-2'
        key='duplicate'
        onClick={(event) => {
          event.stopPropagation();
          openDetail(row);
        }}
      >
        <span className='svg-icon svg-icon-md'>
          <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart1.svg')} />
        </span>
      </a>
      <a
        className='btn btn-icon  btn-hover-primary btn-xs mx-2'
        key='delete'
        onClick={(event) => {
          event.stopPropagation();
          openDelete(row);
        }}
      >
        <span className='svg-icon svg-icon-md'>
          <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
        </span>
      </a>
    </div>
  );
};
