import {deleteApiV2, getApiV2, postApiV2, putApiV2} from 'core/store/api'

const endPoints = {
  listCustomerCategory: '/api/customerCategory/search',
  addCustomerCategory: '/api/customerCategory',
  editCustomerCategory: (id) => `/api/customerCategory/${id}`,
  customerCategoryDetail: (id) => `/api/customerCategory/${id}`,
  deleteCustomerCategory: (id) => `/api/customerCategory/${id}`,
  uploadFile: `/api/file`,
  listCustomer: '/api/customer/search',
};

export function getCustomers(params) {
  return postApiV2(endPoints.listCustomer, params)
}

export function getCustomerCategorys(params) {
  return postApiV2(endPoints.listCustomerCategory, params)
}

export function addCustomerCategory(params) {
  return postApiV2(endPoints.addCustomerCategory, params);
}

export function editCustomerCategory(params, id) {
  return putApiV2(endPoints.editCustomerCategory(id), params);
}

export function customerCategoryDetail(id) {
  return getApiV2(endPoints.customerCategoryDetail(id));
}

export function deleteCustomerCategory(id) {
  return deleteApiV2(endPoints.deleteCustomerCategory(id));
}

export function uploadFile(params) {
    var bodyFormData = new FormData();

  bodyFormData.append('file', params.file);
  return postApiV2(endPoints.uploadFile, bodyFormData);
}
