import {PageAside} from 'core/layouts/layout2/core';
import {Menu} from 'app/layout/_core/Menu';
import OrderListPage from './OrderListPage';
const OrderBatch = () => {
  return (
    <div>
      <PageAside>
        <Menu />
      </PageAside>
      <OrderListPage />
    </div>
  );
};

export default OrderBatch;
